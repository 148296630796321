import React, { useEffect, useRef, useState } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import { useSelector } from "react-redux";
import { AiOutlineFileDone } from "react-icons/ai";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import { quarterlyAppraisalData } from "./quarterlyAppraisalData";
import Declaration from "../../components/common/Declaration/declaration";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";
import Performance from "../../components/layouts/Performance";
import PersonalTarget from "../../components/layouts/PersonalTarget";
import PerformanceRating from "../../components/layouts/PerformanceRating";
import LeadershipAssessment from "../../components/layouts/LeadershipAssessment";
import StaffView from "../../components/layouts/StaffView";
import CareerPlans from "../../components/layouts/CareerPlans";
import TrainingDevelopment from "../../components/layouts/TrainingDevelopment";
import {
  getAnnualData,
  postAnnualMultiple,
  postLeadershipCapabilityMultiple,
  postPerformanceRatingMultiple,
  putAnnualMultiple,
  putLeadershipCapabilitySubmitMultiple,
  putAnnualSubmitMultiple,
  putPerformanceRatingSubmitMultiple,
  getPerformanceAssessment,
  getLeadershipAssessment,


  putLeadershipCapabilityMultiple,
  putPerformanceRatingMultiple,
  createEmployeeWish,
  putEmployeeWish,
  getMidYearData,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import Cookies from "js-cookie";
import { PDFExport } from "@progress/kendo-react-pdf";
import MyContext from "../../utility/Context";
import { useContext } from "react";


// import "./midYearReview.scss";

let errorMessage = undefined;
let error = undefined;

const AnnualAppraisal = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [details, setDetails] = useState([]);
  const [session, setSession] = useState("");
  const [pending, setPending] = useState(false);
  const [openTab, setOpenTab] = useState(0);
  const [employeeName, setemployeeName] = useState([]);
  const [recipientMda, setrecipientMda] = useState([]);
  const [approverId, setApproverId] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [annualData, setAnnualData] = useState([]);
  const [annualUpdateData, setAnnualUpdateData] = useState([]);
  const [wishes, setWishes] = useState('')
  const [wishesID, setWishesId] = useState(Number)
  const [code, setCode] = useState(Number)
  const [performanceState, setPerformanceState] = useState('')
  const [leadershipState, setLeadershipState] = useState('')
  const [performanceRating, setPerformanceRating] = useState([])
  const [leadershipCapability, setLeadershipCapability] = useState([])
  const [personalityProfile, setPersonalityProfile] = useState('')

  const { setAnnualRecord, annualRecord , fromDashboard} = useContext(MyContext);


  const [annualPerformanceRating, setAnnualPerformanceRating] = useState([]);
  const [annualUpdatePerformanceRating, setAnnualUpdatePerformanceRating] =
    useState([]);
  const [annualLeadershipCapability, setAnnualLeadershipCapability] = useState(
    []
  );
  const [annualUpdateLeadershipCapability, setAnnualUpdateLeadershipCapability,] = useState([]);
  const [staffViews, setStaffViews] = useState("");

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const pdfExportComponent = React.useRef(null);
  let disableSubmit = false

  let disable = true
  let disableSubmitbutton = false;
  let disableSubmitperformance = false;
  let disableSubmitgoal = false;



  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "";
    if(fromDashboard === false){
      fetchAnnualData();
      fetchPerformanceRating();
      fetchMyassessmentLeadership();
    }else{
      setLeadershipCapability(annualRecord?.leadershipCapabilitys)
      setPerformanceRating(annualRecord?.performanceRatings)
      setGoalsAndCommitments(annualRecord?.annualGoals)
    }
   

  }, []);

  console.log('From dashboard', fromDashboard, annualRecord)

  

  useEffect(() => {
    setApproverId(
      approvers?.map((item, idx) => ({
        approverID: item?.value,
        priority: idx + 1,
      }))
    );
  }, [approvers]);

  

  if (Array.isArray(leadershipCapability?.userPerformanceLeadership) && leadershipCapability?.userPerformanceLeadership?.length > 0) {
    // Check if userPerformanceLeadership is defined in the first item of the array
    const userPerformanceLeadership = leadershipCapability?.userPerformanceLeadership[0];

    if (userPerformanceLeadership) {
      // Check properties statusId and statusDefinition
      disableSubmitbutton =  leadershipCapability?.userPerformanceLeadership?.some(item => item?.statusId === 1 || item?.statusDefinition === "Unasigned" || item?.statusDefinition === null || item?.statusId === null);
    }
      console.log('BUTTON DISABLE CRITERIA', userPerformanceLeadership, disableSubmitbutton)
  }

  if (Array.isArray(performanceRating?.performanceRating) && performanceRating?.performanceRating?.length > 0) {
    // Check if userPerformanceLeadership is defined in the first item of the array
    const userPerformanceRating = performanceRating?.performanceRating[0];

    if (userPerformanceRating) {
      // Check properties statusId and statusDefinition
      disableSubmitperformance =  performanceRating?.performanceRating?.some(item => item?.statusId === 1 || item?.statusDefinition === "Unasigned" || item?.statusDefinition === null || item?.statusId === null);
    }
        console.log('BUTTON DISABLE perf', userPerformanceRating, disableSubmitperformance)
  }

  if (Array.isArray(goalsAndCommitments) && goalsAndCommitments.length > 0) {
    // Check if any object has statusId equal to 1
    const hasStatusOne = goalsAndCommitments.some(item => item?.status === 1 || item?.status === 6);
  
  
    // If any object has statusId equal to 1, set disableSubmitbutton to false; otherwise, set it to true
    disableSubmitgoal = hasStatusOne;
    console.log('BUTTON DISABLE goal', goalsAndCommitments, disableSubmitgoal);
  }


  if((!disableSubmitbutton) && (!disableSubmitperformance) && (!disableSubmitgoal)){
    console.log('reviwers Disable Stats', disableSubmit)
    disableSubmit = true
    console.log('reviwers Disable Stats', disableSubmit)
  } 
  
  console.log('reviwers SENT TO COMP', !disableSubmitbutton , !disableSubmitperformance ,!disableSubmitgoal, disableSubmit)


  const clearApprovers = () => {
    setemployeeName([]);
    setrecipientMda([]);
  };

  const handleReview = (data) => {
    setDetails(data);
    return setShowFormModal(true);
  };

  const showDetails = (comment, score) => {
    setFormDetails({ comment, score });
    setShowFormModal(true);
  };

  const elementRef = useRef(null);
  const triggerRef = useRef(null);

  const populateAnnualData = (data) => {
    let
      temp = data?.map((data) => {
        return {
          goalId: data?.goalId,
          score: 0,
          comment: data?.comment,
          approvers: approverId
        };

      });
    return temp;
  };

  const validateCreateAnnual = async (data) => {
    if (data?.length == 0) {
      errorMessage = "please fill in form fields";
      return "please fill in form fields";
    }

    let isComment = data?.find((e) => e.comment == "" || e.comment == undefined);
    if (isComment) {
      return "Please enter comment for all comment fields.";
    }

    let isApprover = data?.find(
      (e) => e.approvers.length < 1 || e.approvers == null || !e.approvers
    );
    if (isApprover) {
      return "Please choose at least 1 approver";
    }

    return undefined;
  };

  const validateUpdateAnnual = async (data) => {
    if (data?.length == 0) {
      errorMessage = "please fill in form fields";
      return "please fill in form fields";
    }

    let isComment = data?.find((e) => e.comment == "" || e.comment == undefined);
    if (isComment) {
      return "Please enter comment for all comment fields.";
    }

    return undefined;
  };
  console.log('details', dashboardDetails)


  const handleSession = (data) => {

    if (Array.isArray(data) && data[0]?.reviewers?.length > 0) {
      setSession("review");
    }
  };

  const handlePerformanceState = (data) => {

    if (Array.isArray(data) && data[0]?.reviewers?.length > 0) {
      setPerformanceState('review')
    }
  }

  const handleLeadershipState = (data) => {

    if (Array.isArray(data) && data[0]?.reviewers?.length > 0) {
      setLeadershipState('review')
    }
  }

  const fetchPerformanceRating = async () => {
    try {
      const response = await getPerformanceAssessment();
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setPerformanceRating(response?.data?.data);
        setAnnualUpdatePerformanceRating(response?.data?.data?.performanceRating?.map((data)=>{
          return {
          assessmentId: data?.ratingId,
          score: data?.score,
          id: data?.id,
          achievement: data?.achievements,
          approvers: data?.reviewers,
        }
        }));
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {

    }
  }



  const fetchMyassessmentLeadership = async () => {
    try {
      const response = await getLeadershipAssessment();
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setLeadershipCapability(response?.data?.data);
        console.log(response?.data?.data?.userPerformanceLeadership)
        setAnnualUpdateLeadershipCapability(response?.data?.data?.userPerformanceLeadership?.map((data)=>{
          return {
          assessmentId: data?.ratingId,
          score: data?.score,
          id: data?.id,
          achievement: data?.achievements,
          personalityProfile: data?.personalityProfile ,
          approvers: data?.reviewers,
        }
        }));
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
  };






  const fetchAnnualData = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getAnnualData(dashboardDetails?.performanceId);
      console.log('RESPONS GET CALL', response?.data?.data);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setGoalsAndCommitments(response?.data?.data);
        console.log(response?.data?.data)
        setAnnualUpdateData(response?.data?.data?.map((data)=>{
          return {
            goalId: data?.goalId,
            score: 0,
            comment: data?.comment,
            approvers: data?.reviewers,
            id: data?.id,
          }
        }))
        console.log('SESSION', session);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsFetchingGoals(false);
  };

  useEffect(() => {
    handleLeadershipState(leadershipCapability?.userPerformanceLeadership)
    handlePerformanceState(performanceRating?.performanceRating)
    handleSession(goalsAndCommitments);
    console.log('SESSION', session, leadershipCapability, performanceRating);
    handlePending(goalsAndCommitments);
    
    

  }, [goalsAndCommitments,  session, performanceRating, leadershipCapability]);

  const reviewers = goalsAndCommitments.map((item) => item?.createdBy);
  const reviewer = Array.isArray(goalsAndCommitments.map((item) => item?.reviewers));

  console.log('reviwers SENT TO COMP', reviewers)
  console.log('approver', approverId)
  console.log('reviwers', reviewer)
  console.log('SESSION LEADERSHIP', leadershipState);
  console.log('SESSION PERFORMANCE', performanceState);
  console.log('SESSION', session)
  console.log("goalsAndCommitments:", goalsAndCommitments)

  const userID = Number(Cookies.get('userId'))

  

  const handlePending = (data) => {
    let isPending = data?.find((item) => item?.status !== 2);
    if (isPending) {
      setPending(false);
    } else {
      setPending(true);
    }
  };

  const initiator = Cookies.get('userId')

  const openDrawer = () => {
    if (!isDrawerOpen) {
      elementRef.current.style.width = "19rem";
      triggerRef.current.style.transform = "translate(-19rem)";
      triggerRef.current.style.transition = "0.5s";
      setIsDrawerOpen((prev) => !prev);
    } else {
      elementRef.current.style.width = "0";
      triggerRef.current.style.transform = "translate(0)";
      setIsDrawerOpen((prev) => !prev);
    }
  };



  const setComment = (comment, data) => {
    console.log('Comment:', comment);
    console.log('Data:', data);

    let recordExist;
    console.log('COMMENT', comment)
    let populatedData = populateAnnualData(goalsAndCommitments)

    let updatedArray;
    if (session === "review") {
      updatedArray = [...annualUpdateData];
    } else {
      updatedArray = [...annualData];
    }

    const index = updatedArray?.findIndex((obj) => obj?.goalId === data?.goalId);

    console.log('Updated Array:', updatedArray);
    console.log('Annual Data:', updatedArray?.map((data) => ({ ...data, approvers: approverId })));
    console.log('Annual Update Data:', updatedArray?.map((data) => ({ ...data, approvers: approverId })));


    if (index !== -1) {
      console.log('ONE')
      console.log('ONE SESSION', session)
      console.log('ONE updatedArray', updatedArray[index])
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        comment: comment,
      };
    } else {
      if (session === "review") {
        console.log('TWO')
        // Object doesn't exist, create a new one
        updatedArray?.push({
          goalId: data?.goalId,
          score: 0,
          id: data?.id,
          comment: comment,
          approvers: approverId,
        });
      } else {
        console.log('THREE')
        // Object doesn't exist, create a new one
        updatedArray?.push({
          goalId: data?.goalId,
          score: 0,
          comment: comment,
          approvers: approverId,
        });
      }
    }

    setAnnualData(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setAnnualUpdateData(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  const setPerformanceComment = (achievement, data) => {
    let recordExist;

    console.log(achievement)

    let updatedArray;
    if (performanceState === "review") {
      updatedArray = [...annualUpdatePerformanceRating];
    } else {
      updatedArray = [...annualPerformanceRating];
    }

    console.log(annualPerformanceRating)
    const index = updatedArray?.findIndex(
      (obj) => obj?.assessmentId === data?.ratingId
    );

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        achievement: achievement,
      };
    } else {
      if (performanceState === "review") {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: data?.score,
          id: data?.id,
          achievement: achievement,
          approvers: [],
        });
      } else {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: data?.score,
          achievement: achievement,
          approvers: [],
        });
      }
    }

    setAnnualPerformanceRating(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setAnnualUpdatePerformanceRating(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  const validateScore = (score) => {
    const numericScore = Number(score);
    if (isNaN(numericScore) || numericScore < 0 || numericScore > 10) {
      // If the entered score is not a number or is outside the 0-10 range, return a default value (you can customize this)
      return 0;
    }
    return numericScore;
  };

  const setPerformanceScore = (score, data) => {
    // Validate the score before proceeding
    const validatedScore = validateScore(score);

    let updatedArray;
    if (performanceState === "review") {
      updatedArray = [...annualUpdatePerformanceRating];
    } else {
      updatedArray = [...annualPerformanceRating];
    }

    const index = updatedArray?.findIndex(
      (obj) => obj?.assessmentId === data?.ratingId
    );

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        score: validatedScore,
      };
    } else {
      if (performanceState === "review") {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: validatedScore,
          id: data?.id,
          achievement: data?.achievement,
          approvers: [],
        });
      } else {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: validatedScore,
          achievement: data?.achievement,
          approvers: [],
        });
      }
    }

    console.log(updatedArray)
    setAnnualPerformanceRating(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setAnnualUpdatePerformanceRating(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  const setLeadershipComment = (achievement, data) => {
    let recordExist;

    let updatedArray;
    if (leadershipState === "review") {
      updatedArray = [...annualUpdateLeadershipCapability];
    } else {
      updatedArray = [...annualLeadershipCapability];
    }

    const index = updatedArray?.findIndex(
      (obj) => obj?.assessmentId === data?.ratingId
    );

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        achievement: achievement,
        personalityProfile: personalityProfile,
      };
    } else {
      if (leadershipState === "review") {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: data?.score,
          id: data?.id,
          achievement: achievement,
          personalityProfile: personalityProfile,
          approvers: [],
        });
      } else {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: data?.score,
          achievement: achievement,
          approvers: [],
        });
      }
    }

    setAnnualLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
        personalityProfile: personalityProfile,
      }))
    );

    setAnnualUpdateLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
        personalityProfile: personalityProfile,
      }))
    );
  };

  const updatePersonalityProfile = (personalityProfile) =>{
    let updatedArray;
    if (leadershipState === "review") {
      updatedArray = [...annualUpdateLeadershipCapability];
    } else {
      updatedArray = [...annualLeadershipCapability];
    }

    console.log('IN personality Profile', updatedArray, annualUpdateLeadershipCapability, leadershipState)

    setAnnualLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        personalityProfile: personalityProfile,
      }))
    )
    
    setAnnualUpdateLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        personalityProfile: personalityProfile,
      }))
    )

    console.log('IN After SET update personality Profile', updatedArray, annualUpdateLeadershipCapability, leadershipState)
  }

  const setLeadershipScore = (score, data) => {
    // Validate the score before proceeding
    const validatedScore = validateScore(score);

    let updatedArray;
    if (leadershipState === "review") {
      updatedArray = [...annualUpdateLeadershipCapability];
    } else {
      updatedArray = [...annualLeadershipCapability];
    }

    const index = updatedArray?.findIndex(
      (obj) => obj?.assessmentId === data?.ratingId
    );

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        score: validatedScore,
      };
    } else {
      if (leadershipState === "review") {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: validatedScore,
          id: data?.id,
          achievement: data?.achievement,
          approvers: [],
        });
      } else {
        // Object doesn't exist, create a new one
        updatedArray?.push({
          assessmentId: data?.ratingId,
          score: validatedScore,
          achievement: data?.achievement,
          approvers: [],
        });
      }
    }

    setAnnualLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setAnnualUpdateLeadershipCapability(
      updatedArray?.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  console.log('annual data', annualData)

  const saveAnnual = async () => {
    // let error;
    // if (session === "review") {
    //   error = await validateUpdateAnnual(annualUpdateData);
    // } else {
    //   error = await validateCreateAnnual(annualData);
    // }
    // if (error) {
    //   feedback({
    //     title: "Validation error",
    //     text: error,
    //     iconType: "warning",
    //   });
    //   return;
    // }

    console.log(approverId)

    if (session !== "review") {
      if (annualData?.length > 0) {
        const payload = annualData?.map((data) => ({
          goalId: data?.goalId,
          score: 0,
          comment: data?.comment,
          approvers: approverId,
        }));
        const response = await postAnnualMultiple(payload);
        console.log('annual data payload', payload)
        if (response?.data?.code === 1 || response?.data?.code === "1") {
          feedback({
            title: "Success",
            text: response?.data?.message,
            iconType: "success",
          });
          fetchAnnualData();
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } else {
      const payload = annualUpdateData?.map((data) => ({
        goalId: data?.goalId,
        score: 0,
        id: data?.id,
        comment: data?.comment,
        approvers: approverId.length > 0 ? approverId : (data?.approvers?.map((data) => ({
          approverID: data?.approverId,
          priority: data?.sequence
        }))),
      }));
      const response = await putAnnualMultiple(payload);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchAnnualData();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
  };

  const isFormValid = () => {
    // Check if all required fields are filled
    let performanceData, leadershipData;

    if (session !== 'review') {
      performanceData = annualPerformanceRating;
      leadershipData = annualLeadershipCapability;
    } else {
      performanceData = annualUpdatePerformanceRating;
      leadershipData = annualUpdateLeadershipCapability;
    }

    const isValidLeadershipData = leadershipData?.length > 0 && leadershipData?.every((item) => {
      console.log('Leadership Data:', item);
      return (
        item?.score !== undefined &&
        item?.achievement !== undefined &&
        typeof item?.achievement === 'string' &&
        item?.achievement.trim() !== '' &&
        Array.isArray(item?.approvers)
      );
    });

    const isValidPerformanceData = performanceData?.length > 0 && performanceData?.every((item) => {
      console.log('Performance Data:', item);
      return (
        item?.score !== undefined &&
        item?.achievement !== undefined &&
        typeof item?.achievement === 'string' &&
        item?.achievement.trim() !== '' &&
        Array.isArray(item?.approvers)
      );
    });

    console.log('isValidPerformanceData:', isValidPerformanceData);
    console.log('isValidLeadershipData:', isValidLeadershipData);

    if (isValidPerformanceData && isValidLeadershipData) {
      console.log('Form is valid');
      return true;
    }

    console.log('Performance Data:', performanceData);
    console.log('Leadership Data:', leadershipData);
    console.log('Form is not valid');
    return true;
  };



  const savePerformanceAnnual = async () => {
    console.log('performance session is:', performanceState, annualPerformanceRating, annualUpdateLeadershipCapability, approverId)
    if (performanceState !== "review") {
      const payload = annualPerformanceRating?.map((data) => ({
        assessmentId: data?.assessmentId,
        score: data?.score,
        id: data?.id,
        achievement: data?.achievement,
        approvers: approverId,
      }));
      if (annualPerformanceRating?.length > 0) {
        console.log(annualPerformanceRating)
        const response = await postPerformanceRatingMultiple(
          payload
        );

        console.log("response", response)
        if (response?.data?.code === 1 || response?.data?.code === "1") {
          fetchPerformanceRating()
          feedback({
            title: "Success",
            text: response?.data?.message,
            iconType: "success",
          });
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } else {
      const payload = annualUpdatePerformanceRating?.map((data) => ({
        assessmentId: data?.assessmentId,
        score: data?.score,
        id: data?.id,
        achievement: data?.achievement,
        approvers: approverId.length > 0 ? approverId : (data?.approvers?.map((data) => ({
          approverID: data?.approverId,
          priority: data?.sequence
        }))),
      }));
      const response = await putPerformanceRatingMultiple(
        payload
      );
      console.log(annualPerformanceRating)
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        fetchPerformanceRating()
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }

  };

  const saveLeadershipAnnual = async () => {

    console.log('PProfile', personalityProfile, annualLeadershipCapability, annualUpdateLeadershipCapability, approverId)

    if (leadershipState !== "review") {
      const payload = annualLeadershipCapability?.map((data) => ({
        assessmentId: data?.assessmentId,
        score: data?.score,
        achievement: data?.achievement,
        approvers: approverId,
        personalityProfile: personalityProfile || data?.personalityProfile
      }));
      if (annualLeadershipCapability?.length > 0) {
        console.log(annualLeadershipCapability)
        const response = await postLeadershipCapabilityMultiple(
          payload
        );
        console.log("Res in sub leadership", response)

        if (response?.data?.code === 1 || response?.data?.code === "1") {
          fetchMyassessmentLeadership()
          feedback({
            title: "Success",
            text: response?.data?.message,
            iconType: "success",
          });
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } else {
      console.log('Update', annualUpdateLeadershipCapability)
      const payload = annualUpdateLeadershipCapability?.map((data) => ({
        assessmentId: data?.assessmentId,
        score: data?.score,
        id: data?.id,
        achievement: data?.achievement,
        approvers: approverId.length > 0 ? approverId : (data?.approvers?.map((data) => ({
          approverID: data?.approverId,
          priority: data?.sequence
        }))),
        personalityProfile: personalityProfile || data?.personalityProfile
      }));
      console.log(payload)
      const response = await putLeadershipCapabilityMultiple(
        payload
      );

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        fetchMyassessmentLeadership()
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
  };

  const submitAnnual = async () => {
    const payload = goalsAndCommitments?.map((data, index) => (
      data?.id

    ));
    const response = await putAnnualSubmitMultiple(payload);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }

  const submitPerformanceRating = async () => {
    console.log('SUBMIT', performanceRating)
    const payload = performanceRating?.performanceRating?.map((data) => (
      data?.id
    ));
    const response = await putPerformanceRatingSubmitMultiple(payload);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }


  const submitLeadershipCapability = async () => {
    console.log('SUBMIT', leadershipCapability)
    const payload = leadershipCapability?.userPerformanceLeadership?.map((data) => (
      data?.id
    ));
    const response = await putLeadershipCapabilitySubmitMultiple(payload);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      console.log('SUBMIT Successful');
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
    } else {
      console.log('SUBMIT Failed')
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }

  console.log("USERID", userID, code, wishesID)
  const submitStaffView = async () => {
    if (code !== 1) {
      const payload = {
        wishes: staffViews,
      };
      const response = await createEmployeeWish(payload);
      if (response?.data?.code === 1 || response?.data?.code === "1") {

        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
    else {
      const payload = {
        wishes: wishes,
        id: wishesID
      };
      const response = await putEmployeeWish(payload);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (openTab === 0) {
      feedback({
        title: "Success",
        text: 'Submitted',
        iconType: "success",
      });
      // await submitAnnual();
      // await submitPerformanceRating();
      // await submitLeadershipCapability();

    } else if (openTab === 1) {
      submitStaffView()

    } else if (openTab === 2) {
      feedback({
        title: "Success",
        text: 'Submitted',
        iconType: "success",
      });
    } else {
      await submitAnnual();
      await submitPerformanceRating();
      await submitLeadershipCapability();
      await fetchAnnualData()
      await fetchMyassessmentLeadership()
      await fetchPerformanceRating()
      if((!disableSubmitbutton) && (!disableSubmitperformance) && (!disableSubmitgoal)){
        disableSubmit = true;
      }      
      feedback({
        title: "Success",
        text: 'Submitted',
        iconType: "success",
      });
    }
  };

  const handleProceed = () => {
    if (openTab < 3) {
      setOpenTab(openTab + 1)
    }
  }

  



  return (
    <div className="w-100 ">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Appraisal</h2>
        </div>
        <LocationDetails />
      </div>
      <p className="flex space-between bottom-border ">
        <h2>Annual Performance Review</h2>
        <div className="flex">
          <div
            onClick={() => setOpenTab(0)}
            className={
              openTab === 0
                ? "flex annual_button m-r-10 "
                : "flex annual_button1 m-r-10 "
            }
          >
            Performance Review
          </div>
          <div
            onClick={() => setOpenTab(1)}
            className={
              openTab === 1
                ? "flex annual_button m-r-10 "
                : "flex annual_button1 m-r-10 "
            }
          >
            Staff View &amp; Wishes
          </div>
          <div
            onClick={() => setOpenTab(2)}
            className={
              openTab === 2
                ? "flex annual_button m-r-10 "
                : "flex annual_button1 m-r-10 "
            }
          >
            Career Plans
          </div>
          <div
            onClick={() => setOpenTab(3)}
            className={
              openTab === 3
                ? "flex annual_button m-r-10 "
                : "flex annual_button1 m-r-10 "
            }
          >
            Training and Development
          </div>
        </div>
      </p>



      <div>

        {openTab === 0 && (
          <div >
            {/* {dashboardDetails?.stage === "Annual Appraisal" && */}
              <>
                <div >

                  <Collapsible
                    icon={
                      <HiOutlineUserCircle size={28} className="collapsible-icon" />
                    }
                    title="Approvers Setup"
                    setApproverId={setApproverId}
                    approverId={approverId}
                    setApprovers={setApprovers}
                    employeeName={employeeName}
                    setemployeeName={setemployeeName}
                    recipientMda={recipientMda}
                    setrecipientMda={setrecipientMda}
                  />
                </div>
                <div >

                  <Collapsible
                    icon={
                      <HiOutlineUserCircle size={28} className="collapsible-icon " />
                    }
                    title={<h4>My Personal Targets & Performance (Quantitative)</h4>}
                    component={
                      <Performance
                        page='annual'
                        goalsAndCommitments={goalsAndCommitments}
                        handleReview={handleReview}
                        saveAnnual={saveAnnual}
                        disableSubmit={disableSubmit}
                        setComment={setComment}
                        session={session}
                        approverId={approverId}
                        showDetails={showDetails}
                      />
                    }
                  />
                </div>
                <div>
                  <Collapsible
                    icon={
                      <HiOutlineUserCircle size={28} className="collapsible-icon" />
                    }
                    title={<h4>Performance Rating</h4>}
                    component={
                      <PerformanceRating
                        sampleReviewerList={reviewer}
                        session={session}
                        savePerformance={savePerformanceAnnual}
                        approverId={approverId}
                        disableSubmit={disableSubmit}
                        performanceRating={performanceRating}
                        setComment={setPerformanceComment}
                        setScore={setPerformanceScore}
                        showDetails={showDetails}
                      />
                    }
                  />

                </div>
                <div >

                  <Collapsible
                    icon={
                      <HiOutlineUserCircle size={28} className="collapsible-icon" />
                    }
                    title={<h4>Assessment Of Leadership Capabilities</h4>}
                    component={
                      <LeadershipAssessment
                        sampleReviewerList={reviewer}
                        setComment={setLeadershipComment}
                        saveLeadership={saveLeadershipAnnual}
                        leadershipCapability={leadershipCapability}
                        disableSubmit={disableSubmit}
                        setPersonalityProfile={updatePersonalityProfile}
                        setScore={setLeadershipScore}
                        personalityProfile={personalityProfile}
                        showDetails={showDetails}
                      />
                    }
                  />
                </div>
              </>
            {/* } */}
          </div>
        )}
        {openTab === 1 && (
          <StaffView setStaffViews={setStaffViews} setWishesId={setWishesId} disableSubmit={disableSubmit} initiator={initiator} disable={disable} setCode={setCode} wishes={wishes} setWishes={setWishes} staffViews={staffViews} fromDashboard={fromDashboard} annualRecord = {annualRecord} />
        )}
        {openTab === 2 && <CareerPlans initiator={initiator} disable={disable} disableSubmit={disableSubmit} fromDashboard={fromDashboard} annualRecord = {annualRecord} />}
        {openTab === 3 && <TrainingDevelopment  initiator={initiator} disable={disable} disableSubmit={disableSubmit} fromDashboard={fromDashboard} annualRecord = {annualRecord} />}

      </div>
      <Declaration />

      <div className="submit-goal-container flex space-between">
        <button
          onClick={handleProceed}
          type="button"
          className="btn m-r-10 btn-large"
          disabled={(dashboardDetails?.stage !== "Annual Appraisal") || openTab === 3}
        >
          Proceed
        </button>
        { (openTab === 1 || openTab === 3) &&
        <button
          onClick={handleSubmit}
          type="button"
          className="btn m-r-10 btn-large"
          disabled={(dashboardDetails?.stage !== "Annual Appraisal") || disableSubmit || fromDashboard} 
        >
          {openTab === 1 ? 'Save' : 'Submit'}
        </button>
        }
      </div>
      {isShowFormModal && (
        <ReviewForm
          refreshList={fetchAnnualData}
          details={details}
          formDetails={formDetails}
          closeModal={() => setShowFormModal(false)} />
      )}
    </div>
  );
};

export default AnnualAppraisal;
