export const quarterlyAppraisalData = [
  {
    id: 1,
    commitment:
      "Preparation and submission of Appropriation Account for two years to the Auditor General",
    assignedWeight: 20,
  },
  {
    id: 1,
    commitment:
      "Preparation and submission of Appropriation Account for two years to the Auditor General",
    assignedWeight: 30,
  },
  {
    id: 1,
    commitment:
      "Preparation and submission of Appropriation Account for two years to the Auditor General",
    assignedWeight: 10,
  },
  {
    id: 1,
    commitment:
      "Preparation and submission of Appropriation Account for two years to the Auditor General",
    assignedWeight: 10,
  },
  {
    id: 1,
    commitment:
      "Preparation and submission of Appropriation Account for two years to the Auditor General",
    assignedWeight: 20,
  },
];
