import { createContext, useState } from "react";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [annualRecord, setAnnualRecord] = useState({});
  const [fromDashboard, setFromDashboard] = useState(false)


  return (
    <MyContext.Provider value={{annualRecord, setAnnualRecord, fromDashboard, setFromDashboard}}>
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
