/* eslint-disable import/no-anonymous-default-export */
import React from "react";

import { RiBarChartFill } from "react-icons/ri";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { GrUserSettings } from "react-icons/gr";
import Cookies from "js-cookie";

export default [
  {
    title: "Home",
    href: "/admin-portal/home",
    icon: <AiOutlineHome className="icon" />,
    stage: "default",
  },
  {
    title: "Dashboard",
    href: "#",
    icon: <BiEnvelope className="icon" />,
    stage: "default",
  },
  {
    title: "Performance",
    href: "",
    icon: <GrUserSettings className="icon" />,
    children: [
      // {
      //   title: "Departmental Goal",
      //   href: "/admin-portal/appraisal-period",
      //   icon: "CP",
      //   isDisplayBasedOnRoles: true,
      // },
      {
        title: "Goal Setting",
        href: "/admin-portal/goal-setting",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
      {
        title: "Quarterly Appraisal",
        href: "/admin-portal/quarterly-appraisal",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
      {
        title: "Mid Year Review",
        href: "#",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
      {
        title: "Annual Appraisal",
        href: "/admin-portal/annual-appraisal",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
      {
        title: "Goal Setting Review",
        href: "/admin-portal/goal-setting-review",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
      // {
      //   title: "Appraisal Review",
      //   href: "/admin-portal/appraisal-review",
      //   icon: "ED",
      //   isDisplayBasedOnRoles: true,
      // },
    ],
    stage: "default",
  },
  {
    title: "Setup",
    href: "/admin-portal/performance-planning",
    icon: <RiBarChartFill className="icon" />,
    stage: "planning",
    isDisplayBasedOnRoles: true,
  },
  {
    title: "Report",
    href: "/admin-portal/planning-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "planning",
    isDisplayBasedOnRoles: true,
  },
  {
    title: "Logout",
    href: "/admin-portal/logout",
    icon: <BiLogOutCircle className="icon" />,
    stage: "default",
  },
];
