import { useEffect, useState } from "react";
import LocationDetails from "../../components/layouts/LocationDetails";
import { RiAddLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import {
  getPerformanceGoalDetails,
  getPerformanceGoalsData,
  submitScore,
} from "../../network/services";
import Cookies from "js-cookie";
import { feedback } from "../../utils/feedback";
import {
  GET_GOALS,
  GET_PERFORMANCE_DETAILS,
  SET_GOALS_LOADER,
  SET_PERFORMANCE_DETAILS_LOADER,
  SUBMIT_SCORE,
  SUBMIT_SCORE_LOADER,
} from "../../redux/actions/actionsTypes";

const QuarterlyEntry = () => {
  const [goalDetails, setGoalDetails] = useState([]);
  const [performance, setPerformance] = useState([]);

  const fetchPerformanceGoalDetailsWithData = async (data) => {
    try {
      let response;
      if (data !== null) {
        response = await getPerformanceGoalsData(data);
      } else {
        response = await getPerformanceGoalDetails();
      }
      if (response.status === 200) {
        console.log(response);
        dispatch({
          type: GET_PERFORMANCE_DETAILS,

          payload: response?.data?.data,
        });
        setPerformance(response?.data?.data);
      } else {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_PERFORMANCE_DETAILS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleSubmitScore = async (data, id) => {
    try {
      const response = await submitScore(data);
      if (response.status === 200) {
        console.log(response);
        dispatch({
          type: SUBMIT_SCORE,
          payload: response?.data?.data,
        });

        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchPerformanceGoalDetailsWithData(id);
      } else {
        dispatch({
          type: SUBMIT_SCORE,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_SCORE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const periodComment = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneComment";
      case "Quarter 2 Appraisal":
        return "quaterTwoComment";
      case "Quarter 3 Appraisal":
        return "quaterThreeComment";
      case "Quarter 4 Appraisal":
        return "quaterFourComment";
    }
  };

  const periodScore = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneScore";
      case "Quarter 2 Appraisal":
        return "quaterTwoScore";
      case "Quarter 3 Appraisal":
        return "quaterThreeScore";
      case "Quarter 4 Appraisal":
        return "quaterFourScore";
    }
  };

  const dispatch = useDispatch();
  let payload = [];
  // let [performance, setPerformance] = useState({})

  const goals = useSelector((state) => state?.goals);

  const periodDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const submit = () => {
    handleSubmitScore(payload, periodDetails.performanceId);
  };

  useEffect(() => {
    getInit();
  }, [periodDetails]);

  const getInit = async () => {
    const res = await getPerformanceGoalsData(
      parseInt(periodDetails?.performanceId)
    );
    setPerformance(res?.data?.data);
  };

  // const performance = useSelector(
  //     (state) => state?.performanceDetails.performanceDetails
  // );

  const handleChangeScore = (goal, score) => {
    let index = payload.findIndex((value) => value.id === goal.id);
    if (index < 0) {
      payload.push({
        id: goal?.id,
        performanceId: goal?.performanceId,
        [periodComment(periodDetails?.stage)]: "",
        [periodScore(periodDetails?.stage)]: parseInt(score),
      });
    } else {
      payload[index] = {
        ...payload[index],
        [periodScore(periodDetails?.stage)]: parseInt(score),
      };
    }
  };

  var checker = performance[0];
  console.log(performance);
  checker && console.log(checker[periodComment(periodDetails?.stage)]);
  console.log(periodDetails);
  console.log(checker);
  const handleChangeComment = (goal, comment) => {
    let index = payload.findIndex((value) => value.id === goal.id);
    if (index < 0) {
      payload.push({
        id: goal?.id,
        performanceId: goal?.performanceId,
        [periodComment(periodDetails?.stage)]: "",
        [periodScore(periodDetails?.stage)]: 0,
      });
    } else {
      payload[index] = {
        ...payload[index],
        [periodComment(periodDetails?.stage)]: comment,
      };
    }
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <div>
          <p>Good Day</p>
          <p>{Cookies.get("fullName")}</p>
          <p>{Cookies.get("_Role")}</p>
        </div>
        <LocationDetails />
      </div>

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p>Period</p>
            </div>
            <div className="th">
              <p>{periodDetails?.stage} start Date</p>
            </div>
            <div className="th">
              <p>{periodDetails?.stage} End Date</p>
            </div>
            <div className="th">
              <p></p>
            </div>
          </div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="td">
              {`${periodDetails?.year} ${periodDetails?.stage}`}
            </div>
            <div className="td">{periodDetails?.startDate}</div>
            <div className="td">{periodDetails?.endDate}</div>
            <div className="td">Active</div>
            <div className="td">
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p>Targets/Goals/Commitments</p>
            </div>
            <div className="th">
              <p>Weight</p>
            </div>
            <div className="th">
              <p>Sequence</p>
            </div>
            <div className="th">
              <p>{periodDetails?.stage} Score (../100)</p>
            </div>
            <div className="th flex space-between ">
              <p>{periodDetails?.stage} Comment</p>
            </div>
            <div className="th"></div>
          </div>
        </div>
        <div className="table-body">
          {Array.isArray(performance) &&
            performance?.map((data) => (
              <div className="table-row" key={data.id}>
                <div className="td">{data.objective}</div>
                <div className="td">{data.weight}</div>
                <div className="td">{data.priority}</div>

                <div className="td">
                  {data[periodScore(periodDetails?.stage)] ? (
                    data[periodScore(periodDetails?.stage)]
                  ) : (
                    <input
                      defaultValue={null}
                      type="number"
                      className="table-input"
                      onChange={(e) => handleChangeScore(data, e.target.value)}
                    />
                  )}
                </div>
                <div className="td">
                  {data[periodComment(periodDetails?.stage)] ? (
                    data[periodComment(periodDetails?.stage)]
                  ) : (
                    <textarea
                      placeholder="comment if any..."
                      name="quarter comment"
                      id=""
                      cols="80"
                      rows="5"
                      onChange={(e) =>
                        handleChangeComment(data, e.target.value)
                      }
                    ></textarea>
                  )}
                </div>
              </div>
            ))}
          {/* <div className='table-row'>
                        <div className="td">

                        </div>
                        <div className="td">100</div>
                        <div className="td">0.00%</div>
                        <div className="td"></div>
                    </div> */}
          {checker && checker[periodComment(periodDetails?.stage)] === null && (
            <button
              className="add-button pointer"
              type="button"
              onClick={() => submit()}
            >
              <RiAddLine />
              Submit Goals Details
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuarterlyEntry;
