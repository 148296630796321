import React from "react";
import { useState, useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import CompetenceActionTable from "./CompetenceActionTable";
import DevelopmentGapsTable from "./DevelopmentGapsTable";
import {
  getDevelopmentGap,
  getDevelopmentActionPlan,
} from "../../network/services";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
import DownloadTrainingAndDev from "./DownloadTrainingAndDev";



const CareerPlans = ({ initiator, disableSubmit, disable, fromDashboard, annualRecord }) => {
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [developmentGap, setDevelopmentGap] = useState([]);
  const [developmentActionPlan, setDevelopmentActionPlan] = useState([]);
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);




  let creator;

  // if (Array.isArray(firstReviewer) && firstReviewer.length > 0) {
  //   const firstElement = firstReviewer[0];

  //   if (Array.isArray(firstElement)) {
  //     // If it's an array of arrays, get the appraiseeId from the first element of the first array
  //     initiator = firstElement[0]?.appraiseeId;
  //   } else if (typeof firstElement === 'object' && firstElement !== null) {
  //     // If it's a simple array, get the appraiseeId from the first object
  //     initiator = firstElement.appraiseeId;
  //   }
  // }

  // // If initiator is still undefined, set it to the entire firstReviewer array as a fallback
  creator = initiator;

  console.log(creator, fromDashboard)

  useEffect(() => {
    if (fromDashboard === false) {
      fetchDevelopmentGap();
      fetchDevelopmentActionPlan();
    }else{
      setDevelopmentGap(annualRecord?.developmentGap)
      setDevelopmentActionPlan(annualRecord?.developmentActionPlan)
    }
  }, []);


  console.log('dev gap', developmentGap)
  console.log('dev actPlan', developmentActionPlan)


  const fetchDevelopmentGap = async () => {
    setIsLoading(true);
    try {
      const response = await getDevelopmentGap(creator);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setDevelopmentGap(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsLoading(false);
  };

  const fetchDevelopmentActionPlan = async () => {
    setIsLoading(true);
    try {
      const response = await getDevelopmentActionPlan(creator);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setDevelopmentActionPlan(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsLoading(false);
  };
  console.log('dev  plan:', developmentGap)
  return (
    <PDFExport
      paperSize="A2"
      margin="2mm"
      fileName={'Training & Developement.pdf'}
      ref={pdfExportComponent}
      // paperSize="A3"
      // margin="2mm"
      scale={0.8}
      keepTogether=".p"
      forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    // ref={pdfExportComponent}
    >
      {!isShowDownloadModal && (
        <div className="pdf-section">
          {disable && (
            <div className="mb5 flex justify-content-center m-b-20 m-t-40">
              <button
                className="btn btn-large"
                onClick={() => {
                  setShowDownloadModal(true)
                }}
              >
                View Download Format
              </button>
            </div>
          )}

          <h4 className="m-t-10">Training & Development</h4>
          <div>
            <div className="flex ">
              <button
                className={
                  tab === 2
                    ? "btn-gray m-r-10 career_button "
                    : "btn career_button m-r-10 "
                }
                onClick={() => setTab(1)}
              >
                Identified Development Gaps
              </button>
              <button
                className={
                  tab === 1
                    ? "btn-gray m-r-10 career_button1"
                    : "btn career_button1 m-r-10"
                }
                onClick={() => setTab(2)}
              >
                Competence Development Action Plan and Targets
              </button>
            </div>
            {tab === 2 ? (
              <CompetenceActionTable
                disable={disable}
                initiator={initiator}
                disableSubmit={disableSubmit}
                developmentActionPlan={developmentActionPlan}
                fetchDevelopmentActionPlan={fetchDevelopmentActionPlan}
                developmentGap={developmentGap}
              />
            ) : (
              <DevelopmentGapsTable
                initiator={initiator}
                disable={disable}
                disableSubmit={disableSubmit}
                fetchDevelopmentGap={fetchDevelopmentGap}
                developmentGap={developmentGap}
              />
            )}
          </div>
        </div>
      )}
      {isShowDownloadModal && (
        <DownloadTrainingAndDev
          developmentGap={developmentGap}
          developmentActionPlan={developmentActionPlan}
          closeModal={() => { setShowDownloadModal(false) }}
        />
      )}
    </PDFExport>


  );
};
export default CareerPlans;
