import React, { useEffect, useState } from "react";

import { IoMdClose } from "react-icons/io";
import SelectInput from "../inputs/SelectInput";
import { v4 as uuidv4 } from "uuid";
import TextInput from "../inputs/TextInput";

const ViewApproversComment = ({ closeModal, approversComments, isLoading, data }) => {


    return (
        <div className="overlay">
            <IoMdClose className="close-btn pointer" onClick={closeModal} />
            <div
                className="modal-box w-50"
                style={{ overflowY: "auto", height: "50vh" }}
            >

                <div className="table-view m-t-10">
                    <div className="table-header">
                        <div className="table-row">
                            <div className="th">
                                <p className="san-sherif">approver's Name</p>
                            </div>
                            <div className="th">
                                <p className="san-sherif">Last Modified</p>
                            </div>
                            <div className="th">
                                <p className="san-sherif">Approver's Comment</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-body">
                        {approversComments && approversComments?.map((approver) => (
                            <div className="table-row">
                                <div className="td">{approver?.approver}</div>
                                <div className="td">{approver?.modifiedDate}</div>
                                <div className="td">{approver?.goalComment}</div>
                            </div>
                        ))
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ViewApproversComment;
