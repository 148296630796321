import React, { useState, useEffect } from "react";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";
import TextInput from "../inputs/TextInput";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";


const PersonalTargetsModal = ({
  goalsAndCommitments,
  closeModal,
  isShowDownloadModal
}) => {
  const pdfExportComponent = React.useRef(null);



  useEffect(() => {
    const yearElement = document.getElementById("currentYear");
    if (yearElement) {
      const currentYear = new Date().getFullYear();
      yearElement.textContent = currentYear;
    }
  }, []);




  console.log("goals:", goalsAndCommitments, pdfExportComponent.current)

  return (
    <div className=" card " id="pdf-section">

      <div className="flex space-between">
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={closeModal}
          >
            Back to record
          </button>
        </div>
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent?.current.save();
              }
            }}
          >
            Download PDF
          </button>
        </div>
      </div>
      <PDFExport
        paperSize="A2"
        margin="2mm"
        fileName={'Personal Target.pdf'}
        ref={pdfExportComponent}
        // paperSize="A3"
        // margin="2mm"
        scale={0.8}
        keepTogether=".p"
        forcePageBreak=".page-break"
      // fileName={`${pdfName}.pdf`}
      // ref={pdfExportComponent}
      >

        <div className="center-text">
          <img src={EdsgLogo} alt="logo" className="brand" width="150" />
          <h3 className="m-t-10 m-b-20">
            Edo State 2023 Annual Appraisal Personal Target(s)
            {/* <span id="currentYear"></span> */}
          </h3>
          <h3 className="m-t-10 m-b-20 bold-text">
            {Cookies.get('fullName').toUpperCase()}
          </h3>
        </div>

        <p className="sub-header">
          The Head of the Department in consultation with my Division/Section/Unit
          set out to achieve the following
        </p>
        <div className="table-view m-t-10 score-table">
          <div className="table-header">
            <div className="table-row score-table-header-row ">
              <div
                className="th san-sherif score-table-th"
                style={{ width: "20%" }}
              >
                <p>Commitments</p>
              </div>
              <div
                className="th san-sherif score-table-th"
                style={{ width: "15%" }}
              >
                <p>KPIs</p>
              </div>
              <div
                className="th san-sherif score-table-th"
                style={{ width: "40%" }}
              >
                <p>Employee's Comments</p>
                {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
              </div>
              {goalsAndCommitments[0]?.reviewers?.length > 0 && (
                <div className="th san-sherif score-table-th">
                  <p>Reviewers</p>
                </div>
              )}
            </div>
          </div>
          <div
            className="table-body score-table-body"
            style={{ overflow: "scroll" }}
          >
            {goalsAndCommitments?.map((item, index) => (
              <div
                className={`table-row score-table-row m-t-10 
             ${item?.status === false && "bg-light-green"}`}
                key={index}
              >
                <div className="td td-cell score-table-td">
                  <h3 className="bold-text">{item?.objective}</h3>
                </div>
                <div className="td td-cell score-table-td">
                  {item?.kpis?.map((kpi) => (
                    <div>&nbsp;&nbsp;&nbsp; -{kpi?.performanceIndicator}</div>
                  ))}
                </div>
                <div className="td score-table-td h-100">
                  {
                    <textarea
                      rows={item?.kpis?.length + 2}
                      className="w-100 h-100"
                      value={item?.comment}
                      disabled={(item?.status !== 1 && item?.statusName !== "Unasigned") && item?.statusName !== 'Review'}
                    />
                  }
                </div>
                {item?.reviewers?.length > 0 && (
                  <div className="td score-table-td">
                    {item?.reviewers?.map((item, index) => (
                      <Reviewer
                        key={item.id}
                        isReviewed={item?.goalComment !== ''}
                        score={item?.score}
                        comment={item?.goalComment}
                        office={`${item?.role}, ${item?.approverMDA}`}
                        name={item?.approver}
                        date={item?.date}
                        details={item}
                        isShowDownloadModal={isShowDownloadModal}
                        showButtons={false}
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

        </div>
      </PDFExport>

    </div>
  )
};

export default PersonalTargetsModal;
