export const sampleReviewerList = [
  {
    id: 1,
    name: "Ehigie Uwumarongie",
    isReviewed: true,
    office: "DFA, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: 95,
  },
  {
    id: 2,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 3,
    name: "Ehigie Uwumarongie",
    isReviewed: true,
    office: "DFA, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: 95,
  },
  {
    id: 4,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 5,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 6,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 7,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 8,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 9,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 10,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 11,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 12,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 13,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
  {
    id: 14,
    name: "Idemudia Isi",
    isReviewed: false,
    office: "Officer 2, Ministry of Education",
    dateTime: "20.11.20/14.05.23PM",
    score: null,
  },
];
