import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import Spinner from "../../common/spinner/spinner";
import TextareaInput from "../../inputs/TextareaInput";
import {
  createDevelopmentGap,
  createLongPlan,
  updateDevelopmentGap,
} from "../../../network/services";
import TextInput from "../../inputs/TextInput";
import { feedback } from "../../../utils/feedback";

const DevelopmentGapForm = ({
  closeModal,
  handleSave,
  loading,
  initiator,
  disable,
  formDetails,
  setLoading,
  details,
  shortTerm,
  setDetails,
  fetchDevelopmentGap
}) => {
  const [inputData, setInputData] = useState();

  console.log(details);
  console.log(formDetails);

  const userId = localStorage.getItem('userId')

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const editPlan = async () => {
    const payload = {
      title: inputData?.title || details?.title,
      details: inputData?.details || details?.details,
      expectedOutcome: inputData?.expectedOutcome || details?.expectedOutcome,
      dependency: inputData?.dependency || details?.dependency,
      id: details?.id
    }
    const response = await updateDevelopmentGap(payload);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentGap()
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  };

  const addPlan = async () => {
    const response = await createDevelopmentGap({
      ...inputData,
    });
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentGap()
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    if (details.id) {
      editPlan();
    } else {
      addPlan();
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={()=>{clearForm(); closeModal(); setDetails([]); }} />
      <div className="modal-box max-w-500">
        <form
          id="modal-form"
          onSubmit={(e) => {
            // handleSave(e, inputData);
            handlSubmit(e);
            clearForm();
            setDetails([])
          }}
        >
          <div className="content">
            <TextInput
              className="w-100 m-b-10"
              name="title"
              label="Title"
              type="text"
              onChange={handleChange}
              value={inputData?.title || (formDetails && formDetails.title) || ""}
            />

            <TextareaInput
              className="w-100 m-b-10"
              name="details"
              label="Details"
              type="text"
              rows="5"
              onChange={handleChange}
              value={inputData?.details || (formDetails && formDetails.details) || ""}
            />

            <TextInput
              className="w-100 m-b-10"
              name="dependency"
              label="Dependency"
              type="text"
              onChange={handleChange}
              value={inputData?.dependency || (formDetails && formDetails.dependency) || ""}
            />

            <TextareaInput
              className="w-100 m-b-10"
              name="expectedOutcome"
              label="Expected Outcome"
              type="text"
              rows="5"
              onChange={handleChange}
              value={inputData?.expectedOutcome || (formDetails && formDetails.expectedOutcome) || ""}
            />

            {disable && (
              <div className="flex justify-content-end w-100">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-large flex_basis_48 w-100"
                >
                  {loading && <Spinner />} Save
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DevelopmentGapForm;
