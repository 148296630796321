import React, { useState,useEffect } from "react";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";
import TextInput from "../inputs/TextInput";
import { PDFExport } from "@progress/kendo-react-pdf";
import PersonalTargetsModal from "./DownloadPersonalTargets";
import { useContext } from "react";
import MyContext from "../../utility/Context";

const Performance = ({
  goalsAndCommitments,
  setScore,
  setComment,
  handleReview,
  showDetails,
  disableSubmit,
  saveAnnual
}) => {
  const [commentValue, setCommentValue] = useState([]);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);

  const { setAnnualRecord, annualRecord } = useContext(MyContext);
  

  


  const handleCommentChange = (e, item) => {
    const enteredComment = e?.target?.value;
   
    setComment(enteredComment,item);

    console.log(item)
    console.log('comment prformance', enteredComment)
    setCommentValue((prevValues) => ({
      ...prevValues,
      [item?.goalId]: enteredComment,
    }));
  };

  console.log("goals:",goalsAndCommitments)

  return (
    <PDFExport
    paperSize="A2"
    margin="2mm"
    fileName={'Personal Target.pdf'}
    // ref={pdfExportComponent}
    // paperSize="A3"
    // margin="2mm"
    scale={0.8}
    keepTogether=".p"
    forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    // ref={pdfExportComponent}
    >
    {!isShowDownloadModal && (
    <div className="card  " id="pdf-section">
      

    <div className="mb5 flex justify-content-center m-b-20 m-t-40">
            <button
              className="btn btn-large"
              onClick={() => {
                setShowDownloadModal(true)
              }}
            >
              View Download Format
            </button>
          </div>
          
      
      <p className="sub-header">
        The Head of the Department in consultation with my Division/Section/Unit
        set out to achieve the following
      </p>
      <div className="table-view m-t-10 score-table">
        <div className="table-header">
          <div className="table-row score-table-header-row ">
            <div
              className="th san-sherif score-table-th"
              style={{ width: "20%" }}
            >
              <p>Commitments</p>
            </div>
            <div
              className="th san-sherif score-table-th"
              style={{ width: "15%" }}
            >
              <p>KPIs</p>
            </div>
            <div
              className="th san-sherif score-table-th"
              style={{ width: "40%" }}
            >
              <p>Employee's Comments</p>
              {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
            </div>
            {goalsAndCommitments[0]?.reviewers?.length > 0 && (
              <div className="th san-sherif score-table-th">
                <p>Reviewers</p>
              </div>
            )}
          </div>
        </div>
        <div
          className="table-body score-table-body"
          style={{ overflow: "scroll" }}
        >
          {goalsAndCommitments?.map((item, index) => (
            <div
              className={`table-row score-table-row m-t-10 
             ${item?.status === false && "bg-light-green"}`}
              key={index}
            >
              <div className="td  score-table-td">
                <h3 className="bold-text">{item?.objective}</h3>
              </div>
              <div className="td score-table-td">
                {item?.kpis?.map((kpi) => (
                  <div>&nbsp;&nbsp;&nbsp; -{kpi?.performanceIndicator}</div>
                ))}
              </div>
              <div className="td score-table-td h-100">
                {
                  <textarea
                    rows={item?.kpis?.length + 2}
                    className="w-100 h-100"
                    onChange={(e) => handleCommentChange(e, item)}                  
                    value={commentValue[item?.goalId] || '' || item?.comment }
                    disabled={(item?.status !== 1 && item?.statusName !== "Unasigned") && item?.statusName !== 'Review'}
                  />
                }
              </div>
              {item?.reviewers?.length > 0 && (
                <div className="td score-table-td">
                  {item?.reviewers?.map((item, index) => (
                    <Reviewer
                      key={item?.id}
                      handleReview={handleReview}
                      isReviewed={item?.goalComment !== ''}
                      score={item?.score}
                      comment={item?.goalComment}
                      office={`${item?.role}, ${item?.approverMDA}`}
                      name={item?.approver}
                      date={item?.date}
                      details={item}
                      showButtons={false}
                      viewDetails={showDetails}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
          
       
      </div>
          <div className="submit-goal-container">
            <button
              onClick={saveAnnual}
              type="button"
              className="btn m-r-10 btn-large"
              disabled={disableSubmit}
            >
              Save Personal Targets
            </button>
          </div>
    </div>
    )}
    {isShowDownloadModal &&(
      <PersonalTargetsModal
      isShowDownloadModal = {isShowDownloadModal}
        goalsAndCommitments={goalsAndCommitments}
        closeModal={()=>{setShowDownloadModal()}}
      />
    )}
    </PDFExport>
  )
};

export default Performance;
