import React from "react";
import { useState, useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import { useSelector } from "react-redux";
import CareerPlanForm from "../Forms/ReviewForm/CareerPlanForm";
import { DeleteCareerPlan, getLongTermGoal, getShortTermGoal } from "../../network/services";
import { PDFExport } from "@progress/kendo-react-pdf";
import DownloadCareerPlans from "./DownloadCareerPlan";



const CareerPlans = ({ initiator, disable, disableSubmit, fromDashboard, annualRecord }) => {
  const [shortTerm, setShortTerm] = useState(true);
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [shortTermPlans, setShortTermPlans] = useState([]);
  const [longTermPlans, setLongTermPlans] = useState([]);
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);



  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );


  let creator;


  creator = initiator;

  console.log(creator, disableSubmit, fromDashboard)

  useEffect(() => {
    if (fromDashboard === false) {
      fetchLongTermGoals();
      fetchShortTermGoals();
    } else {
      const longTermPlans = annualRecord?.careerPlan?.filter(plan => !plan.isShorTerm);
      const shortTermPlans = annualRecord?.careerPlan?.filter(plan => plan.isShorTerm);
      setLongTermPlans(longTermPlans);
      setShortTermPlans(shortTermPlans);
    }
  }, []);

  const fetchLongTermGoals = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getLongTermGoal(creator);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setLongTermPlans(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsFetchingGoals(false);
  };

  const handleDeleteGoal = async (id) => {

    const response = await DeleteCareerPlan(id)
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchLongTermGoals()
      fetchShortTermGoals()
      feedback({
        title: "Successfully deleted",
        text: response?.data?.message,
        iconType: "success",
      });
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }

  }

  const fetchShortTermGoals = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getShortTermGoal(creator);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setShortTermPlans(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsFetchingGoals(false);
  };

  const handleClickEditGoal = (e, plan) => {
    console.log(plan)
    e.preventDefault();
    e.stopPropagation();
    setDetails(plan);
    setFormDetails(plan);
    setShowFormModal(true);
  };

  return (
    <PDFExport
      paperSize="A2"
      margin="2mm"
      fileName={'Career Plan?.pdf'}
      ref={pdfExportComponent}
      // paperSize="A3"
      // margin="2mm"
      scale={0.8}
      keepTogether=".p"
      forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    // ref={pdfExportComponent}
    >


      {!isShowDownloadModal && (
        <div className="pdf-section">
          {disable && (
            <div className="mb5 flex justify-content-center m-b-20 m-t-40">
              <button
                className="btn btn-large"
                onClick={() => {
                  setShowDownloadModal(true)
                }}
              >
                View Download Format
              </button>
            </div>

          )}
          {isShowFormModal && (
            <CareerPlanForm
              initiator={initiator}
              disable={disable}
              details={details}
              setDetails={setDetails}
              formDetails={formDetails}
              shortTerm={shortTerm}
              closeModal={() => setShowFormModal(false)}
              fetchLongTermGoals={fetchLongTermGoals}
              fetchShortTermGoals={fetchShortTermGoals}
            />
          )}
          <h4 className="m-t-10">Career Plans</h4>
          <div className="flex space-between">
            <div className="flex ">
              <button
                className={
                  !shortTerm
                    ? "btn-gray m-r-10 career_button"
                    : "btn career_button m-r-10"
                }
                onClick={() => (setShortTerm(true), setFormDetails(null))}
              >
                Short Term
              </button>
              <button
                className={
                  shortTerm
                    ? "btn-gray m-r-10 career_button"
                    : "btn career_button m-r-10"
                }
                onClick={() => (setShortTerm(false), setFormDetails(null))}
              >
                Long Term
              </button>
            </div>
            <div>
              {shortTerm ? (
                <p style={{ color: "red" }}>
                  Identify job plans for the next 2 years
                </p>
              ) : (
                <p style={{ color: "red" }}>
                  Identify job plans and options for the next 3-5 years
                </p>
              )}
            </div>
          </div>
          <div className="table-view">
            <div className="table-header">
              <div className="table-row">
                <div className="th san-sherif" style={{ width: "20%" }}>
                  <p>Title</p>
                </div>

                <div className="th san-sherif" style={{ width: "20%" }}>
                  <p>Details</p>
                </div>

                <div className="th san-sherif" style={{ width: "20%" }}>
                  <p>Dependency</p>
                </div>

                <div className="th san-sherif" style={{ width: "5%" }}>
                  <p>Expected Outcome</p>
                </div>

                {/* <div className="th san-sherif" style={{ width: "10%" }}>
              <p>Last Update</p>
            </div> */}

                <div className="th">
                  {disable &&
                    <button
                      className="add-button pointer"
                      type="button"
                      disabled={disableSubmit}
                      onClick={() => (setShowFormModal(true), setFormDetails(null))}
                    // disabled={(item?.statusId !== 1 || item?.statusDefinition !== "Unasigned" ) && (item?.statusId !== null || item?.statusDefinition !== null)}
                    >
                      <RiAddLine />
                      Add Item
                    </button>
                  }
                </div>
              </div>
            </div>
            {shortTerm && (
              <div className="table-body" >
                {shortTermPlans?.map((plan) => (
                  <div className="table-row">
                    <div className="td-no-inherit td-cell">{plan?.title}</div>
                    <div className="td-no-inherit ">
                      {plan?.details}
                    </div>
                    <div className="td" >{plan?.dependency}</div>
                    <div className="td" >
                      <div className="td-no-inherit" >{plan?.expectedOutcome}</div>
                    </div>
                    {/* <div className="td">20:11:2020 14:05:23PM</div> */}
                    <div className="td" style={{ width: "10% !important" }}>
                      {disable &&
                        <div className="actions">
                          <button
                            type="button"
                            className="btn activate"
                            disabled={disableSubmit}
                            onClick={(e) => handleClickEditGoal(e, plan)}
                          >
                            <GrEdit />
                          </button>

                          <button
                            type="button"
                            disabled={disableSubmit}
                            className="btn deactivate"
                            onClick={() => {
                              feedback(
                                {
                                  title: "Delete Goal?",
                                  text: "Click on DELETE to continue",
                                  isDialog: true,
                                  showCancelButton: true,
                                  confirmButtonText: "Delete",
                                  iconType: "warning",
                                },
                                () => handleDeleteGoal(plan?.id)
                              )
                            }
                            }
                          >
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            )}

            {!shortTerm && (
              <div className="table-body " >
                {longTermPlans?.map((plan) => (
                  <div className="table-row ">
                    <div className="td-no-inherit td-cell">{plan?.title}</div>
                    <div className="td-no-inherit">
                      {plan?.details}
                    </div>
                    <div className="td-no-inherit" >{plan?.dependency}</div>
                    <div className="td">
                      <div className="td-no-inherit" >{plan?.expectedOutcome}</div>
                    </div>
                    {/* <div className="td">20:11:2020 14:05:23PM</div> */}
                    <div className="td" style={{ width: "10% !important" }}>
                      <div className="actions">
                        {disable &&
                          <button
                            type="button"
                            disabled={disableSubmit}
                            className="btn activate"
                            onClick={(e) => handleClickEditGoal(e, plan)}
                          >
                            <GrEdit />
                          </button>
                        }
                        <button
                          type="button"
                          disabled={disableSubmit}
                          className="btn deactivate"
                          onClick={() =>
                            feedback(
                              {
                                title: "Delete Goal?",
                                text: "Click on DELETE to continue",
                                isDialog: true,
                                showCancelButton: true,
                                confirmButtonText: "Delete",
                                iconType: "warning",
                              },
                              () => handleDeleteGoal(plan?.id)
                            )
                          }
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {isShowDownloadModal && (
        <DownloadCareerPlans
          longTermPlans={longTermPlans}
          shortTermPlans={shortTermPlans}
          closeModal={() => { setShowDownloadModal(false) }}
        />
      )}
    </PDFExport>
  );
};
export default CareerPlans;
