import React from "react";
import { getEmployeeWish, putEmployeeWish } from "../../network/services";
import { feedback } from "../../utils/feedback";
import { useState, useEffect } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import DownloadStaffView from "./DownloadStaffView";



const StaffView = ({ staffViews, reviewers, wishes, setWishes, setWishesId, disableSubmit, setStaffViews, initiator, disable, setCode, fromDashboard, annualRecord }) => {

  const [payload, setPayload] = useState()
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);


  // const firstReviewer = reviewers[0][0];

  let creator;


  creator = initiator

  console.log(creator, fromDashboard)


  useEffect(() => {

    if (fromDashboard === false) {
      fetchStaffViews();
    }else{
      setWishes(annualRecord?.wishes?.wishes)
    }
  }, []);

  const fetchStaffViews = async () => {
    const response = await getEmployeeWish(creator);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      setWishes(response?.data?.data?.wishes);
      setCode(response?.data?.code)
      setWishesId(response?.data?.data?.id)
    } else {
      setWishes('')
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }


  console.log(staffViews)
  console.log(wishes)
  console.log('diasble value:', disable)
  return (
    <PDFExport
      paperSize="A2"
      margin="2mm"
      fileName={'Staff View & Wish.pdf'}
      ref={pdfExportComponent}
      // paperSize="A3"
      // margin="2mm"
      scale={0.8}
      keepTogether=".p"
      forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    // ref={pdfExportComponent}
    >
      {!isShowDownloadModal && (
        <div className="pdf-section">
          {disable && (
            <div className="mb5 flex justify-content-center m-b-20 m-t-40">
              <button
                className="btn btn-large"
                onClick={() => {
                  setShowDownloadModal(true)
                }}
              >
                View Download Format
              </button>
            </div>
          )}
          <h4>Staff View & Well Wishes</h4>
          <p>Individual Staff states own wishes for career and others.</p>
          <textarea
            disabled={!disable || disableSubmit}
            value={wishes}
            onChange={(e) => {
              setWishes(e?.target?.value);
              setStaffViews(e?.target?.value);
            }}
            className="staff_text_area"
          />
        </div>
      )}
      {isShowDownloadModal && (
        <DownloadStaffView
          wishes={wishes}
          closeModal={() => { setShowDownloadModal(false) }}
        />
      )}
    </PDFExport>
  );
};

export default StaffView;
