import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { RiAddLine } from "react-icons/ri";
import LocationDetails from "../../components/layouts/LocationDetails";
import { getApprovalRoute, postApproverRoute } from "../../network/services";
import { feedback } from "../../utils/feedback";
import IsLoading from "../../components/common/isLoading";
import ApproverRouteForm from "../../components/Forms/approverRouteForm";

import "./goalSettingApprovalRoute.scss";

const GoalSettingApprovalRoute = () => {
  const [isShowApproverRouteForm, setIsShowApproverRouteForm] = useState(false);
  const [isSettingApproverRoute, setIsSettingApproverRoute] = useState(false);
  const [isFetchingApprovalRoute, setIsFetchingApprovalRoute] = useState(false);
  const [approvalRoutes, setApprovalRoute] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - MDA Appraisal Dashboard";
  }, []);

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const handleSave = async (e, inputData) => {
    e.preventDefault();

    const payload = {
      userId: Number(Cookies.get("userId")),
      performanceId: dashboardDetails?.performanceId,
      priority: Number(inputData?.priority),
      supervisorId: Number(inputData?.supervisorId),
    };

    setIsSettingApproverRoute(true);
    try {
      const response = await postApproverRoute(payload);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        setIsShowApproverRouteForm(false);
        fetchApprovalRoute();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsSettingApproverRoute(false);
  };

  const handleLoader = () => {
    if (isSettingApproverRoute) {
      return <IsLoading message="Is Setting Approver Route.." />;
    } else if (isFetchingApprovalRoute) {
      return <IsLoading message="Is Fetching Approver Route.." />;
    }
  };

  const fetchApprovalRoute = async () => {
    setIsFetchingApprovalRoute(true);
    try {
      const response = await getApprovalRoute(dashboardDetails?.performanceId);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        let result = response?.data?.data.map((item, index) => {
          return {
            serialNumber: index + 1,
            ...item,
          };
        });
        setApprovalRoute(result);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingApprovalRoute(false);
  };

  useEffect(() => {
    fetchApprovalRoute();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Goal Setting Review & Approval Cadence</h2>
        </div>
        <LocationDetails />
      </div>

      <div className="row">
        <div className="col-12">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th san-sherif" style={{ width: "30%" }}>
                  <p>S/N</p>
                </div>
                <div className="th san-sherif" style={{ width: "30%" }}>
                  <p>Supervisor Name</p>
                </div>

                <div className="th san-sherif" style={{ width: "30%" }}>
                  <p>Priority</p>
                </div>

                <div className="th">
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => setIsShowApproverRouteForm(true)}
                  >
                    <RiAddLine />
                    Add Approver
                  </button>
                </div>
              </div>
            </div>
            <div className="table-body" style={{ overflow: "scroll" }}>
              {approvalRoutes.length < 1 ? (
                <p>No Data</p>
              ) : (
                approvalRoutes.map((item, index) => (
                  <div className="table-row" key={index}>
                    <div className="td td-cell">{item?.serialNumber}</div>
                    <div className="td td-cell">{item?.supervisorName}</div>
                    <div className="td">{item?.priority}</div>
                    <div className="td"></div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      {handleLoader()}

      {isShowApproverRouteForm && (
        <ApproverRouteForm
          closeModal={() => setIsShowApproverRouteForm(false)}
          handleSave={handleSave}
          isLoading={isSettingApproverRoute}
        />
      )}
    </div>
  );
};

export default GoalSettingApprovalRoute;
