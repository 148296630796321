import React, { useEffect, useRef, useState } from "react";
import Reviewer from "../common/Reviewer/Reviewer";
import { AiOutlineFileDone } from "react-icons/ai";
import { quarterlyAppraisalData } from "../../pages/AnnualAppraisal/quarterlyAppraisalData";
import TextareaInput from "../inputs/TextareaInput";
import { getLeadershipAssessment } from "../../network/services";
import { feedback } from "../../utils/feedback";
import { PDFExport } from "@progress/kendo-react-pdf";
import DownloadLeadershipCapability from "./DownloadLeadershipCapability";
import { useContext } from "react";
import MyContext from "../../utility/Context";

const LeadershipAssessment = ({
  sampleReviewerList,
  session,
  approverId,
  setScore,
  setComment,
  leadershipCapability,
  saveLeadership,
  setPersonalityProfile,
  showDetails,
  personalityProfile,
  disableSubmit

}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [leadershipScore, setLeadershipScore] = useState({});
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);




  useEffect(() => {
    // fetchMyassessment();
    window.scrollTo(0, 0);
    document.title = "";
  }, []);

  console.log('reviewer list', sampleReviewerList)

  // const fetchMyassessment = async () => {
  //   try {
  //     const response = await getLeadershipAssessment();
  //     if (response?.data?.code === 1 || response?.data?.code === "1") {
  //       setleadershipCapability(response?.data?.data);
  //     } else {
  //       feedback({
  //         title: "Failed",
  //         text: response?.data?.message,
  //         iconType: "error",
  //       });
  //     }
  //   } catch (error) {
  //     // feedback({
  //     //   title: "Failed",
  //     //   text: error?.response?.data?.message,
  //     //   iconType: "error",
  //     // });
  //   }
  // };

  const elementRef = useRef(null);
  const triggerRef = useRef(null);

  const openDrawer = () => {
    if (!isDrawerOpen) {
      elementRef.current.style.width = "19rem";
      triggerRef.current.style.transform = "translate(-19rem)";
      triggerRef.current.style.transition = "0.5s";
      setIsDrawerOpen((prev) => !prev);
    } else {
      elementRef.current.style.width = "0";
      triggerRef.current.style.transform = "translate(0)";
      setIsDrawerOpen((prev) => !prev);
    }
  };

  const handleReview = () => {
    return setShowFormModal(true);
  };

  const handleScoreChange = (e, item) => {
    const enteredScore = e?.target?.value;
    // Validate that the entered score is a number within the range of 0-10
    const numericScore = Number(enteredScore);

    if (isNaN(numericScore) || numericScore < 0 || numericScore > 10) {
      // If the entered score is invalid, display an error message
      feedback({
        title: "Please enter values between 0-10",
        iconType: "error",
      });
      console.error("Invalid score entered. Please enter a number between 0 and 10.");
    } else {
      // If valid, set the score
      setScore(enteredScore, item);
      setLeadershipScore((prevValues) => ({
        ...prevValues,
        [item?.ratingId]: enteredScore,
      }));
    }
  }

  console.log('leadership', leadershipCapability)



  return (
    <PDFExport
      paperSize="A2"
      margin="2mm"
      fileName={'Leadership Assesment.pdf'}
      ref={pdfExportComponent}
      // paperSize="A3"
      // margin="2mm"
      scale={0.8}
      keepTogether=".p"
      forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    >
      {!isShowDownloadModal && (
        <div className="pdf-section">
          <div className="mb5 flex justify-content-center m-b-20 m-t-40">
            <button
              className="btn btn-large"
              onClick={() => {
                setShowDownloadModal(true)
              }}

            >
              View Download Format
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">

                {leadershipCapability?.percentage && (
                  <h1 className="bold-text">You Scored {leadershipCapability?.percentage}% <br />   Grade: {leadershipCapability?.grade}</h1>
                )}

                <p className="sub-header">
                  <p>
                    Exercise here would entail, rating of individual’s leadership
                    capabilities on a scale of 1 - 10, as above.
                  </p>
                  <h4>
                    Rating descriptions are 1 = Poor; 2 = Marginal; 4-6 =
                    Satisfactory; 7-8 = Exceeding -and 9-10 = Distinction.
                  </h4>
                </p>
                <div className="table-view m-t-10 score-table">
                  <div className="table-header">
                    <div className="table-row score-table-header-row">
                      <div  style={{width: '200px'}} className="th san-sherif score-table-th">
                        <p>Assessment Criteria</p>
                      </div>

                      <div
                        className="th san-sherif score-table-th"
                        style={{ width: "10%" }}
                      >
                        <p>Score</p>
                      </div>
                      <div
                        className="th san-sherif score-table-th"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ alignText: "center" }}>
                          Achievement/Narration to the score
                        </p>
                        <div ref={triggerRef}>
                          <AiOutlineFileDone
                            size={32}
                            style={{alignText: "center" }}
                          />
                        </div>


                      </div>
                      <div className="header san-sherif th score-table-th">
                        Reviewers
                      </div>

                      <div
                        className="header san-sherif th score-table-th"
                      >
                        <p>Reviewer's Score</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="table-body score-table-body"
                    style={{ overflow: "scroll" }}
                  >
                    {Array.isArray(leadershipCapability?.userPerformanceLeadership) && leadershipCapability?.userPerformanceLeadership?.map((item, index) => (
                      <div className="table-row score-table-row m-t-10" key={index}>
                        <div className="td  score-table-td">
                          {item?.assessmentCriteria}
                        </div>
                        <div className="td score-table-td">
                          <input
                            className="w-100 h-100"
                            style={{ border: "1px solid #000" }}
                            type="number"
                            onChange={(e) => handleScoreChange(e, item)}
                            value={leadershipScore[item?.ratingId] || item?.score || ""}
                            disabled={(item?.statusId !== 1 || item?.statusDefinition !== "Unasigned") && (item?.statusId !== null || item?.statusDefinition !== null)}
                          />
                        </div>
                        <div className="td score-table-td">
                          <textarea
                            rows={5}
                            className="w-100 h-100"
                            onChange={(e) => setComment(e?.target?.value, item)}
                            defaultValue={
                              item?.achievements ? item?.achievements : ""
                            }
                            readOnly={(item?.statusId !== 1 || item?.statusDefinition !== "Unasigned") && (item?.statusId !== null || item?.statusDefinition !== null)}
                          />
                        </div>
                        <div className="td score-table-td">
                          {item?.reviewers?.map((reviewer) => (
                            <Reviewer
                              key={reviewer?.id}
                              handleReview={handleReview}
                              isReviewed={reviewer?.comment !== ''}
                              score={item?.reviewerScore}
                              comment={reviewer?.comment}
                              office={`${reviewer?.role}, ${reviewer?.approverMDA}`}
                              name={reviewer?.approver}
                              date={reviewer?.date}
                              details={item}
                              showButtons={false}
                              viewDetails={showDetails}
                            />


                          ))}
                        </div>

                        {item?.reviewerScore ? (
                          <div className="td score-table-td">
                            <input
                              className="w-100 h-100"
                              style={{ border: "1px solid #000" }}
                              type="number"
                              value={item?.reviewerScore}
                              disabled
                            />

                          </div>
                        ):
                        <input
                          className="w-100 h-100"
                          style={{ border: "1px solid #000", marginTop: '50px' }}
                          type="text"
                          onChange={(e) => handleScoreChange(e, item)}
                          value={item?.reviewerScore ? item.reviewerScore : 'No revierwer score'}
                          disabled
                        />
                        }

                      </div>
                    ))}
                  </div>

                </div>

                <div>
                  <textarea
                    placeholder="Personality Profile (Brief description of key strengths and weaknesses):"
                    onChange={(e) => { setPersonalityProfile(e.target.value) }}
                    defaultValue={
                      Array.isArray(leadershipCapability?.userPerformanceLeadership) &&
                        leadershipCapability?.userPerformanceLeadership[0]?.personalityProfile ?
                        leadershipCapability?.userPerformanceLeadership[0]?.personalityProfile : ""
                    }
                    readOnly={Array.isArray(leadershipCapability?.userPerformanceLeadership) && (leadershipCapability?.userPerformanceLeadership[0]?.statusId !== 1 || leadershipCapability?.userPerformanceLeadership[0]?.statusDefinition !== "Unasigned") &&
                      (leadershipCapability?.userPerformanceLeadership[0]?.statusId !== null || leadershipCapability?.userPerformanceLeadership[0]?.statusDefinition !== null)}
                    className="assess_text_area"
                  />

                </div>

                <h4>
                  Overall Leadership Capability Rating on a scale of 1 - 10 (From a
                  simple average of all scores in 2.2).
                </h4>

                <div>Overall Performance Ratings</div>
                <p>
                  Rating Scheme of Poor, Marginal, Satisfactory, Exceeding and
                  Distinction to be applied.
                </p>
                <p>
                  Derive Overall assessment by combining scores from 2.1+2.2 using
                  60/40 percentages respectively for senior management positions,
                  and 70/30 precent respectively for junior level Staff.
                </p>
                {/* <hr />
            <div className="flex m-t-20 m-30 center-text">
              <div className="flex  flex-direction-column m-r-30 center-text">
                <h5>Job Performance</h5>
                <h1>75%</h1>
              </div>
              <hr />
              <div className="flex  flex-direction-column m-r-30 center-text">
                <h5>Competency Assessment</h5>
                <h1>93%</h1>
              </div>
              <hr />
              <div className="flex  flex-direction-column m-r-30 center-text">
                <h5>Leadership Capability</h5>
                <h1>88%</h1>
              </div>
              <hr />
              <div className="flex  flex-direction-column m-r-30 center-text">
                <h5>Overall Appraisal</h5>
                <h1>88%</h1>
              </div>
            </div> */}
              </div>
              <div className="submit-goal-container ">
                <button
                  onClick={saveLeadership}
                  type="button"
                  className="btn m-r-10 btn-large "
                  disabled={disableSubmit}
                >
                  Save Leadership Capability
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isShowDownloadModal && (
        <DownloadLeadershipCapability
          isShowDownloadModal={isShowDownloadModal}
          leadershipCapability={leadershipCapability}
          closeModal={() => { setShowDownloadModal(false) }}
        />
      )}
    </PDFExport>
  );
};

export default LeadershipAssessment;
