import React, { useEffect, useRef, useState } from "react";
import Reviewer from "../common/Reviewer/Reviewer";
import { AiOutlineFileDone } from "react-icons/ai";
import { quarterlyAppraisalData } from "../../pages/AnnualAppraisal/quarterlyAppraisalData";
import TextareaInput from "../inputs/TextareaInput";
import { getDownloadLeadershipCapability } from "../../network/services";
import { feedback } from "../../utils/feedback";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
import Cookies from "js-cookie";


const DownloadLeadershipCapability = ({
  sampleReviewerList,
  closeModal,
  approverId,
  setScore,
  setComment,
  setLeadershipCapability,
  leadershipCapability,
  setPersonalityProfile,
  showDetails,
  personalityProfile,
  isShowDownloadModal

}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [leadershipScore, setLeadershipScore] = useState({});
  const pdfExportComponent = React.useRef(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "";
  }, []);

  console.log('reviewer list', sampleReviewerList)


  const elementRef = useRef(null);
  const triggerRef = useRef(null);

  const openDrawer = () => {
    if (!isDrawerOpen) {
      elementRef.current.style.width = "19rem";
      triggerRef.current.style.transform = "translate(-19rem)";
      triggerRef.current.style.transition = "0.5s";
      setIsDrawerOpen((prev) => !prev);
    } else {
      elementRef.current.style.width = "0";
      triggerRef.current.style.transform = "translate(0)";
      setIsDrawerOpen((prev) => !prev);
    }
  };

  const handleReview = () => {
    return setShowFormModal(true);
  };



  console.log('leadership', leadershipCapability, isShowDownloadModal)



  return (
    <div className="pdf-section">
      <div className="flex space-between">
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={closeModal}
          >
            Back to record
          </button>
        </div>
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={() => {
              if (pdfExportComponent?.current) {
                pdfExportComponent?.current.save();
              }
            }}
          >
            Download PDF
          </button>
        </div>
      </div>


      <PDFExport
        paperSize="A2"
        margin="2mm"
        fileName={'Leadership Assesment.pdf'}
        ref={pdfExportComponent}
        // paperSize="A3"
        // margin="2mm"
        scale={1}
        keepTogether=".p"
        forcePageBreak=".page-break"
      // fileName={`${pdfName}.pdf`}
      >
        <div className="center-text">
          <img src={EdsgLogo} alt="logo" className="brand" width="150" />
          <h3 className="m-t-10 m-b-20">
            Edo State 2023 Annual Appraisal Leadership Assesment
            {/* <span id="currentYear"></span> */}
          </h3>
          <h3 className="m-t-10 m-b-20 bold-text">
            {Cookies.get('fullName').toUpperCase()}
          </h3>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">

              {leadershipCapability?.percentage && (
                <h1 className="bold-text">You Scored <span className="m-l-10">{leadershipCapability?.percentage}%</span> <br />   Grade: {leadershipCapability?.grade}</h1>
              )}

              <p className="sub-header">
                <p>
                  Exercise here would entail, rating of individual’s leadership
                  capabilities on a scale of 1 - 10, as above.
                </p>
                <h4>
                  Rating descriptions are 1 = Poor; 2 = Marginal; 4-6 =
                  Satisfactory; 7-8 = Exceeding -and 9-10 = Distinction.
                </h4>
              </p>
              <div className="table-view m-t-10 score-table">
                <div className="table-header">
                  <div className="table-row score-table-header-row">
                    <div style={{ width: '200px' }} className="th san-sherif score-table-th">
                      <p>Assessment Criteria</p>
                    </div>

                    <div
                      className="th san-sherif score-table-th"
                      style={{ width: "10%" }}
                    >
                      <p>Score</p>
                    </div>
                    <div
                      className="th san-sherif score-table-th"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p style={{ alignText: "center" }}>
                        Achievement/Narration to the score
                      </p>
                      <div ref={triggerRef}>
                        <AiOutlineFileDone
                          size={32}
                          style={{ alignText: "center" }}
                        />
                      </div>
                    </div>
                    <div className="header san-sherif th score-table-th">
                      Reviewers
                    </div>
                    <div
                      className="header san-sherif  score-table-th"
                    >
                      Reviewer's Score
                    </div>

                  </div>
                </div>
                <div
                  className="table-body score-table-body"
                  style={{ overflow: "scroll" }}
                >
                  {Array.isArray(leadershipCapability?.userPerformanceLeadership) && leadershipCapability?.userPerformanceLeadership?.map((item, index) => (
                    <div className="table-row score-table-row m-t-10" key={index}>
                      <div className="td  score-table-td">
                        {item?.assessmentCriteria}
                      </div>
                      <div className="td score-table-td">
                        <input
                          className="w-100 h-100"
                          style={{ border: "1px solid #000" }}
                          type="number"
                          value={item?.score}
                          disabled
                        />
                      </div>
                      <div className="td score-table-td">
                        <textarea
                          rows={5}
                          className="w-100 h-100"
                          defaultValue={
                            item?.achievements
                          }
                          readOnly
                        />
                      </div>
                      <div className="td score-table-td">
                        {item?.reviewers?.map((reviewer) => (
                          <Reviewer
                            key={reviewer?.id}
                            handleReview={handleReview}
                            isReviewed={reviewer?.score !== null}
                            score={item?.reviewerScore}
                            comment={reviewer?.comment}
                            isShowDownloadModal={isShowDownloadModal}
                            office={`${reviewer?.role}, ${reviewer?.approverMDA}`}
                            name={reviewer?.approver}
                            date={reviewer?.date}
                            details={item}
                            showButtons={false}
                            viewDetails={showDetails}
                          />


                        ))}
                      </div>

                      {item?.reviewerScore && (
                        <div className="td score-table-td">
                          <textarea
                            className="w-100 h-100"
                            style={{ border: "1px solid #000", textAlign: "center" }}
                            type="number"
                            value={item?.reviewerScore}
                            disabled
                          />

                        </div>
                      )}

                    </div>
                  ))}
                </div>
              </div>

              <div>
                <textarea
                  placeholder="Personality Profile (Brief description of key strengths and weaknesses):"
                  defaultValue={
                    Array.isArray(leadershipCapability?.userPerformanceLeadership) &&
                      leadershipCapability?.userPerformanceLeadership[0]?.personalityProfile ?
                      leadershipCapability?.userPerformanceLeadership[0]?.personalityProfile : ""
                  }
                  readOnly={Array.isArray(leadershipCapability?.userPerformanceLeadership) && (leadershipCapability?.userPerformanceLeadership[0]?.statusId !== 1 || leadershipCapability?.userPerformanceLeadership[0]?.statusDefinition !== "Unasigned") &&
                    (leadershipCapability?.userPerformanceLeadership[0]?.statusId !== null || leadershipCapability?.userPerformanceLeadership[0]?.statusDefinition !== null)}
                  className="assess_text_area"
                />

              </div>

              <h4>
                Overall Leadership Capability Rating on a scale of 1 - 10 (From a
                simple average of all scores in 2.2).
              </h4>

              <div>Overall Performance Ratings</div>
              <p>
                Rating Scheme of Poor, Marginal, Satisfactory, Exceeding and
                Distinction to be applied.
              </p>
              <p>
                Derive Overall assessment by combining scores from 2.1+2.2 using
                60/40 percentages respectively for senior management positions,
                and 70/30 precent respectively for junior level Staff.
              </p>

            </div>
          </div>
        </div>
      </PDFExport>

    </div>
  );
};

export default DownloadLeadershipCapability;
