import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import Spinner from "../../common/spinner/spinner";
import TextareaInput from "../../inputs/TextareaInput";
import { putApproveMidYear, putReviewMidYear } from "../../../network/services"
import TextInput from "../../inputs/TextInput";
import { feedback } from "../../../utils/feedback";

const ReviewForm = ({ closeModal, handleSave, loading, formDetails, setLoading, details, refreshList }) => {
  const [inputData, setInputData] = useState();

  console.log(details?.id)

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const reviewMidYear = async (e) => {
    e.preventDefault()
    if(inputData?.score > 100 || inputData?.score < 0){
      feedback({
        title: "Warning",
        text: "Please enter a valid score between 0 and 100",
        iconType: "warning",
      });
      return;
    }
    const response = await putReviewMidYear(
      {
        ...inputData,
        score: Number(inputData?.score),
        id: details?.id
      }
    );
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      refreshList();
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      
      closeModal();
    }
    else{
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
    console.log(response);
  }

  const approveMidYear = async (e) => {
    e.preventDefault();
    const response = await putApproveMidYear(
      {
        ...inputData,
        score: Number(inputData?.score),
        id: details?.id
      }
    );
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      refreshList();
      closeModal();
    }
    else{
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
    console.log(response);
  }

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-500">
        <form
          id="modal-form"
          onSubmit={(e) => {
            // handleSave(e, inputData);
            approveMidYear(e);
            clearForm();
          }}
        >
          <div className="content">
            {/* <TextInput
              className="w-100 m-b-10"
              name="score"
              label="Proposed Score:"
              type="number"
              minDate={0}
              maxDate={100}
              onChange={handleChange}
              value={formDetails && formDetails?.score}
              disabled={formDetails}
            /> */}

            <TextareaInput
              className="w-100 m-b-10"
              name="comment"
              label="Comments on Commitments:"
              type="text"
              rows="5"
              onChange={handleChange}
              value={formDetails && formDetails?.comment}
              disabled={formDetails}
            />

            {!formDetails && <div className="flex space-between w-100">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-large flex_basis_48 w-100"
              >
                {loading && <Spinner />} Approve
              </button>
              <button
                disabled={loading}
                className="btn btn-large flex_basis_48 w-100"
                onClick={(e)=>reviewMidYear(e)}
              >
                {loading && <Spinner />} Send Back For Review
              </button>
            </div>}

          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewForm;
