import React, { useEffect, useRef, useState } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineFileDone } from "react-icons/ai";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import { quarterlyAppraisalData } from "./quarterlyAppraisalData";
import Declaration from "../../components/common/Declaration/declaration";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";

import "./quarterlyReview.scss";
import { sampleReviewerList } from "./sampleReviewerList";

const QuarterlyReview = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "";
  }, []);

  const elementRef = useRef(null);
  const triggerRef = useRef(null);

  const openDrawer = () => {
    if (!isDrawerOpen) {
      elementRef.current.style.width = "20rem";
      triggerRef.current.style.transform = "translate(-20rem)";
      triggerRef.current.style.transition = "0.5s";
      setIsDrawerOpen((prev) => !prev);
    } else {
      elementRef.current.style.width = "0";
      triggerRef.current.style.transform = "translate(0)";
      setIsDrawerOpen((prev) => !prev);
    }
  };

  const handleReview = () => {
    return setShowFormModal(true);
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Appraisal</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">
        Plan your activities for the year 2021
      </p>

      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Departmental Commitment"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Targets/Goals/Commitment"
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>My Personal Targets & Performance (Quantitative)</h4>
            <p className="sub-header">
              The Head of the Department in consultation with my
              Division/Section/Unit set out to achieve the following
            </p>
            <div className="table-view m-t-10 score-table">
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th">
                    <p>Commitments</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Assigned Weight</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Your Score (.../100)</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Employee's Comments</p>
                    <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="table-body score-table-body"
                style={{ overflow: "scroll" }}
              >
                {quarterlyAppraisalData.map((item, index) => (
                  <div className="table-row score-table-row m-t-10" key={index}>
                    <div className="td td-cell score-table-td">
                      {item?.commitment}
                    </div>
                    <div className="td score-table-td">
                      {item?.assignedWeight}
                    </div>
                    <div className="td score-table-td">{item?.review}</div>
                    <div className="td score-table-td">{item?.priority}</div>
                  </div>
                ))}
              </div>
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th">
                    <p>100%</p>
                  </div>
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th"></div>
                </div>
              </div>
              <div className="drawer" ref={elementRef}>
                <div className="header san-sherif th score-table-th">
                  Reviewers
                </div>

                {sampleReviewerList.map((item, index) => (
                  <Reviewer
                    key={index}
                    handleReview={handleReview}
                    isReviewed={item?.isReviewed}
                    score={item?.score}
                    office={item?.office}
                    name={item?.name}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Declaration />

      <div className="submit-goal-container">
        <button type="button" className="btn btn-large">
          Submit Review
        </button>
      </div>

      {isShowFormModal && (
        <ReviewForm closeModal={() => setShowFormModal(false)} />
      )}
    </div>
  );
};

export default QuarterlyReview;
