import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";

import LocationDetails from "../../components/layouts/LocationDetails";
import { getApprovalStaffList, postApprovePerformance } from "../../network/services";
import { feedback } from "../../utils/feedback";
// import { goalSettingReviewData } from "./goalSettingReviewData";

import "./goalSettingReview.scss";
import NoData from "../../components/common/NoData/NoData";

const GoalSettingReview = () => {
  const [approvalStaffList, setApprovalStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewData, setReviewData] = useState([]);

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  console.log("dashboardDetails: ", dashboardDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - MDA Appraisal Dashboard";
  }, []);

  const approve = async (data) => {
    console.log(data)
    const submitData = {
      comment: "ApprovalCompleted",
      performanceID: data.performanceId,
      approvalStatus: 3
    }
    const res = await postApprovePerformance(submitData);
    console.log(res)
    if (res.data.code === 1) {
      feedback({
        title: "Success",
        text: res?.data?.message,
        iconType: "success",
      });
      fetchGoalReviewList();
    }
    else {
      feedback({
        title: "Error",
        text: res?.data?.message,
        iconType: "error",
      });
      fetchGoalReviewList();
    }
  }

  const decline = async (data) => {
    const submitData = {
      comment: "Review",
      performanceID: data.performanceId,
      approvalStatus: 6
    }
    const res = await postApprovePerformance(submitData);
    console.log(res);
    if (res.data.code === 1) {
      feedback({
        title: "Success",
        text: res?.data?.message,
        iconType: "success",
      });
      fetchGoalReviewList();
    }
    else {
      feedback({
        title: "Error",
        text: res?.data?.message,
        iconType: "error",
      });
      fetchGoalReviewList()
    }
  }

  const fetchGoalReviewList = async () => {
    setIsLoading(true);
    try {
      let response = await getApprovalStaffList(dashboardDetails?.periodId);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setApprovalStaffList(response?.data?.data);

      } else {
        setApprovalStaffList([]);
      }
    } catch (e) {
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
      setApprovalStaffList([]);

    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchGoalReviewList();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Performance Review</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">
        Appraisee Performance Planning & Developmental Needs
      </p>



      <div className="row">
        <div className="col-12">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th san-sherif">
                  <p>Staff Name</p>
                </div>

                <div className="th san-sherif">
                  <p>Last Update</p>
                </div>

                <div className="th san-sherif">
                  <p>Last Updated By</p>
                </div>

                <div className="th san-sherif ">
                  <p style={{ textAlign: "center" }}>Actions</p>
                </div>
              </div>
            </div>
            <div className="table-body" style={{ overflow: "scroll" }}>
              {approvalStaffList.length < 1 ? (
                <NoData />
              ) : (
                approvalStaffList?.map((item, index) => (
                  <div className="table-row" key={index}>
                    <div className="td">
                      <Link
                        to={`/admin-portal/goal-setting-review/user?id=${item?.performanceId}`}
                      >
                        {item?.appraisee}
                      </Link>
                    </div>
                    <div className="td">{item?.modifiedDate}</div>
                    <div className="td">{item?.modifiedBy}</div>
                    <div className="td">
                      <div className="actions">
                        <button onClick={() => approve(item)} type="button" className="btn activate">
                          <AiOutlineCheck />
                        </button>
                        <button onClick={() => decline(item)} type="button" className="btn deactivate" style={{ backgroundColor: "pink" }}>
                          <GiCancel />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalSettingReview;
