import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import Spinner from "../../common/spinner/spinner";
import TextareaInput from "../../inputs/TextareaInput";
import {
  putApproveLeadershipCapability,
  putReviewLeadershipCapability,
} from "../../../network/services";
import TextInput from "../../inputs/TextInput";
import { feedback } from "../../../utils/feedback";

const ReviewFormAnnualLeadershipCapability = ({
  closeModal,
  handleSave,
  loading,
  formDetails,
  setLoading,
  details,
  refreshList,
  scores,
  setLeadershipScore
}) => {
  const [inputData, setInputData] = useState();

  console.log(details)
  

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e?.target?.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const reviewLeadershipCapability = async (e) => {
    e.preventDefault();
    console.log('SCORES', scores)
    const response = await putReviewLeadershipCapability({
      ...inputData,
      comment: inputData?.comment,
      userId: details?.userId,
      scores: scores,
    });
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      refreshList();
      setLeadershipScore({})
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });

      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
    console.log(response);
  };

  const approveLeadershipCapability = async (e) => {
    e.preventDefault();
    const response = await putApproveLeadershipCapability({
      ...inputData,
      comment: inputData?.comment,
      scores: scores,
      userId: details?.userId,
    });
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      refreshList();
      setLeadershipScore({})
      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
    console.log(response);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-500">
        <form
          id="modal-form"
          onSubmit={(e) => {
            // handleSave(e, inputData);
            approveLeadershipCapability(e);
            clearForm();
          }}
        >
          <div className="content">
           

            <TextareaInput
              className="w-100 m-b-10"
              name="comment"
              label="Comments on Commitments:"
              type="text"
              onChange={handleChange}
              value={formDetails && formDetails?.comment}
              disabled={formDetails}
            />

            {!formDetails && (
              <div className="flex space-between w-100">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-large flex_basis_48 w-100"
                >
                  {loading && <Spinner />} Approve
                </button>
                <button
                  disabled={loading}
                  className="btn btn-large flex_basis_48 w-100"
                  onClick={(e) => reviewLeadershipCapability(e)}
                >
                  {loading && <Spinner />} Send Back For Review
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewFormAnnualLeadershipCapability;
