import React from "react";
import { useState } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import DevelopmentActionPlanForm from "../Forms/ReviewForm/DevelopmentActionPlanForm";
import { DeleteDevelopmentActionPlan } from "../../network/services";

const CompetenceActionTable = ({ developmentGap, initiator, disable,fetchDevelopmentActionPlan , disableSubmit, developmentActionPlan }) => {
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [isShowFormModal, setShowFormModal] = useState(false);

  //   useEffect(() => {}, []);

  const userId = localStorage.getItem('userId')
  console.log(userId)

  const handleClickEditGoal = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();
    setDetails(plan);
    setFormDetails(plan);
    setShowFormModal(true);
  };

  const handleDeleteGoal = async (id) =>{
    const response = await DeleteDevelopmentActionPlan(id)
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentActionPlan()
      feedback({
        title: "Successfully deleted",
        text: response?.data?.message,
        iconType: "success",
      });
    }else{
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }

  console.log(developmentActionPlan)
  return (
    <div className="pdf-section">
      {isShowFormModal && (
        <DevelopmentActionPlanForm
          initiator={initiator}
          disable={disable}
          details={details}
          setDetails = {setDetails}
          formDetails={formDetails}
          fetchDevelopmentActionPlan={fetchDevelopmentActionPlan}
          developmentGap={developmentGap}
          closeModal={() => setShowFormModal(false)}
        />
      )}
      <div className="info-box">
        <p>
          (Identify specific competence development need and action plan and
          time).
        </p>
      </div>
      <div className="table-view">
        <div className="table-header">
          <div className="table-row">
            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Target Actions</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Development Goals</p>
            </div>

            <div className="th san-sherif" style={{ width: "5%" }}>
              <p>Employees Achievements</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Manager's Reviews</p>
            </div>

            <div className="th">
              { disable && 
                <button
                  className="add-button pointer"
                  disabled={disableSubmit}
                  type="button"
                  onClick={() => (setShowFormModal(true), setFormDetails(null))}
                >
                  <RiAddLine />
                  Add Item
                </button>
              }
            </div>
          </div>
        </div>
        <div className="table-body" style={{ overflow: "scroll" }}>
          {developmentActionPlan?.map((plan) => (
            <div className="table-row">
              <div className="td td-cell">{plan?.targetAction}</div>
              <div className="td-no-inherit">
                <div>{plan?.devGoals}</div>
              </div>
              <div className="td-no-inherit">{plan?.achievements}</div>
              <div className="td-no-inherit">
                <div className="td-no-inherit">{plan?.managersReviews}</div>
              </div>
              {/* <div className="td">20:11:2020 14:05:23PM</div> */}
              <div className="td">
                  {disable &&
                <div className="actions">
                    <button
                    type="button"
                    disabled={disableSubmit}
                    className="btn activate"
                    onClick={(e) => handleClickEditGoal(e, plan)}
                  >
                    <GrEdit />
                    </button>
                  
                  <button
                      type="button"
                      disabled={disableSubmit}
                      className="btn deactivate"
                      onClick={() =>
                      feedback(
                          {
                              title: "Delete Goal?",
                              text: "Click on DELETE to continue",
                              isDialog: true,
                              showCancelButton: true,
                              confirmButtonText: "Delete",
                              iconType: "warning",
                          },
                          () => handleDeleteGoal(plan?.id)
                      )
                  }
                >
                  <RiDeleteBin6Line />
                </button>
                </div>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompetenceActionTable;
