import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useEffect } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import Select from "react-select";
import "./collapsible.scss";

const BASE_API_URL = `https://edogoverp.com/services/api`;

const Collapsible = ({
  children,
  icon = <HiOutlineUserCircle size={28} className="collapsible-icon" />,
  title,
  setApproverId,
  approvers,
  setApprovers,
  employeeName,
  setemployeeName,
  recipientMda,
  setrecipientMda,
  component
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const [mdaList, setMdaList] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);

  React.useEffect(() => {
    getApproversMDAs();
  }, [])

  const user = localStorage.getItem("userId");
  

  const getEmployeesFromMda = (id) => {
    axios
      .get(`${BASE_API_URL}/Employees/employees_by_mda?mdaid=${id}`)
      .then((response) => {
        console.log("--employeesByMDAs--");
        console.log(response?.data);
  
        const roleList = response?.data.map((item) => ({
          name: `${item.name}`,
          value: item.id,
        }));
  
        const filteredEmployees = roleList.filter((item) => item.value !== Number(user) );
        setEmployees(filteredEmployees);
      });
  };
  

  const getApproversMDAs = () => {
    axios.get(`${BASE_API_URL}/Employees/mdas`).then((response) => {
      console.log("--mdas--");
      console.log(response?.data);
      // this.setState({
      const roleList = response?.data.map((item) => ({
        name: `${item.name}`,
        value: item.id,
      }));
      setMdaList(roleList);

    });
  };

  console.log('user', user)
  console.log('employees', employees)
  const handleApproverId = (e) => {
    setApprovers(e);
    
    console.log(e);
  };

  const renderTrigger = () => {
    if (isCollapsibleOpen === false) {
      return (
        <>
          <div
            className="collapse-expand-trigger expand"
            onClick={() => setIsCollapsibleOpen(true)}
          >
            Expand Section
          </div>
        </>
      );
    }  else {
      return (
        <div
          className="collapse-expand-trigger collapse"
          onClick={() => setIsCollapsibleOpen(false)}
        >
          Collapse Section
        </div>
      );
    }
  };

  return (
    <div className="collapsible-container">
      <details >
        <summary>
          <span className="icon-title-container">
            {icon}
            <h4>{title}</h4>
          </span>
          {renderTrigger()}
        </summary>
        {title !== "Approvers Setup" && <div className="collapsible-content">
          {component && component ||
            <>
              <p>
                {Cookies.get("mdaMandate")}
              </p>
            </>
          }
        </div>}
        {title === "Approvers Setup" && <div className="collapsible-content">
          {component && component ||
            <>
              <div >

                <div className='m-b-10 w-100 form-group '>
                  <label>Approvers MDA</label>
                  <Select
                    label='Select Approvers MDA'
                    color='rgba(120,120,120,0.8)'
                    className='m-b-10 w-w00'
                    onChange={(value) => {
                      console.log(value);
                      // this.setState({ employeeName: e.target.value });
                      setrecipientMda(value);
                      getEmployeesFromMda(value.value);
                      // this.setState({recipientMda:value});
                    }}
                    value={recipientMda}
                    options={mdaList.map((m) => ({
                      label: m.name,
                      value: m.value,
                    }))}
                  />
                  {/* <div
          style={{
            padding: '10px 10px 10px 0',
            width: '100%',
            height: '39px',
            borderBottom: '1px solid #c6c6c6',
          }}>
          {authoriser.name}
        </div> */}
                </div>
                <div className='m-b-10 w-100 form-group '>
                  <label>Approvers</label>
                  <Select
                    placeholder='Select Approvers'
                    color='rgba(120,120,120,0.8)'
                    className='m-b-10 w-w00'
                    onChange={(value) => {
                      console.log(value.value);
                      setemployeeName(value);
                      handleApproverId(value);
                      // handleApproverId(value)
                      // setEmployeeList(value.value);
                      // this.setState({ employeeList: value });

                      // this.getEmployeesFromMda(value.value)
                      // this.setState({recipientMda:value});
                    }}
                    // onChange={(e)=>{handleApproverId(e)}}
                    // onInputChange={(e) => {
                    //   handleApprovers(e);
                    // }}
                    isMulti
                    value={employeeName}
                    options={employees?.map((m) => ({
                      label: m.name,
                      value: m.value,
                    }))}
                  />
                  {/* <div
          style={{
            padding: '10px 10px 10px 0',
            width: '100%',
            height: '39px',
            borderBottom: '1px solid #c6c6c6',
          }}>
          {permSec.name}
        </div> */}
                </div>
              </div>
            </>
          }
        </div>}
      </details>
    </div>
  );
};

export default Collapsible;
