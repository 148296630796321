import React from "react";
import { useState, useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import CompetenceActionTable from "./CompetenceActionTable";
import DevelopmentGapsTable from "./DevelopmentGapsTable";
import {
  getDevelopmentGap,
  getDevelopmentActionPlan,
} from "../../network/services";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
import Cookies from "js-cookie";



const DownloadTrainingAndDev = ({ reviewers, initiator, closeModal, developmentGap, developmentActionPlan, disable }) => {
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);






  console.log('dev gap', developmentGap)
  console.log('dev actPlan', developmentActionPlan)

  console.log('dev  plan:', developmentGap)
  return (
    <div>
      <div className="flex space-between">
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={closeModal}
          >
            Back to record
          </button>
        </div>
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={() => {
              if (pdfExportComponent?.current) {
                pdfExportComponent?.current.save();
              }
            }}
          >
            Download PDF
          </button>
        </div>
      </div>
      <PDFExport
        paperSize="A2"
        margin="2mm"
        fileName={'Training & Developement.pdf'}
        ref={pdfExportComponent}
        // paperSize="A3"
        // margin="2mm"
        scale={0.8}
        keepTogether=".p"
        forcePageBreak=".page-break"
      // fileName={`${pdfName}.pdf`}
      // ref={pdfExportComponent}
      >
        <div>
          <div className="center-text m-t-40">
            <img src={EdsgLogo} alt="logo" className="brand" width="150" />
            <h3 className="m-t-10 m-b-20">
              Edo State 2023 Annual Appraisal Training And Development Actions
              {/* <span id="currentYear"></span> */}
            </h3>
            <h3 className="m-t-10 m-b-20 bold-text">
              {Cookies.get('fullName').toUpperCase()}
            </h3>
          </div>
          <h4 className="m-t-10">Training & Development</h4>
          <div>
            <div className="flex ">
              <button
                className={
                  tab === 2
                    ? "btn-gray m-r-10 career_button "
                    : "btn career_button m-r-10 "
                }
                onClick={() => setTab(1)}
              >
                Identified Development Gaps
              </button>
              <button
                className={
                  tab === 1
                    ? "btn-gray m-r-10 career_button1"
                    : "btn career_button1 m-r-10"
                }
                onClick={() => setTab(2)}
              >
                Competence Development Action Plan and Targets
              </button>
            </div>
            {tab === 2 ? (
              <CompetenceActionTable
                disable={disable}
                initiator={initiator}
                developmentActionPlan={developmentActionPlan}
                developmentGap={developmentGap}
              />
            ) : (
              <DevelopmentGapsTable initiator={initiator} disable={disable} developmentGap={developmentGap} />
            )}
          </div>
        </div>
      </PDFExport>
    </div>

  );
};
export default DownloadTrainingAndDev;
