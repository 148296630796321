import React, { useEffect, useState, useContext } from 'react';
import Declaration from '../components/common/Declaration/declaration';
import LocationDetails from '../components/layouts/LocationDetails';
import IsLoading from '../components/common/isLoading';
import { getAllPeriod, getAppraisalRecordByYear } from "../network/services";
import MyContext from '../utility/Context';
import Select from "react-select";


const Dashboard = ({ history }) => {
  const [title, setTitle] = useState('');
  const [isFetchingRecord, setIsFetchingRecord] = useState(false);
  const { setAnnualRecord, setFromDashboard } = useContext(MyContext);
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
    setTitle('Dashboard');
    fetcthPeriods()
  }, []);

  const fetchAnnualRecord = async (periodId) => {
    setIsFetchingRecord(true);
    try {
      const response = await getAppraisalRecordByYear(periodId);
      if (response?.data?.code === 1) {
        setAnnualRecord(response?.data?.data);
        setFromDashboard(true);
      }
    } catch (error) {
      console.error("Error fetching annual record:", error);
    } finally {
      setIsFetchingRecord(false);
    }
  }

  const fetcthPeriods = async () => {
    setIsFetchingRecord(true);
    try {
      const response = await getAllPeriod();
      if (response?.data?.code === 1) {
        setPeriods(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching annual record:", error);
    } finally {
      setIsFetchingRecord(false);
    }
  }

  const handleFetch = async (periodId) => {
    await fetchAnnualRecord(periodId);
    await history.push('/admin-portal/annual-appraisal');
  }

  const handleLoader = () => {
    if (isFetchingRecord) {
      return <IsLoading message="Is Setting Goals.." />;
    }
  };

  const currentYear = new Date().getFullYear();
  const yearsToShow = Array.from({ length: currentYear - 2023 + 1 }, (_, index) => 2023 + index);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Your Past Year(s) Annual Appraisals</h2>
        </div>
        <LocationDetails />
      </div>
      <div className='col-6 m-b-10 flex_basis_30 form-group '>
        <label>Appraisal Year</label>
        <Select
          label='Select Initiators'
          color='rgba(120,120,120,0.8)'
          className='m-b-10 w-w00'
          onChange={(selectedOption) => {
            handleFetch(selectedOption.value); 
          }}
          options={periods.map((year, index) => ({
            label: year.year.toString(),
            value: year.id
          }))}
        />
      </div>

      <Declaration />
      {handleLoader()}
    </div>
  );
};

export default Dashboard;
