import React from "react";

import { RiAddLine } from "react-icons/ri";

import userIcon from "../../../assets/images/user-avater.png";

import "./Reviewer.scss";

const Reviewer = ({
  handleReview,
  isReviewed,
  office,
  name,
  score,
  date,
  details,
  showButtons,
  comment,
  mda,
  viewDetails,
  isShowDownloadModal,
}) => {
  console.log(isReviewed);
  return (
    <div>
      {!isReviewed && (
        <div className="reviewer">
          <img src={userIcon} />
          <div className="name-and-title">
            {name && <h5 className="user-name">{name}</h5>}
            {office && <p className="title">{office}</p>}
            {mda && <p className="title">{mda}</p>}
          </div>
          {showButtons && (
            <button
              className="add-button pointer"
              type="button"
              onClick={() => handleReview(details)}
            >
              <RiAddLine />
              Action
            </button>
          )}
        </div>
      )}
      {isReviewed && (
        <div className="reviewed">
          <div className="reviewer-meta-data">
            <img src={userIcon} />
            <div className="name-and-title">
              {name && <h5 className="user-name">{name}</h5>}
              {office && <p className="title">{office}</p>}
              {mda && <p className="title">{mda}</p>}
              {date && <span className="date-time">{date}</span>}
              { !isShowDownloadModal &&
              <span
              className="view-details"
              onClick={() => viewDetails(comment, score)}
              >
                View Details
              </span>
              }
              {date && isShowDownloadModal && <h4 className=" bold-text">{comment}</h4>}
            </div>
          </div>

          {/* <div className="score-container">
            <div className="score-title">Proposed Score</div>
            <div className="score">{score}</div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Reviewer;
