import React from "react";
import { useState, useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import { useSelector } from "react-redux";
import CareerPlanForm from "../Forms/ReviewForm/CareerPlanForm";
import { getLongTermGoal, getShortTermGoal } from "../../network/services";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';


const DownloadCareerPlans = ({reviewers, initiator, disable,closeModal, longTermPlans,shortTermPlans}) => {
  const [shortTerm, setShortTerm] = useState(true);
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const pdfExportComponent = React.useRef(null);


  
  
  

  const handleClickEditGoal = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();
    setDetails(plan);
    setFormDetails(plan);
    setShowFormModal(true);
  };

  return (
    <div>
    <div className="flex space-between">
    <div className="mb5 flex justify-content-center m-b-20 m-t-40">
            <button
              className="btn btn-large"
              onClick={closeModal}
            >
             Back to record
            </button>
          </div>
    <div className="mb5 flex justify-content-center m-b-20 m-t-40">
            <button
              className="btn btn-large"
              onClick={() => {
                if (pdfExportComponent.current) {
                  pdfExportComponent.current.save();
                }
              }}
            >
              Download PDF
            </button>
          </div>
    </div>
   
    <PDFExport
    paperSize="A2"
    margin="2mm"
    fileName={'Career Plan?.pdf'}
    ref={pdfExportComponent}
    // paperSize="A3"
    // margin="2mm"
    scale={0.8}
    keepTogether=".p"
    forcePageBreak=".page-break"
    // fileName={`${pdfName}.pdf`}
    // ref={pdfExportComponent}
    >

    
      {isShowFormModal && (
          <CareerPlanForm
          initiator={initiator}
          disable={disable}
          details={details}
          formDetails={formDetails}
          shortTerm={shortTerm}
          closeModal={() => setShowFormModal(false)}
        />
      )}
    <div className="center-text m-t-40">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
        <h3 className="m-t-10 m-b-20">
            Edo State 2023 Annual Appraisal Career Plans
            {/* <span id="currentYear"></span> */}
      </h3>
      </div>
    <div className="pdf-section">
      <h4 className="m-t-10">Career Plans</h4>
      <div className="flex space-between">
        <div className="flex ">
          <button
            className={
              !shortTerm
                ? "btn-gray m-r-10 career_button"
                : "btn career_button m-r-10"
            }
            onClick={() => (setShortTerm(true), setFormDetails(null))}
          >
            Short Term
          </button>
          <button
            className={
              shortTerm
                ? "btn-gray m-r-10 career_button"
                : "btn career_button m-r-10"
            }
            onClick={() => (setShortTerm(false), setFormDetails(null))}
          >
            Long Term
          </button>
        </div>
        <div>
          {shortTerm ? (
            <p style={{ color: "red" }}>
              Identify job plans for the next 2 years
            </p>
          ) : (
            <p style={{ color: "red" }}>
              Identify job plans and options for the next 3-5 years
            </p>
          )}
        </div>
      </div>
      <div className="table-view">
        <div className="table-header">
          <div className="table-row">
            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Title</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Details</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Dependency</p>
            </div>

            <div className="th san-sherif" style={{ width: "5%" }}>
              <p>Expected Outcome</p>
            </div>

            {/* <div className="th san-sherif" style={{ width: "10%" }}>
              <p>Last Update</p>
            </div> */}

            <div className="th">
              { disable &&
                <button
                  className="add-button pointer"
                  type="button"
                  onClick={() => (setShowFormModal(true), setFormDetails(null))}
                >
                  <RiAddLine />
                  Add Item
                </button>
              }
            </div>
          </div>
        </div>
        {shortTerm && (
          <div className="table-body" style={{ overflow: "scroll" }}>
            {shortTermPlans?.map((plan) => (
              <div className="table-row">
                <div className="td-no-inherit td-cell">{plan?.title}</div>
                <div className="td-no-inherit">
                  <div>{plan?.details}</div>
                </div>
                <div className="td-no-inherit">{plan?.dependency}</div>
                <div className="td">
                  <div className="td-no-inherit">{plan?.expectedOutcome}</div>
                </div>
                {/* <div className="td">20:11:2020 14:05:23PM</div> */}
                <div className="td">
                  <div className="actions">
                   { disable &&
                    <button
                      type="button"
                      className="btn activate"
                      onClick={(e) => handleClickEditGoal(e, plan)}
                    >
                      <GrEdit />
                    </button>
                    }
                    
                  </div>
                </div>
              </div>
            ))}
            
          </div>
        )}

        {!shortTerm && (
          <div className="table-body" style={{ overflow: "scroll" }}>
            {longTermPlans?.map((plan) => (
              <div className="table-row">
                <div className="td-no-inherit td-cell">{plan?.title}</div>
                <div className="td-no-inherit">
                  <div>{plan?.details}</div>
                </div>
                <div className="td-no-inherit">{plan?.dependency}</div>
                <div className="td-no-inherit">
                  <div className="td-no-inherit">{plan?.expectedOutcome}</div>
                </div>
                <div className="td">
                  <div className="actions">
                    {disable &&
                      <button
                      type="button"
                      className="btn activate"
                       onClick={(e) => handleClickEditGoal(e, plan)}
                    >
                      <GrEdit />
                    </button>
                    }
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
    </PDFExport>
    </div>
  );
};
export default DownloadCareerPlans;
