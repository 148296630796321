import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { BiEnvelope } from "react-icons/bi";
import LocationDetails from "../../components/layouts/LocationDetails";
import OrganogramForm from "../../components/layouts/OrganogramForm";
import Cookies from "js-cookie";
import Spinner from "../../components/common/spinner/spinner";
import { GET_PERFORMANCE_DETAILS } from "../../redux/actions/actionsTypes";
import {
  getAppraisalRatings,
  getPerformanceGoalsData,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import Collapsible from "../../components/common/collapsible/collapsible";

import "./appraisalDashboard.scss";

const AppraisalDashboard = () => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [goal, setGoal] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingRatings, setIsFetchingAppraisalRatings] = useState(false);
  const [appraisalRatings, setAppraisalRatings] = useState([]);

  const dispatch = useDispatch();

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - MDA Appraisal Dashboard";
  }, []);

  const getGoals = async () => {
    setIsLoading(true);
    try {
      let response = await getPerformanceGoalsData(
        dashboardDetails.performanceId
      );

      if (response.data.code === 1) {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,

          payload: response?.data?.data,
        });
        setGoal(response?.data?.data);
        setIsLoading(false);
      } else {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,
          payload: null,
        });
        setIsLoading(false);
      }
    } catch (e) {
      dispatch({
        type: GET_PERFORMANCE_DETAILS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
      setIsLoading(false);
    }
  };

  const getQuarterScore = (goalsArray) => {
    if (goalsArray.length > 0) {
      const totalQuarterOneScore = goalsArray.reduce(
        (previous, current) => previous + current.quaterOneSupervisorScore,
        0
      );

      const totalQuarterTwoScore = goalsArray.reduce(
        (previous, current) => previous + current.quaterTwoSupervisorScore,
        0
      );

      const totalQuarterThreeScore = goalsArray.reduce(
        (previous, current) => previous + current.quaterThreeSupervisorScore,
        0
      );

      const totalQuarterFourScore = goalsArray.reduce(
        (previous, current) => previous + current.quaterFourSupervisorScore,
        0
      );

      const QuarterDetails = {
        "Quarter 1": totalQuarterOneScore,
        "Quarter 2": totalQuarterTwoScore,
        "Quarter 3": totalQuarterThreeScore,
        "Quarter 4": totalQuarterFourScore,
      };

      Object.keys(QuarterDetails).forEach((item) => {
        if (QuarterDetails[item] === 0) {
          delete QuarterDetails[item];
        }
      });

      return QuarterDetails;
    }

    return {};
  };

  const truncateComment = (comment, num = 50) => {
    if (comment !== null) {
      if (comment.length <= num) {
        return comment;
      }
    } else {
      return "";
    }

    return `${comment.slice(0, num)}...`;
  };

  const getRatingAndDescription = (scoreInQuestion) => {
    if (appraisalRatings.length < 1) {
      return { grade: "No ratings yet", description: "No ratings yet" };
    } else {
      const result = appraisalRatings.find(
        (item) =>
          scoreInQuestion >= item.fromScore && scoreInQuestion <= item.toScore
      );

      return { grade: result?.grade, description: result?.ratingDescription };
    }
  };

  const fetchAppraisalRatings = async () => {
    setIsFetchingAppraisalRatings(true);
    try {
      const response = await getAppraisalRatings();
      if (response?.status === 200) {
        setAppraisalRatings(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingAppraisalRatings(false);
  };

  useEffect(() => {
    fetchAppraisalRatings();
  }, []);

  useEffect(() => {
    if (dashboardDetails) {
      getGoals();
    }
  }, [dashboardDetails]);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <div>
          <p>Good Day</p>
          <p>{Cookies.get("fullName")}</p>
          <p>{Cookies.get("_Role")}</p>
        </div>
        <LocationDetails />
      </div>

      <Collapsible />
      <div className="stat-card-container">
        <div className="stat-card">
          <div className="stat-card-header">
            <span>Period/Pending Approvals</span>
            <BiEnvelope className="icon" size={20} />
          </div>
          <hr className="stat-card-divider" />
          <div className="stat-card-body">
            <div>
              <div className="stat-card-item">
                <span>Active Period: </span>
                <span>{dashboardDetails?.year}</span>
              </div>
              <hr className="stat-card-item-divider" />
            </div>

            <div>
              <div className="stat-card-item">
                <span>Pending Aprovals: </span>
                <span>0</span>
              </div>
            </div>
          </div>
        </div>

        <div className="stat-card">
          <div className="stat-card-header">
            <span>Performance Stage</span>
            <BiEnvelope className="icon" size={20} />
          </div>
          <hr className="stat-card-divider" />
          <div className="stat-card-body">
            <div>
              <div className="stat-card-item">
                <span>Current Stage </span>
                <span>{dashboardDetails?.stage}</span>
              </div>
              <hr className="stat-card-item-divider" />
            </div>

            <div>
              <div className="stat-card-item">
                <span>Valid Till: </span>
                <span>{dashboardDetails?.endDate}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {JSON.parse(Cookies.get("isSupervisor")) === false && (
        <>
          <div className="row">
            <div className="col-6">
              <div className="table-view m-t-10">
                <div className="table-header">
                  <div className="table-row">
                    <div className="th">
                      <p>Quarter</p>
                    </div>

                    <div className="th">
                      <p>Score</p>
                    </div>

                    <div className="th">
                      <p>Rating</p>
                    </div>

                    <div className="th">
                      <p>Description</p>
                    </div>
                  </div>
                </div>
                <div className="table-body">
                  {Object.keys(getQuarterScore(goal)).map((item, index) => (
                    <div className="table-row" key={index}>
                      <div className="td">{item}</div>
                      <div className="td">{getQuarterScore(goal)[item]}</div>
                      <div className="td">
                        {
                          getRatingAndDescription(getQuarterScore(goal)[item])
                            ?.grade
                        }
                      </div>
                      <div className="td">
                        {
                          getRatingAndDescription(getQuarterScore(goal)[item])
                            ?.description
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "2rem" }} className="dashboard-table">
            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p>Targets/Goals</p>
                  </div>
                  <div className="th">
                    <p>KPIs</p>
                  </div>
                  <div className="th">
                    <p>Weight</p>
                  </div>
                  <div className="th">
                    <p>Sequence</p>
                  </div>
                  <div className="th">
                    <p>Quarter One Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter One Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter One Supervisor's Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter One Supervisor's Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Two Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter Two Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Two Supervisor's Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter Two Supervisor's Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Three Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter Three Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Three Supervisor's Score (../100)</p>
                  </div>
                  <div className="th  ">
                    <p>Quarter Three Supervisor's Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Four Score (../100)</p>
                  </div>
                  <div className="th">
                    <p>Quarter Four Comment</p>
                  </div>
                  <div className="th">
                    <p>Quarter Four Supervisor's Score (../100)</p>
                  </div>
                  <div className="th">
                    <p>Quarter Four Supervisor's Comment</p>
                  </div>
                  <div className="th">
                    <p>Approval Status</p>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <Spinner />
              ) : (
                <div className="table-body">
                  {goal?.map((goal) => (
                    <div className="table-row" key={goal.id}>
                      <div className="td">{goal?.objective}</div>
                      <div className="td">{goal.performanceIndicator}</div>
                      <div className="td">{goal.weight}</div>
                      <div className="td">{goal.priority}</div>
                      <div className="td">
                        {goal.quaterOneScore && goal.quaterOneScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterOneComment)}
                      </div>
                      <div className="td">
                        {" "}
                        {goal.quaterOneSupervisorScore &&
                          goal.quaterOneSupervisorScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterOneSupervisorComment)}
                      </div>
                      <div className="td">
                        {goal.quaterTwoScore && goal.quaterTwoScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterTwoComment)}
                      </div>
                      <div className="td">
                        {" "}
                        {goal.quaterTwoSupervisorScore &&
                          goal.quaterTwoSupervisorScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterTwoSupervisorComment)}
                      </div>
                      <div className="td">
                        {goal.quaterThreeScore && goal.quaterThreeScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterThreeComment)}
                      </div>
                      <div className="td">
                        {" "}
                        {goal.quaterThreeSupervisorScore &&
                          goal.quaterThreeSupervisorScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterThreeSupervisorComment)}
                      </div>
                      <div className="td">
                        {goal.quaterFourScore && goal.quaterFourScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterFourComment)}
                      </div>
                      <div className="td">
                        {" "}
                        {goal.quaterFourSupervisorScore &&
                          goal.quaterFourSupervisorScore}
                      </div>
                      <div className="td">
                        {truncateComment(goal?.quaterFourSupervisorComment)}
                      </div>
                      <div className="td">{goal?.approvalStatus}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="table-view m-t-10">
        <div className="table-header">
          <div style={{ width: "100%" }} className="table-row">
            <div className="th">
              <p>Qualitative Assessment</p>
            </div>
            <div
              className="th"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p>Qualitative Assessment</p>
            </div>
          </div>
        </div>
        <div className="table-body">
          {[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }].map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">Samuel Ademuyinwa</div>
              <div className="td">5hh5h5h5</div>
            </div>
          ))}
        </div>
      </div> */}

      {showFormModal && (
        <OrganogramForm closeModal={() => setShowFormModal(false)} />
      )}
    </div>
  );
};

export default AppraisalDashboard;
