import React, { useEffect, useRef, useState } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineFileDone } from "react-icons/ai";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import Declaration from "../../components/common/Declaration/declaration";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewFormAnnual";
import StaffView from "../../components/layouts/StaffView";
import CareerPlans from "../../components/layouts/CareerPlans";
import TrainingDevelopment from "../../components/layouts/TrainingDevelopment";
import ReviewFormAnnualPerformanceRating from "../../components/Forms/ReviewForm/ReviewFormAnnualPerformanceRating";
import ReviewFormAnnualLeadershipCapabilities from "../../components/Forms/ReviewForm/ReviewFormAnnuallLeadershipCapabilities";
import "./AppraisalReviewRequest.scss";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  getMidYearData,
  getPerformanceGoalsData,
  getAnnualData,
  getApproverAnnualQueue,
  getApproverPerformanceRatingOnQueue,
  getApproverLeadershipCapabilityOnQueue,
  putApprovePerformanceRating,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import Cookies from "js-cookie";

const AnnualApprover = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [staffViews, setStaffViews] = useState("");

  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isShowPerformanceFormModal, setShowPerformanceFormModal] =
    useState(false);
  const [isShowLeadershipFormModal, setShowLeadershipFormModal] =
    useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [performanceRating, setPerformanceRating] = useState([]);
  const [leadershipCapability, setLeadershipCapability] = useState([]);
  const [tableDataPerformance, setTableDataPerformance] = useState([]);
  const [tableDataLeadership, setTableDataLeadership] = useState([]);
  const [appraiseesPerformance, setAppraiseesPerformance] = useState([]);
  const [appraiseesLeadership, setAppraiseesLeadership] = useState([]);

  const [openTab, setOpenTab] = useState(0);
  const [goals, setGoals] = useState([])
  const [wishes, setWishes] = useState('')
  const [wishesId, setWishesId] = useState(Number)
  const [code , setCode] = useState(Number)
  const [appraisees, setAppraisees] = useState([]);
  const [tableData, setTableData] = useState([])
  const [searchValue, setSearchValue] = useState({label: "All Initiators", value:0});
  const [searchValuePerformance, setSearchValuePerformance] = useState({label: "All Initiators", value:0});
  const [searchValueLeadership, setSearchValueLeadership] = useState({label: "All Initiators", value:0});
  const [assignee, setAssignee] = useState("")
  const [assigneePerformance, setAssigneePerformance] = useState("")
  const [assigneeLeadership, setAssigneeLeadership] = useState("")
  const [initiator, setInitiator] = useState('')
  const [Review, setReview] = useState(false);
  const [performanceScore, setPerformanceScore] = useState({})
  const [leadershipScore, setLeadershipScore] = useState({})
  const [performanceReviewerScore, setPerformanceReviewerScore] = useState([])
  const [leadershipReviewerScore, setLeadershipReviewerScore] = useState([])


  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);

  let midYearData = [];
  let disable = false;
  const userId = Cookies.get('userId');

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const showDetails = (comment, score) => {
    setFormDetails({ comment, score });
    setShowFormModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Appraisal Annual Review";
    // fetchGoalsAndCommitments(dashboardDetails?.performanceId);
    const fetchData = async () => {
      await fetchAnnualData();
      await fetchPerformanceRatingData();
      await fetchLeadershipCapabilityData();
    };
    fetchData()
    
  }, []);

 
  
 
  const sortTable = (text) => {
    if(text === "All Initiators"){
      setTableData([])

      return;
    }
    
    setTableData(goalsAndCommitments?.filter((item) => {
      return item?.reviewers[0]?.appraisee.includes(text)
    }))
  }

  const sortTablePerformance = (text) => {
    if(text === "All Initiators"){
      setTableDataPerformance([])
      
      return;
    }
    
    setTableDataPerformance(performanceRating?.filter((item) => {
      return item?.userAssessmentCriteria[0]?.reviewers[0]?.appraisee.includes(text)
    }))
  }


  const sortTableLeadership = (text) => {
    if(text === "All Initiators"){
      setTableDataLeadership([])
      
      return;
    }
    
    setTableDataLeadership(leadershipCapability?.filter((item) => {
      return item?.userAssessmentCriteria[0]?.reviewers[0]?.appraisee.includes(text)
    }))
  }

  

  useEffect(() => {
    const key = 'label';
    Array.isArray(goalsAndCommitments) && setAppraisees(
      [...new Map(
        goalsAndCommitments?.map((item, index) => (
          {
            label: item?.reviewers[0]?.appraisee,
            value: index + 1
          }
        )).map(item =>
          [item[key], item]))?.values()]
    );
    sortTable(assignee);

    Array.isArray(performanceRating) && setAppraiseesPerformance(
      [...new Map(
        performanceRating?.map((item, index) => (
          {
            label: item?.userAssessmentCriteria[0]?.reviewers[0]?.appraisee,
            value: index + 1
          }
        )).map(item =>
          [item[key], item]))?.values()]
    );
    sortTablePerformance(assigneePerformance);

    Array.isArray(leadershipCapability) && setAppraiseesLeadership(
      [...new Map(
        leadershipCapability?.map((item, index) => (
          {
            label: item?.userAssessmentCriteria[0]?.reviewers[0]?.appraisee,
            value: index + 1
          }
        )).map(item =>
          [item[key], item]))?.values()]
    );
    sortTableLeadership(assigneeLeadership);

    
  }, [goalsAndCommitments,performanceRating,leadershipCapability])
  
  console.log(appraisees)

  
  useEffect(() => {
    const createdByValues = Array.from(new Set(tableData?.map(item => item?.reviewers[0]?.appraiseeId)));
    
    console.log('tableData:', tableData);
    console.log('createdByValues:', createdByValues);
    console.log('performance:', performanceRating);
    console.log('leadership:', leadershipCapability)
    console.log('INITAITOR:', initiator);

 
    
    const filteredPerformanceRating = filterPerformanceRating(createdByValues);
    const filteredLeadershipCapabillity = filterLeadership(createdByValues);
    
    // Array.isArray(filteredLeadershipCapabillity) && setableDataLeadership(filteredLeadershipCapabillity[0]?.userAssessmentCriteria);
    // Array.isArray(filteredPerformanceRating) && setableDataPerformance(filteredPerformanceRating[0]?.userAssessmentCriteria);
    
  }, [tableData]);
  
  const filterPerformanceRating = (createdByValues) => {
    setInitiator(createdByValues)
    console.log('createdByValues in perFIlter:', createdByValues);
    console.log('PERFORMANCE IN FILTER', performanceRating)
    const filteredData = performanceRating?.filter((rating) => {
      const criteria = rating?.userAssessmentCriteria[0];
      console.log('criteria:', criteria);
      console.log('modifiedBy:', criteria?.modifiedBy);
      console.log('userId:', criteria?.userId);
      return criteria && (createdByValues?.includes(criteria?.userId));
    });
  
    console.log('FILTERED PERF',filteredData);
    return filteredData;
  };

  console.log('PERFORMANCE IN MAin', tableDataPerformance)
  console.log('LEARDerSHIP IN MAin', tableDataLeadership)

  const filterLeadership = (createdByValues) => {
    console.log('CREATED BY VALUE', createdByValues[0])
    console.log('LEADERSHIP IN FILTER', leadershipCapability)
    const filteredData = leadershipCapability?.filter((rating) => {

      const criteria = rating?.userAssessmentCriteria[0];
      console.log('CRITERIA USER ID', criteria.userId)
      return criteria && (createdByValues?.includes(criteria?.userId));
    });
  
    console.log('FILTERED LEAD',filteredData);
    return filteredData;
  };

  console.log('LEADERSHIP' ,leadershipCapability)
  console.log('PERFORMANCE' ,performanceRating)

  const fetchAnnualData = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getApproverAnnualQueue(
        dashboardDetails?.performanceId
      );
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setGoalsAndCommitments(response?.data?.data);
      } else {
        // feedback({
        //   title: "Failed",
        //   text: response?.data?.message,
        //   iconType: "error",
        // });
      }
    } catch (error) {
      setGoalsAndCommitments([])
      // feedback({
      //   title: "Empty Record",
      //   text: error?.response?.data?.message,
      //   iconType: "warning",
      // });
    }
    setIsFetchingGoals(false);
  };

  const fetchPerformanceRatingData = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getApproverPerformanceRatingOnQueue(
        // dashboardDetails?.performanceId
      );
      console.log('res',response)
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setPerformanceRating(response?.data?.data);
      }
    } catch (error) {
      setTableDataPerformance([])
     
    }
    setIsFetchingGoals(false);
    console.log(performanceRating);
  };
  const fetchLeadershipCapabilityData = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getApproverLeadershipCapabilityOnQueue(
        // dashboardDetails?.performanceId
      );
      console.log('res',response)
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setLeadershipCapability(
          response?.data?.data
        );
      } else {
       
      }
    } catch (error) {
     setTableDataLeadership([])
    }
    setIsFetchingGoals(false);
    console.log(leadershipCapability);
  };
  
  const handleScoreChange = (e, item) => {
    const enteredScore = e?.target?.value;
    // Validate that the entered score is a number within the range of 0-10
    const numericScore = Number(enteredScore);
  
    if (isNaN(numericScore) || numericScore < 0 || numericScore > 10) {
      // If the entered score is invalid, display an error message
      feedback({
        title: "Please enter values between 0-10",
        iconType: "error",
      });
      console.error("Invalid score entered. Please enter a number between 0 and 10.");
    } else {
      console.log(enteredScore)
      
      setPerformanceScore((prevValues) => ({
        ...prevValues,
        [item?.ratingId]: enteredScore,
      }));

      console.log('PERFSCORES', performanceScore)

      
      

      console.error("Invalid score entered. Please enter a number between 0 and 10.");
    }
  };

  const handlePerformanceScores = () => {
    const convertedArray = Object.entries(performanceScore).map(([assessmentId, score]) => ({
      assessmentId: parseInt(assessmentId),
      score: parseInt(score)
    }));
  
    setPerformanceReviewerScore(convertedArray)
    console.log('PERFSCORE2', performanceReviewerScore)
  }

  useEffect(() => {
    handleLeadershipScores()
    handlePerformanceScores()
  }, [performanceScore,leadershipScore])
  
  

  const handleScoreChangeLeadership = (e, item) => {
    const enteredScore = e?.target?.value;
    // Validate that the entered score is a number within the range of 0-10
    const numericScore = Number(enteredScore);
  
    if (isNaN(numericScore) || numericScore < 0 || numericScore > 10) {
      // If the entered score is invalid, display an error message
      feedback({
        title: "Please enter values between 0-10",
        iconType: "error",
      });
      console.error("Invalid score entered. Please enter a number between 0 and 10.");
    } else {
      console.log(enteredScore)
      
      setLeadershipScore((prevValues) => ({
        ...prevValues,
        [item?.ratingId]: enteredScore,
      }));

      console.log('LEADERSHIPSCORES', leadershipScore)

      

      

      console.error("Invalid score entered. Please enter a number between 0 and 10.");
    }
  };

  const handleLeadershipScores = () => {
    const convertedArray = Object.entries(leadershipScore).map(([assessmentId, score]) => ({
      assessmentId: parseInt(assessmentId),
      score: parseInt(score)
    }));
  
    setLeadershipReviewerScore(convertedArray)
    console.log('LEADERSHIPSCORES2', leadershipReviewerScore)
  }




  const setComment = (comment, data) => {
    console.log(comment, data);
    let recordExist =
      Array.isArray(midYearData) &&
      midYearData?.find((record) => record?.goalId === data?.id);

    if (recordExist === false || recordExist === undefined) {
      midYearData = [
        ...midYearData,
        {
          goalId: data?.id,
          comment: data?.comment,
        },
      ];
    } else {
      Array.isArray(midYearData) &&
        midYearData?.find(
          (record) =>
            record.goalId === data?.id && ((record.comment = comment), true)
        );
    }
  };

  const handleReview = (data) => {
    setFormDetails(null);
    setDetails(data);
    return setShowFormModal(true);
  };

  const handlePerformanceReview = (data) => {
    setFormDetails(null);
    setDetails(data);
    return setShowPerformanceFormModal(true);
  };

  const handleLeadershipReview = (data) => {
    setFormDetails(null);
    setDetails(data);
    return setShowLeadershipFormModal(true);
  };

  const handleNext = ()=>{
    setOpenTab(openTab+1)
    
    
  }

  const handleBack = ()=>{
    setOpenTab(openTab-1)
  }

 

 

  console.log("OPENTAB",openTab)
  console.log("OPENTAB",Review)

  
  let reviewers = goalsAndCommitments?.map((item)=>item?.reviewers);
  
  console.log('perf',tableDataPerformance[0]?.userAssessmentCriteria[0])
  console.log('goal to be reviewed:', goalsAndCommitments)
  console.log('goal data',goals)
  console.log('leadership',tableDataLeadership)
  console.log('reviwers',reviewers)
  console.log('details',dashboardDetails)
  console.log('TABLEDATA',tableData)

  return (
    <div className="w-100">
      
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Appraisal</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">Appraisal Approval</p>
        {reviewers.length > 0 &&
          <div className="bottom-border">
          <div className="flex">
            <div
              onClick={() => setOpenTab(0)}
              className={
                openTab === 0
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Performance Review
            </div>
            <div
              onClick={() => setOpenTab(1)}
              className={
                openTab === 1
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Staff View &amp; Wishes
            </div>
            <div
              onClick={() => setOpenTab(2)}
              className={
                openTab === 2
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Career Plans
            </div>
            <div
              onClick={() => setOpenTab(3)}
              className={
                openTab === 3
                  ? "flex annual_button m-r-10 "
                  : "flex annual_button1 m-r-10 "
              }
            >
              Training and Development
            </div>
          </div>
        </div>
        
        }

      {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      /> */}

      <h3 className="m-t-10 bold-text ">Please go through the Staff Wish, Career Plan and Training and Development tabs before reviewing </h3>  
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Departmental Commitment"
      />
        
      {openTab === 0 && (
      <div>

      {/* <h3 className="bold-text m-t-40 m-b-20">Please only approve records that are complete (i.e records that have Personal Targets, Performance Rating and leadership Capability present)</h3> */}
      <div className='m-b-10 flex_basis_30 form-group  m-t-20'>
          <label className="m-t-20">Initiators</label>
          <h3 className="bold-text">Please use initiator dropdown to filter through user Personal Target records</h3>
          <Select
            label='Select Initiators'
            color='rgba(120,120,120,0.8)'
            className='m-b-10 w-w00'
            onChange={(value) => {
              console.log(value);
              setSearchValue(value);
              setAssignee(value?.label);
              sortTable(value?.label)
            }}
            value={searchValue}
            options={[
              {label: "All Initiators", value:0},
              ...appraisees
            ]}
          />

          {/* <h3 className="bold-text m-t-20 m-b-20">Please only approve Personal Target after approving Performance Ratings and Leadership Capability </h3> */}
        </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
          <div className="flex space-between">
              <div>
                <h4>My Personal Targets & Performance (Quantitative)</h4>
                <p className="sub-header">
                  The Head of the Department in consultation with my
                  Division/Section/Unit set out to achieve the following
                </p>
              </div>

              
          </div>
          
            <div className="table-view m-t-10 score-table ">
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "20%" }}
                  >
                    <p>Commitments</p>
                  </div>
                  {/* <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Your Score (.../100)</p>
                  </div> */}
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "40%" }}
                  >
                    <p>Employee's Comments</p>
                    {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Reviewers</p>
                  </div>
                </div>
              </div>
              <div
                className="table-body score-table-body"
                style={{ overflow: "scroll" }}
              >
                {tableData?.map((item, index) => (
                  <div
                    className={`table-row score-table-row m-t-10`}
                    key={index}
                  >
                    <div className="td td-cell score-table-td">
                      <h3 className="bold-text">{item?.objective}</h3>
                      {Array.isArray(item?.kpis) &&
                        item?.kpis?.map((kpi) => (
                          <div>
                            &nbsp;&nbsp;&nbsp; -{kpi?.performanceIndicator}
                          </div>
                        ))}
                    </div>
                    {/* <div className={`td score-table-td h-100`}>
                      {
                        <textarea
                          rows={item?.kpis?.length + 2}
                          className=" h-100"
                          onChange={(e) => setScore(e.target?.value, item)}
                          value={item?.score === 0 ? null : item?.score}
                          disabled={item?.score === 0 ? false : true}
                        />
                      }
                    </div> */}
                    <div className="td score-table-td h-100">
                      {
                        <textarea
                          rows={item?.kpis?.length + 2}
                          className="w-100 h-100"
                          onChange={(e) => setComment(e.target?.value, item)}
                          value={item?.comment === "" ? null : item?.comment}
                          disabled
                        />
                      }
                    </div>
                    <div className="td score-table-td">
                      {item?.reviewers?.map((reviewer, index) => (
                        <Reviewer
                          key={reviewer?.id}
                          handleReview={handleReview}
                          isReviewed={item?.status === 3 }
                          score={reviewer?.score}
                          comment={reviewer?.goalComment}
                          office={`${reviewer?.role}`}
                          mda={`${reviewer?.approverMDA}`}
                          name={reviewer?.approver}
                          date={reviewer?.date}
                          details={item}
                          showButtons={
                            reviewer?.approver === Cookies.get("fullName") 
                          }
                          viewDetails={showDetails}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      


      <div className='m-b-10 flex_basis_30 form-group  m-t-20'>
          <label className="m-t-20">Initiators</label>
          <h3 className="bold-text">Please use initiator dropdown to filter through Performance Rating records</h3>
          <Select
            label='Select Initiators'
            color='rgba(120,120,120,0.8)'
            className='m-b-10 w-w00'
            onChange={(value) => {
              console.log(value);
              setSearchValuePerformance(value);
              setAssigneePerformance(value?.label);
              sortTablePerformance(value?.label)
            }}
            value={searchValuePerformance}
            options={[
              {label: "All Initiators", value:0},
              ...appraiseesPerformance
            ]}
          />

        </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>Performane Rating</h4>
            <p className="sub-header">
              The Head of the Department in consultation with my
              Division/Section/Unit set out to achieve the following
            </p>
            <div className="table-view m-t-10 score-table ">
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "20%" }}
                  >
                    <p>Commitments</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Employee's Score (.../10)</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "40%" }}
                  >
                    <p>Employee's Comments</p>
                    {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Reviewers</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Employee's Score (.../10)</p>
                  </div>
                </div>
              </div>
              <div
                className="table-body score-table-body"
                style={{ overflow: "scroll" }}
              >
                {Array.isArray(tableDataPerformance[0]?.userAssessmentCriteria) && tableDataPerformance[0]?.userAssessmentCriteria?.map((item, index) => (
                  <div
                    className={`table-row score-table-row m-t-10`}
                    key={index}
                  >
                    <div className="td td-cell score-table-td">
                      <h3 className="bold-text">{item?.assessmentCriteria}</h3>
                    </div>
                    <div className={`td score-table-td h-100`}>
                      {
                        <input
                        className="w-100 h-100"
                        style={{ border: "1px solid #000" }}
                        type="number"
                        onChange={(e) => handleScoreChange(e, item)}
                        value={item?.score}
                        disabled={(item?.createdBy !== userId)}
                      />
                      }
                    </div>
                    <div className="td score-table-td h-100">
                      { 
                      
                        <textarea
                          rows={4}
                          className="w-100 h-100"
                          onChange={(e) => setComment(e.target?.value, item)}
                          value={
                            item?.achievements === "" ? null : item?.achievements
                          }
                          disabled = {item?.createdBy !== userId}
                        />
                      }
                    </div>
                   <div className="td score-table-td">
                          <div>
                            {item?.reviewers?.map((reviewer, index) => (
                              <Reviewer
                                key={reviewer?.id}
                                handleReview={handlePerformanceReview}
                                isReviewed={item?.statusId === 3}
                                score={reviewer?.score}
                                comment={reviewer?.goalComment}
                                office={`${reviewer?.role}`}
                                mda={`${reviewer?.approverMDA}`}
                                name={reviewer?.approver}
                                date={reviewer?.date}
                                details={item}
                                showButtons={false}
                                viewDetails={showDetails}
                              />
                            ))}
                          </div>
                        
                    </div>
                    <div className={`td score-table-td h-100`}>
                      {
                        <input
                        className="w-100 h-100"
                        style={{ border: "1px solid #000" }}
                        type="number"
                        onChange={(e) => handleScoreChange(e, item)}
                        value={performanceScore[item?.ratingId] || item?.reviewerScore || ''}
                        disabled ={item?.reviewers?.[0]?.approverId !== undefined && item?.reviewers[0]?.approverId !== Number(userId)}
                        
                      />
                      }
                    </div>

                  </div>
                ))}
         
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {tableDataPerformance && tableDataPerformance[0]?.userAssessmentCriteria?.length > 0 &&
        <div className="submit-goal-container m-b-40">
            <button onClick={()=>{handlePerformanceReview(tableDataPerformance[0]?.userAssessmentCriteria[0])}} type="button" className="btn btn-large">
              Review Performance Ratings
            </button>
        </div> 
      
      }


<div className='m-b-10 flex_basis_30 form-group  m-t-20'>
          <label className="m-t-20">Initiators</label>
          <h3 className="bold-text">Please use initiator dropdown to filter through Leadership Capability records</h3>
          <Select
            label='Select Initiators'
            color='rgba(120,120,120,0.8)'
            className='m-b-10 w-w00'
            onChange={(value) => {
              console.log(value);
              setSearchValueLeadership(value);
              setAssigneeLeadership(value?.label);
              sortTableLeadership(value?.label)
            }}
            value={searchValueLeadership}
            options={[
              {label: "All Initiators", value:0},
              ...appraiseesLeadership
            ]}
          />

        </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>Leadership Capability</h4>
            <p className="sub-header">
              The Head of the Department in consultation with my
              Division/Section/Unit set out to achieve the following
            </p>
            <div className="table-view m-t-10 score-table ">
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "20%" }}
                  >
                    <p>Commitments</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Employee's Score (.../10)</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "40%" }}
                  >
                    <p>Employee's Comments</p>
                    {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Reviewers</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "5%" }}
                  >
                    <p>Employee's Score (.../10)</p>
                  </div>
                </div>
              </div>
              <div
                className="table-body score-table-body"
                style={{ overflow: "scroll" }}
              >
                {Array.isArray(tableDataLeadership) && tableDataLeadership[0]?.userAssessmentCriteria?.map((item, index) => (
                  <div
                    className={`table-row score-table-row m-t-10`}
                    key={index}
                  >
                    <div className="td td-cell score-table-td">
                      <h3 className="bold-text">{item?.assessmentCriteria}</h3>
                    </div>
                    <div className={`td score-table-td h-100`}>
                      {
                        <input
                        className="w-100 h-100"
                        style={{ border: "1px solid #000" }}
                        type="number"
                        value={item?.score}
                        disabled
                      />
                      }
                    </div>
                    <div className="td score-table-td h-100">
                      {
                        <textarea
                          rows={item?.kpis?.length + 2}
                          className="w-100 h-100"
                          onChange={(e) => setComment(e?.target?.value, item)}
                          value={item?.achievements === "" ? null : item?.achievements}
                          disabled
                        />
                      }
                    </div>
                    <div className="td score-table-td">
                          <div>
                            {item?.reviewers?.map((reviewer, index) => (
                              <Reviewer
                                key={reviewer?.id}
                                handleReview={handleLeadershipReview}
                                isReviewed={item?.statusId === 3}
                                score={reviewer?.score}
                                comment={reviewer?.goalComment}
                                office={`${reviewer?.role}`}
                                mda={`${reviewer?.approverMDA}`}
                                name={reviewer?.approver}
                                date={reviewer?.date}
                                details={item}
                                showButtons={false}
                                viewDetails={showDetails}
                              />
                            ))}
                          </div>
                    </div>
                    <div className={`td score-table-td h-100`}>
                      {
                        <input
                        className="w-100 h-100"
                        style={{ border: "1px solid #000" }}
                        type="number"
                        onChange={(e) => handleScoreChangeLeadership(e, item)}
                        value={leadershipScore[item?.ratingId] || item?.reviewerScore || ""}
                        disabled ={item?.reviewers?.[0]?.approverId !== undefined && item?.reviewers[0]?.approverId !== Number(userId)}
                      />
                      }
                    </div>
                    
                  </div>
                ))}
              </div>
                          </div>
            { Array.isArray(tableDataLeadership) && tableDataLeadership && (
              <div>
                <textarea
                  placeholder="Personality Profile (Brief description of key strengths and weaknesses):"
                  defaultValue={
                    Array.isArray(tableDataLeadership) &&
                    tableDataLeadership[0]?.userAssessmentCriteria[0]?.personalityProfile ?
                    tableDataLeadership[0]?.userAssessmentCriteria[0]?.personalityProfile : ""
                  }
                  className="assess_text_area"  
                  disabled
                />
              </div>

            )}
          </div>
        </div>
      </div>
      {tableDataLeadership && tableDataLeadership[0]?.userAssessmentCriteria?.length > 0 &&
      
        <div className="submit-goal-container m-b-40">
          <button onClick={()=>{handleLeadershipReview(tableDataLeadership[0]?.userAssessmentCriteria[0]); }} type="button" className="btn btn-large">
            Review Leadership Capabilities
          </button>
        </div>
      
      }


      
      </div>
      )}

      {openTab === 1 && (
        <StaffView setStaffViews={setStaffViews} wishes={wishes}  initiator={initiator} setCode={setCode} disable={disable} setWishesId={setWishesId} setWishes={setWishes}  staffViews={staffViews} />
      )}
      {openTab === 2 && <CareerPlans reviewers={reviewers} initiator={initiator} disable={disable} />}
      {openTab === 3 && (<TrainingDevelopment reviewers={reviewers} initiator={initiator} disable={disable} />)}


      <Declaration />
        {openTab < 3 &&(
          <div className="submit-goal-container">
            <button onClick={handleNext} type="button" className="btn btn-large">
              Next
            </button>
          </div>
        )}

        {openTab > 0 &&(
          <div className="submit-goal-container">
            <button onClick={handleBack} type="button" className="btn btn-large">
              Previous
            </button>
          </div>
        )}

      {isShowFormModal && (
        <ReviewForm
          details={details}
          formDetails={formDetails}
          refreshList={() => fetchAnnualData()}
          closeModal={() => setShowFormModal(false)}
        />
      )}
      {isShowPerformanceFormModal && (
        <ReviewFormAnnualPerformanceRating
          details={details}
          scores={performanceReviewerScore}
          formDetails={formDetails}
          setPerformanceScore={setPerformanceScore}
          refreshList={() => fetchPerformanceRatingData()}
          closeModal={() => setShowPerformanceFormModal(false)}
        />
      )}
      {isShowLeadershipFormModal && (
        <ReviewFormAnnualLeadershipCapabilities
          details={details}
          scores={leadershipReviewerScore}
          formDetails={formDetails}
          setLeadershipScore={setLeadershipScore}
          refreshList={() => fetchLeadershipCapabilityData()}
          closeModal={() => setShowLeadershipFormModal(false)}
        />
      )}
    </div>
  );
};

export default AnnualApprover;
