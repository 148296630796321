import React from "react";
import { useState, useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { RiAddLine, RiDeleteBin6Line } from "react-icons/ri";
import { formatWeight } from "../../pages/GoalSetting/helper";
import { feedback } from "../../utils/feedback";
import DevelopmentGapForm from "../Forms/ReviewForm/DevelopmentGapForm";
import { DeleteDevelopmentGap } from "../../network/services";

const DevelopmentGaps = ({ developmentGap, initiator, fetchDevelopmentGap, disableSubmit, disable }) => {
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [isShowFormModal, setShowFormModal] = useState(false);


  useEffect(() => {}, []);

  const handleClickEditGoal = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();
    setDetails(plan);
    setFormDetails(plan);
    setShowFormModal(true);
  };

  const handleDeleteGoal = async (id) =>{
    const response = await DeleteDevelopmentGap(id)
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentGap()
      feedback({
        title: "Successfully deleted",
        text: response?.data?.message,
        iconType: "success",
      });
    }else{
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  }

  return (
   
    <div className="pdf-section">



      {isShowFormModal && (
        <DevelopmentGapForm
          initiator={initiator}
          disable={disable}
          setDetails={setDetails}
          details={details}
          formDetails={formDetails}
          fetchDevelopmentGap={fetchDevelopmentGap}
          closeModal={() => setShowFormModal(false)}
        />
      )}
      <div className="info-box">
        <p>
          Staff and Supervisor discuss and agree competence gaps for
          development.
        </p>
        <p>
          {" "}
          Should encompass lapses in previous year and challenges demanding new
          skills in the current year.
        </p>
      </div>
      <div className="table-view">
        <div className="table-header">
          <div className="table-row">
            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Title</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Details</p>
            </div>

            <div className="th san-sherif" style={{ width: "25%" }}>
              <p>Dependency</p>
            </div>

            <div className="th san-sherif" style={{ width: "5%" }}>
              <p>Expected Outcome</p>
            </div>


            <div className="th">
              { disable &&
                <button
                  className="add-button pointer"
                  disabled={disableSubmit}
                  type="button"
                  onClick={() => (setShowFormModal(true), setFormDetails(null))}
                >
                  <RiAddLine />
                  Add Item
                </button>
              }
            </div>
          </div>
        </div>
        <div className="table-body" style={{ overflow: "scroll" }}>
          {developmentGap?.map((plan) => (
            <div className="table-row">
              <div className="td-no-inherit td-cell">{plan?.title}</div>
              <div className="td-no-inherit">
                <div>{plan?.details}</div>
              </div>
              <div className="td">
                <div className="td-no-inherit">{plan?.dependency}</div>
              </div>
              <div className="td-no-inherit">{plan?.expectedOutcome}</div>
              {/* <div className="td">20:11:2020 14:05:23PM</div> */}
              <div className="td">
                  { disable &&
                <div className="actions">
                    <button
                    type="button"
                    disabled={disableSubmit}
                    className="btn activate"
                    onClick={(e) => handleClickEditGoal(e, plan)}
                  >
                    <GrEdit />
                  </button>
                 
                  <button
                      type="button"
                      disabled={disableSubmit}
                      className="btn deactivate"
                      onClick={() =>
                      feedback(
                          {
                              title: "Delete Goal?",
                              text: "Click on DELETE to continue",
                              isDialog: true,
                              showCancelButton: true,
                              confirmButtonText: "Delete",
                              iconType: "warning",
                          },
                          () => handleDeleteGoal(plan?.id)
                      )
                  }
                >
                  <RiDeleteBin6Line />
                </button>
                </div>
                 }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevelopmentGaps;
