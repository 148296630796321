import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import EdsgLogo from "../../assets/images/edsg-logo-250.png";
import { feedback } from "../../utils/feedback";
import { BiLogOutCircle } from "react-icons/bi";
import {
  AiOutlineDashboard,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai";
import MyContext from "../../utility/Context";
import { useContext } from "react";



const Sidebar = ({ history, menuList }) => {
  const [supervisorStatus, setSupervisorStatus] = useState(false);
  const [isShowingSub, setIsShowingSub] = useState(false);
  const [isShowingSetupSub, setIsShowingSetupSub] = useState(false);
  const { setAnnualRecord, annualRecord , fromDashboard} = useContext(MyContext);


  const {
    location: { pathname },
  } = history;

  const stage = useSelector(
    (state) => state?.dashboard?.dashboardDetails?.stage
  );

  if (stage === "") {
    feedback({
      title: "Failed",
      text: "No performance stage is currently activated or the time for the current stage has elapsed. Kindly activate a stage",
      iconType: "warning",
    });
  }

  const getSupervisorStatus = () => {
    if (
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor"))
    ) {
      setSupervisorStatus(JSON.parse(Cookies.get("isSupervisor")));
    } else if (
      Cookies.get("isSupervisor") &&
      !JSON.parse(Cookies.get("isSupervisor"))
    ) {
      setSupervisorStatus(false);
    }
  };

  const handleMenuClick = () => {
    setAnnualRecord({});
  };

  // useEffect(() => {
  //   getSupervisorStatus();
  // }, []);

  return (
    <nav className="page-sidebar">
      <div className="sidebar-header">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          <li>
            <AiOutlineHome className="icon" />
            <Link to={"/admin-portal/home"} onClick={handleMenuClick}>
              <span className="title">Home</span>
            </Link>
          </li>
          <li>
            <AiOutlineDashboard className="icon" />
            <Link to={"/admin-portal/dashboard"} onClick={handleMenuClick}>
              <span className="title">Dashboard</span>
            </Link>
          </li>

          <>
            <li>
              <AiOutlineMail className="icon" />
              <>
                <Link
                  onClick={() => setIsShowingSub(!isShowingSub)} 
                  className="has-sub-menu"
                >
                  <span className="title">Performance</span>
                </Link>

                <IoIosArrowBack
                  className={`${isShowingSub ? "open" : ""} arrow`}
                />
              </>
            </li>
            {isShowingSub && (
              <ul className={`${isShowingSub ? "show" : ""} sub-menu`}>
                {/* <li className="">
                  <Link to="/admin-portal/appraisal-period">
                    Departmental Goal
                  </Link>
                </li> */}
                <li className="">
                  <Link to="/admin-portal/goal-setting" onClick={handleMenuClick}> Goal Setting</Link>
                </li>
                <li className="">
                  <Link to="/admin-portal/goal-setting-review" onClick={handleMenuClick}>
                    Goal Setting Review
                  </Link>
                </li>
                {/* <li className="">
                  <Link to="/admin-portal/quarterly-appraisal">
                    Quarterly Appraisal
                  </Link>
                </li> */}
                <li className="">
                  <Link to="/admin-portal/mid-year-review" onClick={handleMenuClick}>Mid Year Entry</Link>
                </li>
                <li className="">
                  <Link to="/admin-portal/mid-year-approver" onClick={handleMenuClick}>
                    Mid Year Review
                  </Link>
                </li>
                <li className="">
                  <Link to="/admin-portal/annual-appraisal" onClick={handleMenuClick}>
                    Annual Appraisal
                  </Link>
                </li>

                <li className="">
                  <Link to="/admin-portal/appraisal-review" onClick={handleMenuClick}>
                    Appraisal Review
                  </Link>
                </li>
              </ul>
            )}
          </>

          {Cookies.get("isAdmin") === "true" && (
            <>
              <li>
                <AiOutlineSetting className="icon" />
                <>
                  <Link
                    onClick={() => setIsShowingSetupSub(!isShowingSetupSub)}
                    className="has-sub-menu"
                  >
                    <span className="title">Setup</span>
                  </Link>

                  <IoIosArrowBack
                    className={`${isShowingSetupSub ? "open" : ""} arrow`}
                  />
                </>
              </li>
              {isShowingSetupSub && (
                <ul className={`${isShowingSetupSub ? "show" : ""} sub-menu`}>
                  <li className="">
                    <Link to="/admin-portal/appraisal-period" onClick={handleMenuClick}>
                      Activate Perf. Planning
                    </Link>
                  </li>
                  {/* <li className="">
                  <Link to="/admin-portal/goal-setting-approval-route">
                    Goal Setting Approval
                  </Link>
                </li> */}
                </ul>
              )}
            </>
          )}

          {/* <li>
            <AiOutlineUser className="icon" />
            <Link to="#">
              <span className="title">Report</span>
            </Link>
          </li> */}

          <li>
            <BiLogOutCircle className="icon" />
            <Link to={"/admin-portal/logout"}>
              <span className="title" onClick={handleMenuClick}>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ props: { title, href, icon, children }, pathname }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li className={`${pathname === href ? "active" : ""}`}>
        {icon}
        {children ? (
          <>
            <Link
              onClick={() => setIsShowingSub(!isShowingSub)}
              className="has-sub-menu"
            >
              <span className="title">{title}</span>
            </Link>
            {children && (
              <IoIosArrowBack
                className={`${isShowingSub ? "open" : ""} arrow`}
              />
            )}
          </>
        ) : (
          <Link to={href}>
            <span className="title">{title}</span>
          </Link>
        )}
      </li>
      {children && isShowingSub && (
        <ul className={`${isShowingSub ? "show" : ""} sub-menu`}>
          {children &&
            children
              .filter((item) => item.isDisplayBasedOnRoles === true)
              .map((sub) => (
                <li
                  className={`${pathname === sub.href ? "active" : ""}`}
                  key={sub.title}
                >
                  <Link to={sub.href}>{sub.title}</Link>
                </li>
              ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
