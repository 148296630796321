import React from "react";
import { Switch, Route } from "react-router";
import { pure } from "recompose";

import ProtectedRoute from "./ProtectedRoute";
import Cookies from "js-cookie";
import Dashboard from "../pages/Dashboard";
import MdaSetup from "../pages/MdaSetup";
import AppraisalDashboard from "../pages/AppraisalDashboard/AppraisalDashboard";
import AppraisalApprovers from "../pages/Settings/AppraisalApprovers/appraisalApprovers";
import AppraisalRating from "../pages/Settings/AppraisalRating/appraisalRating";
import CreateCompetencies from "../pages/Settings/CreateCompetency/createCompetency";
import QuarterlyEntry from "../pages/QuarterlyEntry/QuarterlyEntry";
import QuarterlyReview from "../pages/QuarterlyReview/QuarterlyReview";
import GoalSetting from "../pages/GoalSetting/goalSetting";
import MidYearApprover from "../pages/MidYearApprover/MidYearApprover";
import GoalSettingReview from "../pages/GoalSettingReview/goalSettingReview";
import ReviewUser from "../pages/GoalSettingReview/reviewUser";
import QuarterlyAppraisal from "../pages/QuarterlyAppraisal/quarterlyAppraisal";
import AppraisalReviewRequest from "../pages/AppraisalReviewRequest/AppraisalReviewRequest";
import AppraisalPeriod from "../pages/AppraisalPeriod/appraisalPeriod";
import MidYearReview from "../pages/MidYearReview/midYearReview";
import GoalSettingApprovalRoute from "../pages/GoalSettingApprovalRoute/GoalSettingApprovalRoute";
import AnnualAppraisal from "../pages/AnnualAppraisal/AnnualAppraisal";

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/admin-portal/" exact component={Dashboard} />
    <ProtectedRoute path="/admin-portal/mda-setup" exact component={MdaSetup} />
    <ProtectedRoute
      path="/admin-portal/appraisal-dashboard"
      exact
      component={AppraisalDashboard}
    />
     <ProtectedRoute
      path="/admin-portal/dashboard"
      exact
      component={Dashboard}
    />
    <ProtectedRoute
      path="/admin-portal/goal-setting"
      exact
      component={GoalSetting}
    />
    <ProtectedRoute
      path="/admin-portal/goal-setting-review"
      exact
      component={GoalSettingReview}
    />
    <ProtectedRoute
      path="/admin-portal/goal-setting-review/user"
      exact
      component={ReviewUser}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-review"
      exact
      component={AppraisalReviewRequest}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-period"
      exact
      component={AppraisalPeriod}
    />
    <ProtectedRoute
      path="/admin-portal/quarterly-review"
      exact
      component={QuarterlyReview}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-approvers"
      exact
      component={AppraisalApprovers}
    />
    <ProtectedRoute
      path="/admin-portal/quarterly-entry"
      exact
      component={QuarterlyEntry}
    />
    <ProtectedRoute
      path="/admin-portal/quarterly-appraisal"
      exact
      component={QuarterlyAppraisal}
    />
    <ProtectedRoute
      path="/admin-portal/annual-appraisal"
      exact
      component={AnnualAppraisal}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-rating"
      exact
      component={AppraisalRating}
    />
    <ProtectedRoute
      path="/admin-portal/mid-year-review"
      exact
      component={MidYearReview}
    />

<ProtectedRoute
      path="/admin-portal/mid-year-approver"
      exact
      component={MidYearApprover}
    />



    <ProtectedRoute
      path="/admin-portal/create-competency"
      exact
      component={CreateCompetencies}
    />
    <ProtectedRoute
      path="/admin-portal/goal-setting-approval-route"
      exact
      component={GoalSettingApprovalRoute}
    />
    s
    <ProtectedRoute
      path="/admin-portal/logout"
      exact
      component={() => {
        // Cookies.remove("userId");
        // Cookies.remove("_Role");
        // Cookies.remove("_Email");
        // Cookies.remove("profile");
        // Cookies.remove("_FullName");
        // Cookies.remove("firstName");
        // Cookies.remove("lastName");
        // Cookies.remove("fullName");
        // Cookies.remove("profilePicture");
        // Cookies.remove("isAdmin");
        // Cookies.remove("loginTime");
        // // Cookies.remove("Edogov_Appraisal_AppToken");
        // Cookies.remove("_APIBaseURL");
        // Cookies.remove("tokenExist");
        // // Cookies.remove("Edogov_Appraisal_AccessToken");
        // localStorage.clear();
        window.location.assign(Cookies.get("homeLink"));
      }}
    />
    <ProtectedRoute
      path="/admin-portal/home"
      exact
      component={() => {
        Cookies.remove("userId");
        Cookies.remove("_Role");
        Cookies.remove("_Email");
        Cookies.remove("profile");
        Cookies.remove("_FullName");
        Cookies.remove("firstName");
        Cookies.remove("lastName");
        Cookies.remove("fullName");
        Cookies.remove("profilePicture");
        Cookies.remove("isAdmin");
        Cookies.remove("loginTime");
        Cookies.remove("_APIBaseURL");
        Cookies.remove("tokenExist");

        localStorage.clear();
        window.location.assign(Cookies.get("homeLink"));
      }}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
