import axios from "axios";
import Cookies from "js-cookie";
import { ModuleName } from "../utils/globalVariables";

const Http = axios.create({
  baseURL: `https://edogoverp.com/performanceapi`,
});

Http.interceptors.request.use(
  function (config) {
    const appToken = Cookies.get(ModuleName + "_AppToken");

    config.headers.Authorization = appToken ? `Bearer ${appToken}` : "";
    return config;
  },
  function (error) {
    // console.log("error: ", error?.response);
    // console.log(window.location);
    // if (error.response.status === 401) {
    // }
    return Promise.reject(error);
  }
);

export default Http;
