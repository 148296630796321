import Cookies from "js-cookie";
import React from "react";
import TextInput from "../../inputs/TextInput";

import "./declaration.scss";

const Declaration = ({ hint }) => {
  return (
    <div className="declaration-container">

     {hint && <div className="declaration">
        <h3>Hint</h3>
        <p>
          Individual Staff states own wishes for career and others.
        </p>
      </div>}

      <div className="declaration">
        <h3>Declaration</h3>
        <p>
          I hereby declare that the Report has been written with the highest
          sense of responsibility and to the best of my judgement and with due
          regard to my conscience
        </p>
      </div>

      <div className="signature">
        <h3>Signature</h3>
        <img src={Cookies.get("signature")} alt="signature" style={{borderBottom:" 1px solid grey", paddingBottom:"8px", width:"100%", height:"80px", objectFit:"contain"}}/>
      </div>
    </div>
  );
};

export default Declaration;
