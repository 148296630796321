import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import LocationDetails from "../../../components/layouts/LocationDetails";
import CreateCompetencyCategoryForm from "../../../components/layouts/createCompetencyCategoryForm";
import { fetchCompetencyCategory } from "../../../redux/actions/actions";
import CreateCompetencyForm from "../../../components/layouts/createCompetencyForm";
import { feedback } from "../../../utils/feedback";
import {
  delCompetency,
  getCompetencyByCategoryId,
  postCompetency,
  putCompetency,
} from "../../../network/services";
import IsLoading from "../../../components/common/isLoading";
import EditCompetencyForm from "../../../components/layouts/editCompetencyForm";

const CreateCompetencies = ({ history, details = {} }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCompetency, setSelectedCompany] = useState(null);
  const [isShowCreateCompetencyForm, setIsShowCreateCompetencyForm] =
    useState(false);
  const [isShowEditCompetencyForm, setIsShowEditCompetencyForm] =
    useState(false);
  const [competencies, setCompetencies] = useState([]);
  const [isFetchingCompetencies, setIsFetchingCompetencies] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Employee Profile";
  }, []);

  const competencyCategory = useSelector(
    (state) => state?.competencyReducer.competencyCategory
  );

  const formatCompetencyCategory = (list) => {
    const result = list.map((item) => {
      return {
        name: item?.categoryName,
        value: item?.id,
      };
    });

    return result;
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleAddCompetency = () => {
    if (!selectedCategory) {
      feedback({
        title: "Warning",
        text: "Kindly select a category first",
        iconType: "warning",
      });
    } else {
      setIsShowCreateCompetencyForm(true);
    }
  };

  const fetchCompetencies = async () => {
    setIsFetchingCompetencies(true);
    try {
      const response = await getCompetencyByCategoryId(selectedCategory);
      if (response?.status === 200) {
        setCompetencies(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingCompetencies(false);
  };

  const handleCreate = async (data) => {
    const payload = {
      categoryID: Number(selectedCategory),
      priority: Number(data?.priority),
      competency: data?.competency,
    };
    try {
      const response = await postCompetency(payload);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        setIsShowCreateCompetencyForm(false);
        fetchCompetencies();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleEdit = async (data) => {
    const payload = {
      id: data?.id,
      categoryID: Number(data?.categoryID),
      priority: Number(data?.priority),
      competency: data?.competency,
    };
    try {
      const response = await putCompetency(payload);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        setIsShowEditCompetencyForm(false);
        fetchCompetencies();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleDelete = async (data) => {
    const id = data?.id;

    try {
      const response = await delCompetency(id);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchCompetencies();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleEditCompetency = (item) => {
    setIsShowEditCompetencyForm(true);
    setSelectedCompany(item);
  };

  useEffect(() => {
    dispatch(fetchCompetencyCategory());
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchCompetencies();
    }
  }, [selectedCategory]);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Setup Competencies</h2>
        <LocationDetails />
      </div>

      <div className="w-100 flex space-between m-t-20 flex-v-center custom-header">
        <select onChange={handleCategoryChange} value={selectedCategory}>
          <option>Select Item...</option>
          {formatCompetencyCategory(competencyCategory).map((item) => (
            <option key={item?.value} value={item?.value}>
              {item?.name}
            </option>
          ))}
        </select>
        <button
          className="add-button pointer"
          type="button"
          onClick={() => setShowFormModal(true)}
        >
          <RiAddLine />
          Add/Edit Competency Category
        </button>
      </div>

      {isFetchingCompetencies ? (
        <IsLoading />
      ) : (
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              <div className="th">
                <p className="san-sherif">Competencies</p>
              </div>
              <div className="th">
                <p className="san-sherif">Sequence</p>
              </div>

              <div className="th">
                <p className="san-sherif">Last Updated By</p>
              </div>
              <div className="th">
                <button
                  className="add-button pointer"
                  type="button"
                  onClick={handleAddCompetency}
                >
                  <RiAddLine />
                  Add Competency
                </button>
              </div>
            </div>
          </div>
          <div className="table-body">
            {competencies.map((item) => (
              <div className="table-row" key={item.id}>
                <div className="td">{item?.competency}</div>
                <div className="td">{item?.priority}</div>
                <div className="td">
                  {item?.modifiedBy} {item?.date}
                </div>
                <div className="td actions">
                  <button
                    type="button"
                    className="pointer"
                    onClick={() => handleEditCompetency(item)}
                  >
                    <BiEditAlt />
                  </button>
                  <button
                    type="button"
                    className="del pointer"
                    onClick={() => handleDelete(item)}
                  >
                    <GiTrashCan />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showFormModal && (
        <CreateCompetencyCategoryForm
          closeModal={() => setShowFormModal(false)}
        />
      )}

      {isShowCreateCompetencyForm && (
        <CreateCompetencyForm
          closeModal={() => setIsShowCreateCompetencyForm(false)}
          handleCreate={handleCreate}
        />
      )}

      {isShowEditCompetencyForm && (
        <EditCompetencyForm
          closeModal={() => setIsShowEditCompetencyForm(false)}
          handleEdit={handleEdit}
          selectedCompetency={selectedCompetency}
        />
      )}
    </div>
  );
};

export default CreateCompetencies;
