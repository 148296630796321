import React, { useEffect, useState } from "react";

import { IoMdClose } from "react-icons/io";
// import { MultiSelect } from "react-multi-select-component";
import SelectInput from "../inputs/SelectInput";
import { getAgency, getStaff } from "../../network/services";
import IsLoading from "../common/isLoading";
import TextInput from "../inputs/TextInput";
import { feedback } from "../../utils/feedback";

const ApproverRouteForm = ({ closeModal, handleSave, isLoading }) => {
  const [isFetchingAgency, setIsFetchingAgency] = useState(false);
  const [isFetchingStaff, setIsFetchingStaff] = useState(false);
  const [inputData, setInputData] = useState({
    agencyId: "",
    supervisorId: "",
    priority: "",
  });
  const [agency, setAgency] = useState([]);
  const [staff, setStaff] = useState([]);

  const handleChangeAgency = (e) => {
    const value = e.target.value;
    setInputData((prev) => ({ ...prev, agencyId: value }));
    fetchStaff(value);
  };

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const fetchAgency = async () => {
    setIsFetchingAgency(true);
    try {
      const response = await getAgency();
      if (response?.status === 200) {
        const result = response?.data?.map((item) => {
          return {
            name: item?.name,
            value: item?.id,
          };
        });
        setAgency(result);
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingAgency(false);
  };

  const fetchStaff = async (mdaId) => {
    setIsFetchingStaff(true);
    try {
      const response = await getStaff(mdaId);

      if (response?.status === 200) {
        const result = response?.data?.map((item) => {
          return {
            name: `${item?.firstName} ${item?.lastName}`,
            value: item?.id,
          };
        });
        setStaff(result);
      }
    } catch (error) {}
    setIsFetchingStaff(false);
  };

  useEffect(() => {
    fetchAgency();
  }, []);

  if (isFetchingAgency || isFetchingStaff) {
    return <IsLoading />;
  }

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div
        className="modal-box max-w-400"
        style={{ overflowY: "auto", height: "50vh" }}
      >
        <form
          id="modal-form"
          onSubmit={(e) => {
            handleSave(e, inputData);
            clearForm();
          }}
        >
          <div className="content">
            <SelectInput
              className="w-100 m-b-10"
              name="priority"
              label="Agency"
              type="number"
              options={agency}
              onChange={(e) => handleChangeAgency(e)}
              value={inputData?.agencyId || ""}
            />

            <SelectInput
              className="w-100 m-b-10"
              name="supervisorId"
              label="Staff"
              type="text"
              options={staff}
              onChange={handleChange}
              value={inputData?.supervisorId || ""}
            />

            <TextInput
              className="w-100 m-b-10"
              name="priority"
              label="Priority"
              type="number"
              onChange={handleChange}
              value={inputData?.priority || ""}
            />

            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-large w-100"
            >
              Save Record
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApproverRouteForm;
