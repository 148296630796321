import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import SelectInput from "../inputs/SelectInput";
import { v4 as uuidv4 } from "uuid";
import TextInput from "../inputs/TextInput";

const GoalForm = ({ closeModal, handleSave, isLoading }) => {
  const [inputData, setInputData] = useState();
  const [kpiList, setKpiList] = useState([]);

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const handleChangeKpi = (e, item) => {
    const value = e.target.value;
    const kpiInQuestion = kpiList?.find((kpi) => kpi?.id === item?.id);
    const indexOfKpi = kpiList?.indexOf(kpiInQuestion);
    const newList = [...kpiList];

    newList[indexOfKpi] = {
      id: item?.id,
      performanceIndicator: value,
    };

    setKpiList(newList);
  };

  const schedule = [
    {
      name: "Weekly",
      value: "Weekly",
    },
    {
      name: "Bi-Weekly",
      value: "Bi-Weekly",
    },
    {
      name: "Monthly",
      value: "Monthly",
    },
    {
      name: "Bi-Monthly",
      value: "Bi-Monthly",
    },
  ];

  const handleAddKpi = (e) => {
    e.preventDefault();

    return setKpiList((prev) => [
      ...prev,
      { id: uuidv4(), performanceIndicator: "" },
    ]);
  };

  const handleRemoveKpi = (item) => {
    const newList = kpiList?.filter((kpi) => kpi?.id !== item.id);
    setKpiList(newList);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div
        className="modal-box max-w-400"
        style={{ overflowY: "auto", height: "50vh" }}
      >
        <form
          id="modal-form"
          onSubmit={(e) => {
            handleSave(e, { inputData, kpiList: kpiList });
            clearForm();
          }}
        >
          <div className="content">
            <TextInput
              className="w-100 m-b-10"
              name="goal"
              label="Goals/Target"
              type="text"
              onChange={handleChange}
              value={inputData?.goal || ""}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <TextInput
                customStyle={{ width: "45%" }}
                className="m-b-10"
                name="weight"
                label="Weight"
                type="number"
                minDate={1}
                onChange={handleChange}
                value={inputData?.weight || ""}
              /> */}
              <TextInput
                // customStyle={{ width: "45%" }}
                className="m-b-10 w-100"
                name="priority"
                label="Priority"
                type="number"
                // options={[1, 2, 3, 4, 5]}
                onChange={handleChange}
                value={inputData?.priority || ""}
                
              />
            </div>

            {/* <SelectInput
              className="w-100 m-b-10"
              name="schedule"
              label="Review Schedule"
              type="text"
              options={schedule}
              onChange={handleChange}
              value={inputData?.schedule || ""}
            /> */}

            <span
              style={{
                fontSize: "0.75rem",
                marginTop: "0.5rem",
                color: "#999",
              }}
            >
              Key Performance Indicators (KPIs)
            </span>
            <div style={{ width: "100%" }}>
              {kpiList?.map((item, index) => (
                <div key={index} style={{ display: "flex", marginBottom: '10px' }}>
                <TextInput
                  key={index}
                  className="w-100 m-b-10"
                  name={`kpi+${index}`}
                  type="text"
                  value={item?.performanceIndicator || ""}
                  onChange={(e) => handleChangeKpi(e, item)}
                />
                <button
                    type="button"
                    className="btn btn-small m-l-20 m-b-10"
                    onClick={() => handleRemoveKpi(item)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <button className="btn btn-large" onClick={handleAddKpi}>
                Add
              </button>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-large w-100"
            >
              Save Record
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoalForm;
