import Http from "./index";

export const getPerformanceStages = () => {
  return Http.get("/api/PerformanceStage");
};

export const putPerformanceStages = (data) => {
  return Http.put("/api/PerformanceStage", data);
};

export const getDashboardDetails = () => {
  return Http.get("/Dashboard/details/1");
};

export const getApprovalSetup = () => {
  return Http.get("/approvalsetup");
};

export const getRoles = () => {
  return Http.get("/roles");
};

export const getStaffByRoles = (roleId) => {
  return Http.get(`/staffbyroles/${roleId}`);
};

export const getAgency = () => {
  return Http.get(`/api/Agency`);
};

export const getStaff = (mdaId) => {
  return Http.get(`/api/Agency/staff/${mdaId}`);
};

export const getApproverRoute = (performanceId) => {
  return Http.get(`/Appraisal/myapprovalroute/${performanceId}`);
};

export const getAppraisalRatings = () => {
  return Http.get("/api/AppraisalRatingSetup");
};

export const postAppraisalRatings = (data) => {
  return Http.post("/api/AppraisalRatingSetup", data);
};

export const delAppraisalRatings = (id) => {
  return Http.delete(`/api/AppraisalRatingSetup?id=${id}`);
};

export const putAppraisalRatings = (data) => {
  return Http.put("/api/AppraisalRatingSetup", data);
};

export const getCompetencyCategory = () => {
  return Http.get("/api/Competency/category");
};

export const postCompetencyCategory = (data) => {
  return Http.post("/api/Competency/category", data);
};

export const deleteCompetencyCategory = (id) => {
  return Http.delete(`/api/Competency/category?id=${id}`);
};

export const postCompetency = (data) => {
  return Http.post(`/api/Competency/competency`, data);
};

export const putCompetency = (data) => {
  return Http.put(`/api/Competency/competency`, data);
};

export const delCompetency = (id) => {
  return Http.delete(`/api/Competency/competency?id=${id}`);
};

export const getCompetencyByCategoryId = (categoryId) => {
  return Http.get(`/api/Competency/competency/bycategory/${categoryId}`);
};

export const getPerformanceHeader = (periodId) => {
  return Http.get(`/PerformanceGoalDetail/performanceheader/${periodId}`);
};

export const postInitiatePerformance = (periodId) => {
  return Http.post(`/PerformanceGoalDetail/initiateperformance/${periodId}`);
};

export const getPerformanceGoalsData = (id) => {
  return Http.get(`/PerformanceGoalDetail/goals/${id}`);
};

export const deletePerformanceGoalsData = (id) => {
  return Http.delete(`/PerformanceGoalDetail/deletegoal/${id}`);
};

export const postApproverRoute = (data) => {
  return Http.post(`/Appraisal/setapprovalroute`, data);
};

export const getApprovalRoute = (id) => {
  return Http.get(`/Appraisal/myapprovalroute/${id}`);
};

export const postPerformanceGoal = (data) => {
  return Http.post(`/PerformanceGoalDetail/setgoal`, data);
};

export const putPerformanceGoal = (data) => {
  return Http.put(`/PerformanceGoalDetail/updategoal`, data);
};

export const putGoal = (data) => {
  return Http.put(`/PerformanceGoalDetail/updatemygoal`, data);
};

export const getApprovalStaffList = (id) => {
  return Http.get(`/PerformanceGoalDetail/approvalprogress`);
};

// Stopped here

export const postInitiatePerformanceApproval = (data) => {
  return Http.post(`/PerformanceGoalDetail/initiateperformanceapproval`, data);
};

export const getPerformanceDetailApprovalProgress = (id) => {
  return Http.get(`/api/PerformanceGoalDetail/approvalprogress/${id}`);
};

export const getCurrentPeriod = () => {
  return Http.get(`/api/AppraisalPeriod/currentperiod`);
};

export const getAllPeriod = () => {
  return Http.get(`/AppraisalPeriod/allperiods`);
};

export const getPerformanceDetailApprovalStafflist = (currentPeriodId) => {
  return Http.get(
    `/api/PerformanceGoalDetail/approvalstafflist/${currentPeriodId}`
  );
};

export const getReviewGoals = (id) => {
  return Http.get(`/PerformanceGoalDetail/goalsdata/${id}`);
};

export const postApprovePerformance = (data) => {
  return Http.put(`/PerformanceGoalDetail/approveperformance`, data);
};

export const putSubmitMyGoal = (data) => {
  return Http.put(`/PerformanceGoalDetail/submitmygoal`, data);
};

export const putUpdateGoal = (data) => {
  return Http.put(`/PerformanceGoalDetail/updategoal`, data);
};

export const getPerformanceGoalDetails = (data) => {
  return Http.get(`/api/PerformanceGoalDetail/goals`);
};

export const getMyPerformanceApprovalRoute = () => {
  return Http.get("/api/PerformanceGoalDetail/myapprovalroute");
};

export const getPerformanceApprovalProgress = () => {
  return Http.get(
    "/api/PerformanceGoalDetail/approvalprogress/{performanceid}"
  );
};

export const postMidYearMultiple = (data) => {
  return Http.post("/MidYear/CreateMultiple", data);
};

export const putUpdateMidYearMultiple = (data) => {
  return Http.put("/MidYear/UpdateMultiple", data);
};

export const getMidYearData = (PerformanceId) => {
  return Http.get(`/MidYear/Get/Performance/${PerformanceId}`);
};

export const putApproveMidYear = (data) => {
  return Http.put(`/MidYear/Approve`, data);
};

export const getApproverMidYearQueue = () => {
  return Http.get("/MidYear/Approver/GetGoalsOnMyQueue");
};

export const putReviewMidYear = (data) => {
  return Http.put(`/MidYear/Review`, data);
};

export const approvePerformance = () => {
  return Http.get(
    "​/api​/PerformanceGoalDetail​/initiateperformanceapproval​/"
  );
};

export const getQuarterPerformance = () => {
  return Http.get("/api/MidYear/performance/{performanceid}");
};



export const getGoals = () => {
  return Http.get("/api/PerformanceGoalDetail/supervisor/goals");
};

export const submitScore = (data) => {
  return Http.put("/api/MidYear/updatescore", data);
};

export const submitSupervisorScore = (data) => {
  return Http.put("/api/MidYear/updatescore/supervisor", data);
};

export const submit = (data) => {
  return Http.put(`/api/MidYear/submitforapproval/${data}`);
};

export const submitSupervisor = (data, stage, body) => {
  return Http.put(`/api/MidYear/supervisorapproval/${data}/${stage}`, body);
};

export const supervisorDecline = (performanceid, stage, body) => {
  return Http.put(
    `/api/MidYear/supervisor/decline/${performanceid}/${stage}`,
    body
  );
};

export const submitDevelopmentNeed = (data) => {
  return Http.post(`/api/MidYear/developmentneed`, data);
};

export const updateDevelopmentNeed = (data) => {
  return Http.put(`/api/MidYear/developmentneed`, data);
};

export const deleteDevelopmentNeed = (data) => {
  return Http.delete(`/api/MidYear/developmentneed/${data}`);
};

export const getDevelopmentNeed = (data) => {
  return Http.get(`/api/MidYear/developmentneed/${data}`);
};

export const getExpectations = (performanceId, mdaId) => {
  return Http.get(
    `/api/PerformanceGoalDetail/expectations/${performanceId}/mdaid?mdaid=${mdaId}`
  );
};

export const getAnnualData = (PerformanceId) => {
  return Http.get(`/Annual/Get/Performance/${PerformanceId}`);
};

export const postAnnualMultiple = (data) => {
  return Http.post("/Annual/CreateMultiple", data);
};

export const putAnnualMultiple = (data) => {
  return Http.put("/Annual/UpdateMultiple", data);
};

export const putAnnualSubmitMultiple = (data) => {
  return Http.put("/Annual/Submit", data);
};

export const getApproverAnnualQueue = () => {
  return Http.get("/Annual/Approver/GetGoalsOnMyQueue");
};

export const getAppraisalRecordByYear = (PeriodId) => {
  return Http.get(`/Annual/GetMyGoals/Period/${PeriodId}`);
};

export const putApproveAnnual = (data) => {
  return Http.put(`/Annual/Approve`, data);
};

export const putReviewAnnual = (data) => {
  return Http.put(`/Annual/Review`, data);
};

export const getPerformanceAssessment = () => {
  return Http.get("/PerformanceRating/GetMyAssessment");
};

export const getPerformanceAssessmentByUser = (UserId) => {
  return Http.get(`/PerformanceRating/Get/User/${UserId}`);
};

export const postPerformanceRatingMultiple = (data) => {
  return Http.post("/PerformanceRating/CreateMultiple", data);
};

export const putPerformanceRatingMultiple = (data) => {
  return Http.put("/PerformanceRating/UpdateMultiple", data);
};

export const putPerformanceRatingSubmitMultiple = (data) => {
  return Http.put("/PerformanceRating/SubmitMultiple", data);
};

export const getApproverPerformanceRatingOnQueue = () => {
  return Http.get("/PerformanceRating/Approver/GetAssessmentOnMyQueue");
};

export const putApprovePerformanceRating = (data) => {
  return Http.put(`/PerformanceRating/Approve`, data);
};

export const putReviewPerformanceRating = (data) => {
  return Http.put(`/PerformanceRating/Review`, data);
};

export const getLeadershipAssessment = () => {
  return Http.get("/LeadershipCapability/GetMyAssessment");
};

export const getLeadershipAssessmentByUser = (UserId) => {
  return Http.get(`/LeadershipCapability/Get/User/${UserId}`);
};

export const postLeadershipCapabilityMultiple = (data) => {
  return Http.post("/LeadershipCapability/CreateMultiple", data);
};

export const putLeadershipCapabilitySubmitMultiple = (data) => {
  return Http.put("/LeadershipCapability/SubmitMultiple", data);
};

export const putLeadershipCapabilityMultiple = (data) => {
  return Http.put("/LeadershipCapability/UpdateMultiple", data);
};

export const getApproverLeadershipCapabilityOnQueue = () => {
  return Http.get("/LeadershipCapability/Approver/GetAssessmentOnMyQueue");
};

export const putApproveLeadershipCapability = (data) => {
  return Http.put(`/LeadershipCapability/Approve`, data);
};

export const putReviewLeadershipCapability = (data) => {
  return Http.put(`/LeadershipCapability/Review`, data);
};

export const getEmployeeWish = (userId) => {
  return Http.get(`/Wishes/Get/User/${userId}`)
}

export const createEmployeeWish = (data) => {
  return Http.post(`/Wishes/Create`, data);
};

export const putEmployeeWish = (data) => {
  return Http.put(`/Wishes/Update`, data);
};

export const createShortPlan = (data) => {
  return Http.post(`/CareerPlan/ShortPlan/Create`, data);
};

export const DeleteCareerPlan = (Id) => {
  return Http.delete(`/CareerPlan/${Id}`);
};

export const createLongPlan = (data) => {
  return Http.post(`/CareerPlan/LongPlan/Create`, data);
};

export const updatePlan = (data) => {
  return Http.put(`/CareerPlan/Update`, data);
};

export const getShortTermGoal = (userId) => {
  return Http.get(`/CareerPlan/Get/ShortPlan/User/${userId}`);
};

export const getLongTermGoal = (userId) => {
  return Http.get(`/CareerPlan/Get/LongPlan/User/${userId}`);
};

export const createDevelopmentGap = (data) => {
  return Http.post(`/DevelopmentGap/Create`, data);
};

export const DeleteDevelopmentGap = (Id) => {
  return Http.delete(`/DevelopmentGap/${Id}`);
}

export const updateDevelopmentGap = (data) => {
  return Http.put(`/DevelopmentGap/Update`, data);
};

export const getDevelopmentGap = (userId) => {
  return Http.get(`/DevelopmentGap/Get/User/${userId}`);
};

export const createDevelopmentActionPlan = (data) => {
  return Http.post(`/DevelopmentActionPlan/Create`, data);
};

export const DeleteDevelopmentActionPlan = (Id) => {
  return Http.delete(`/DevelopmentActionPlan/${Id}`);
};

export const updateDevelopmentActionPlan = (data) => {
  return Http.put(`/DevelopmentActionPlan/Update`, data);
};

export const getDevelopmentActionPlan = (userId) => {
  return Http.get(`/DevelopmentActionPlan/Get/User/${userId}`);
};
