import React from "react";

import userIcon from "../../../../assets/images/user.png";

import "./approverHeader.scss";

const ApproverHeader = ({ withBorder = false, withDetails = false , index, approver}) => {
  return (
    <div className={`approver ${withBorder ? "withborder" : null}`}>
      <div className={`details-header ${withDetails ? "border-bottom" : null}`}>
        <button>Review: {index + 1}</button>
        <div className="user-details-container">
          <div>
            <h3 className="user-name">{approver?.approver}</h3>
            <span className="title">{`${approver.role}, ${approver?.approverMDA}`}</span>
          </div>
          <img src={approver?.profilePicture} className="circle-pic"/>
        </div>
      </div>

      {/* <hr></hr> */}

      {withDetails && (
        <div className="details-container">
          <div className="row no-gutters t-header">
            <div className="col-3 t-cell">KPIs</div>
            <div className="col-3 t-cell">Review Schedule</div>
            <div className="col-3 t-cell">Priority</div>
            <div className="col-3 t-cell">Weight</div>
          </div>
          <hr></hr>
          <div className="row no-gutters t-header">
            <div className="col-3 c-column">
              - Preparation and submission of appropriation
            </div>
            <div className="col-3 c-column">Weekly</div>
            <div className="col-3 c-column">1</div>
            <div className="col-3 c-column">30%</div>
          </div>

          <div className="comments-section">
            <h3>Additonal Comments</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptatibus voluptatem, necessitatibus fugiat facilis dicta ipsam
              corrupti enim assumenda vel ut omnis libero, minus commodi eaque
              sint nihil officia perferendis adipisci?
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproverHeader;
