import React from "react";
import ReactDOM from "react-dom";

import "./assets/css/pages-icons.css";
import "./assets/css/pages-sidebar.css";
import "./assets/css/index.css";
import "./assets/css/general.css";

import AppWrapper from "./App";
import reportWebVitals from "./reportWebVitals";
import { MyContextProvider } from "./utility/Context";

ReactDOM.render(
  <React.StrictMode>
    <MyContextProvider>
      <AppWrapper />
    </MyContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
