import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import LocationDetails from "../../components/layouts/LocationDetails";
import { sampleGoalListReview } from "./sampleGoalReview";
import CollapsibleUserGoals from "./Components/collapsibleUserGoals";
import userIcon from "../../assets/images/user.png";
import queryString from 'query-string';
import { getDashboardDetails, getPerformanceGoalsData, getReviewGoals, postApprovePerformance, putPerformanceGoal } from "../../network/services";
import "./reviewUser.scss";
import ApproverHeader from "./Components/approverHeader/approverHeader";
import Cookies from "js-cookie";
import { feedback } from "../../utils/feedback";
import { useSelector } from "react-redux";


const ReviewUser = () => {
  const history = useHistory();

  const [performanceDetails, setPerformanceDetails] = useState([])
  const [review, setReview] = useState("");


  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const getGoalData = async (id) => {
    const res = await getPerformanceGoalsData(id);
    setPerformanceDetails(res.data.data);
  }

  const sendForReview = async (data) => {
      console.log(data)
      const submitData = {
        comment: "Review",
        performanceID: Number(parsed.id),
        approvalStatus: 3
      }
      const res = await postApprovePerformance(submitData);
      console.log(res)
      if (res.data.code === 1) {
        feedback({
          title: "Success",
          text: res?.data?.message,
          iconType: "success",
        });
        history.push("/admin-portal/goal-setting-review");
      }
      else {
        feedback({
          title: "Error",
          text: res?.data?.message,
          iconType: "error",
        });
      }
  }

  const putApproverReview = async (data) => {
    const res = await putPerformanceGoal({
      id: data.id,
      performanceID: data.performanceID,
      periodID: dashboardDetails?.periodId,
      staffID: data.performancePlanApprovers[0].approverId,
      performanceIndicator: data.kpis,
      objective: data.objective,
      weight: data.weight,
      priority: data.priority,
      origin: "string",
      reviewSchedule: data.reviewSchedule,
      goalComment: review,
      goalStatus: 2
    });
    if (res.data.code) {
      getGoalData(Number(parsed.id))
    }
    console.log(res);
  }

  console.log(performanceDetails)
  console.log(Cookies.get("fullName"))

  const location = useLocation();
  const parsed = queryString.parse(location.search);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - MDA Appraisal Dashboard";
    getGoalData(Number(parsed.id))
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Performance Review</h2>
        </div>
        <LocationDetails />
      </div>

      <div className="flex space-between m-b-20 w-100 ">
      <Link to={"/admin-portal/goal-setting-review/"}><button className="btn w-100 flex_basis_30" >Back to Approval</button></Link>

        <button className="btn w-100 flex_basis_30" onClick={sendForReview}>Send Back For Review</button>
      </div>
      <div className="custom-collapsible-table ">
        <div className="custom-collapsible-table-header">
          <div className="col-3  c-column">Target/Goals/Commitment</div>
          <div className="col-3 no-inherit c-column">KPIs</div>
          {/* <div className="col-2 c-column">Review Schedule</div> */}
          <div className="col-3  c-column">Priority</div>
          {/* <div className="col-2 c-column">Weight</div> */}
          <div className="col-3  c-column">Last Update</div>
        </div>
        {Array.isArray(performanceDetails) && performanceDetails?.map((data, index) => (
          <CollapsibleUserGoals
            key={index}
            columns={
              <div className="row pointer ">
                <div className="col-3 ">{data?.objective}</div>
                <div className="col-3  no-inherit ">{data?.kpis?.map((item) => (<div>{`-${item.performanceIndicator}`}</div>))}</div>
                {/* <div className="col-2">{data?.reviewSchedule}</div> */}
                <div className="col-3">{data?.priority}</div>
                {/* <div className="col-2">{`${data?.weight}%`}</div> */}
                <div className="col-3 ">{data?.date}</div>
              </div>
            }
          >
            {Array.isArray(data?.performancePlanApprovers) && data?.performancePlanApprovers?.map((approver, idx) => (
              <div className="row no-gutters">
                <div
                  className="col-2"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                ></div>
                <div
                  className="col-10"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <ApproverHeader index={idx} data={data} approver={approver} />
                  <hr className="divider"></hr>
                  <div className="row no-gutters">
                    <div className="col-4">{data?.kpis?.map((item) => (<div>{`-${item?.performanceIndicator}`}</div>))}</div>
                    {/* <div className="col-3">{data?.reviewSchedule}</div> */}
                    <div className="col-4">{data?.priority}</div>
                    <div className="col-4">{`${data?.date}`}</div>
                  </div>
                  <div className="additional-comments-container">
                    {
                       console.log(approver?.approver)
                    }
                    <textarea
                      rows={10}
                      value={approver?.comment ? approver?.comment : undefined}
                      disabled={approver?.comment !== null && approver?.comment !== '' && approver?.comment !== undefined && approver?.approver !== Cookies.get("fullName")}
                      placeholder="Additional Comments: "
                      onChange={(e) => setReview(e.target.value)}
                    />{
                     
                      (approver?.comment === null || approver?.comment === "" && approver?.comment !== undefined && approver?.approver === Cookies.get("fullName")) && <button type="button" onClick={() => putApproverReview(data)} className="btn btn-large">
                        Submit Review
                      </button>}
                  </div>
                  <div>
                    {/* <ApproverHeader withBorder />
                  <ApproverHeader withDetails withBorder /> */}
                  </div>
                </div>
              </div>
            ))

            }
          </CollapsibleUserGoals>
        ))}
      </div>
    </div>
  );
};

export default ReviewUser;
