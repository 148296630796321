import React from "react";

import "./collapsibleUserGoals.scss";

const CollapsibleUserGoals = ({ columns, children }) => {
  return (
    <div className="">
      <details>
        <summary>{columns}</summary>
        <div className="collapsible-content">{children}</div>
      </details>
    </div>
  );
};

export default CollapsibleUserGoals;
