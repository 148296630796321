import React from "react";
import { getEmployeeWish, putEmployeeWish } from "../../network/services";
import { feedback } from "../../utils/feedback";
import { useState, useEffect } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import EdsgLogo from '../../assets/images/edsg-logo-250.png';
import Cookies from "js-cookie";



const DownloadStaffView = ({ closeModal, reviewers, wishes, setWishes, setWishesId, setStaffViews, initiator, disable, setCode }) => {

  const [payload, setPayload] = useState()
  const pdfExportComponent = React.useRef(null);
  const [isShowDownloadModal, setShowDownloadModal] = useState(false);





  console.log(wishes)
  console.log('diasble value:', disable)
  return (
    <div>

      <div className="flex space-between">
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={closeModal}
          >
            Back to record
          </button>
        </div>
        <div className="mb5 flex justify-content-center m-b-20 m-t-40">
          <button
            className="btn btn-large"
            onClick={() => {
              if (pdfExportComponent?.current) {
                pdfExportComponent?.current.save();
              }
            }}
          >
            Download PDF
          </button>
        </div>
      </div>

      <PDFExport
        paperSize="A2"
        margin="2mm"
        fileName={'Staff View & Wish.pdf'}
        ref={pdfExportComponent}
        // paperSize="A3"
        // margin="2mm"
        scale={0.8}
        keepTogether=".p"
        forcePageBreak=".page-break"
      // fileName={`${pdfName}.pdf`}
      // ref={pdfExportComponent}
      >

        {!isShowDownloadModal && (
          <div className="pdf-section">
            <div className="center-text">
              <img src={EdsgLogo} alt="logo" className="brand" width="150" />
              <h3 className="m-t-10 m-b-20">
                Edo State 2023 Annual Appraisal Staff Wishes
                {/* <span id="currentYear"></span> */}
              </h3>
              <h3 className="m-t-10 m-b-20 bold-text">
                {Cookies.get('fullName').toUpperCase()}
              </h3>
            </div>
            <div className="flex space-between">

            </div>
            <h4>Staff View & Well Wishes</h4>
            <p>Individual Staff states own wishes for career and others.</p>
            <textarea
              disabled={!disable}
              value={wishes}

              className="staff_text_area"
            />
          </div>
        )}
      </PDFExport>
      {/* {isShowDownloadModal &&(
        <DownloadLeadershipCapability
        leadershipacapabilities={leadershipacapabilities}
        closeModal={()=>{setShowDownloadModal(false)}}
        />
      )} */}
    </div>
  );
};

export default DownloadStaffView;
