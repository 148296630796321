import React, { useEffect, useRef, useState } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineFileDone } from "react-icons/ai";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import { quarterlyAppraisalData } from "./quarterlyAppraisalData";
import Declaration from "../../components/common/Declaration/declaration";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";
import { sampleReviewerList } from "./sampleReviewerList";


import "./midYearReview.scss";
import { useSelector } from "react-redux";
import {
  getMidYearData,
  getPerformanceHeader,
  getPerformanceGoalsData,
  postMidYearMultiple,
  putUpdateMidYearMultiple,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import { validate } from "uuid";




let errorMessage = undefined;
let error = undefined;

// let midYearData = [];

const QuarterlyReview = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [details, setDetails] = useState([]);
  const [session, setSession] = useState("");
  const [pending, setPending] = useState(false);
  const [employeeName, setemployeeName] = React.useState([]);
  const [recipientMda, setrecipientMda] = React.useState([]);
  const [approverId, setApproverId] = React.useState(0);
  const [approvers, setApprovers] = React.useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [midYearData, setMidYearData] = useState([])
  const [midYearUpdateData, setMidYearUpdateData] = useState([])
  const [approved, setApproved] = useState(false);
  const [headerDetails, setHeaderDetails] = useState(null);



  useEffect(() => {
    const yearElement = document.getElementById("currentYear");
    if (yearElement) {
      const currentYear = new Date().getFullYear();
      yearElement.textContent = currentYear;
    }
  }, []);

  const showDetails = (comment, score) => {
    setFormDetails({ comment, score });
    setShowFormModal(true);
  };

  const clearApprovers = () => {
    setemployeeName([]);
    setrecipientMda([]);
  };

  const populateMidYearData = (data) => {
    let temp = data.map((data) => {
      return {
        goalId: data.goalId,
      };
    });
    setMidYearData(temp);
  };

  React.useEffect(() => {
    setApproverId(
      approvers?.map((item, idx) => ({
        approverID: item.value,
        priority: idx + 1,
      }))
    );
  }, [approvers]);

  useEffect(() => {
    setMidYearData(
      midYearData.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
    setMidYearUpdateData(
      midYearUpdateData.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  }, [approverId]);

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  

  const fetchMidYearData = async () => {
    setIsFetchingGoals(true);
    try {
      const response = await getMidYearData(dashboardDetails?.performanceId);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        handleSession(response?.data?.data);
        handlePending(response?.data?.data);
        handleApproved(response?.data?.data)
        setGoalsAndCommitments(response?.data?.data);
        populateMidYearData(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      // feedback({
      //   title: "Failed",
      //   text: error?.response?.data?.message,
      //   iconType: "error",
      // });
    }
    setIsFetchingGoals(false);
  };

  const setScore = (score, data) => {
    let recordExist;

    let updatedArray;
    if (session === "review") {
      updatedArray = [...midYearUpdateData];
    }
    else {
      updatedArray = [...midYearData];
    }
    const index = updatedArray.findIndex((obj) => obj.goalId === data?.goalId);

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        score: Number(score),
      };
    } else {
      if (session === "review") {
        const regex = /^[1-9][0-9]?$|^100$/;
        // Object doesn't exist, create a new one

        if (regex.test(Number(score))) {
          updatedArray.push({
            goalId: data.goalId,
            score: Number(score),
            comment: undefined,
            id: data.id,
            approvers: [],
          });
        }
      } else {
        const regex = /^[1-9][0-9]?$|^100$/;
        if (regex.test(Number(score))) {
          // Object doesn't exist, create a new one
          updatedArray.push({
            goalId: data.goalId,
            score: Number(score),
            comment: undefined,
            approvers: [],
          });
        }
      }
    }

    setMidYearData(
      updatedArray.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setMidYearUpdateData(
      updatedArray.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  const  setComment = (comment, data) => {
    let recordExist;

    let updatedArray;
    if (session === "review") {
      updatedArray = [...midYearUpdateData];
    }
    else {
      updatedArray = [...midYearData];
    }

    const index = updatedArray.findIndex((obj) => obj.goalId === data?.goalId);

    if (index !== -1) {
      // Object exists, mutate its properties
      updatedArray[index] = {
        ...updatedArray[index],
        comment: comment,
      };
    } else {
      if (session === "review") {
        // Object doesn't exist, create a new one
        updatedArray.push({
          goalId: data.goalId,
          score: undefined,
          id: data.id,
          comment: comment,
          approvers: [],
        });
      } else {
        // Object doesn't exist, create a new one
        updatedArray.push({
          goalId: data.goalId,
          score: undefined,
          comment: comment,
          approvers: [],
        });
      }
    }

    setMidYearData(
      updatedArray.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );

    setMidYearUpdateData(
      updatedArray.map((data) => ({
        ...data,
        approvers: approverId,
      }))
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Appraisal MidYear Review";
    // fetchGoalsAndCommitments(dashboardDetails?.performanceId);
    fetchMidYearData();
    fetchPerformanceHeaderDetails()
  }, []);

  const validateCreateMidYear = async (data) => {
    if (data.length == 0) {
      errorMessage = "please fill in form fields";
      return "please fill in form fields";
    }

    // let isScore = data.find((e) => e.score < 0 || e.score > 100 || e.score === undefined || !e.score)
    // if (isScore) { return "Please enter a valid number between 0 and 100." }

    // let isComment = data.find((e) => e.comment == "" || e.comment == undefined)
    // if (isComment) { return "Please enter comment for all comment fields." }

    let isApprover = data.find((e) => e.approvers.length < 1 || e.approvers == null || !e.approvers)
    if (isApprover) { return "Please choose at least 1 approver" }

    // data.forEach((item) => {
    //   if (isNaN(item.score) || item.score === 0 || item.score > 100) {
    //     errorMessage = "Please enter a valid number between 0 and 100.";
    //     return "Please enter a valid number between 0 and 100."
    //   }
    //   else if (!item.comment || item.comment === "") {
    //     errorMessage = "Please enter a comment for all comment fields.";
    //     return "Please enter a comment for all comment fields.";
    //   }
    //   else if ((item.approvers.length < 1 || item.approvers === null || !item.approvers) && session !== "review") {
    //     errorMessage = "Please choose at least 1 approver";
    //     return "Please choose at least 1 approver";
    //   }
    // })
    return undefined;
  };

  const validateUpdateMidYear = async (data) => {
    if (data.length === 0) {
      errorMessage = "please fill in form fields";
      return "please fill in form fields";
    }

    // let isScore = data.find((e) => e.score < 0 || e.score > 100 || e.score === undefined || !e.score)
    // if (isScore) { return "Please enter a valid number between 0 and 100." }

    let isComment = data.find((e) => e.comment === "" || e.comment === undefined)
    // if (isComment) { return "Please enter comment for all comment fields." }
    if (isComment) { return "Please enter comment for all comment fields." }

    // data.forEach((item) => {
    //   if (isNaN(item.score) || item.score === 0 || item.score > 100) {
    //     errorMessage = "Please enter a valid number between 0 and 100.";
    //     return "Please enter a valid number between 0 and 100."
    //   }
    //   else if (!item.comment || item.comment === "") {
    //     errorMessage = "Please enter a comment for all comment fields.";
    //     return "Please enter a comment for all comment fields.";
    //   }
    //   else if ((item.approvers.length < 1 || item.approvers === null || !item.approvers) && session !== "review") {
    //     errorMessage = "Please choose at least 1 approver";
    //     return "Please choose at least 1 approver";
    //   }
    // })

    return undefined;
  };
  const fetchPerformanceHeaderDetails = async () => {
    try {
      const response = await getPerformanceHeader(dashboardDetails?.periodId);
      if (response?.data != null) {
        if (response?.status) {
          let result = response?.data?.data;
  
          setHeaderDetails(result);
  
         
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } catch (error) {
      
    }
  };

  // const addApprovers = () => {
  //   midYearData = Array.isArray(midYearData) && midYearData.forEach((item) => {
  //     item.approvers = approverId
  //   })
  // }

  const handleSession = (data) => {
    let isReviewer = data.find((item) => item?.reviewers?.length > 0 && item.status === 1);
    if (isReviewer) {
      setSession("review")
    }
    else {
      setSession("")

    }
  }

  const handlePending = (data) => {
    let isPending = data.find((item) => item.status !== 2);
    if (isPending) {
      setPending(false);
    }
    else {
      setPending(true);
    }
  }

  const handleApproved = (data) => {
    let isApproved = data.find((item) => item.status !== 3);
    if (isApproved) {
      setApproved(false);
    }
    else {
      setApproved(true);
    }
  }

  console.log(approved)
  
  const submitMidYear = async () => {
    let error
    if (session === "review") {
      error = await validateUpdateMidYear(midYearUpdateData);
    }
    else {
      error = await validateCreateMidYear(midYearData);

    }

    console.log(error)
    if (error) {
      feedback({
        title: "Validation error",
        text: error,
        iconType: "warning",
      });
      return;
    }

    if (session !== "review") {
      const response = await postMidYearMultiple(midYearData);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        fetchMidYearData();
        setApprovers([]);
        clearApprovers();
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      }
      else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } else {
      const response = await putUpdateMidYearMultiple(midYearUpdateData);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        fetchMidYearData();
        setApprovers([]);
        clearApprovers();
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });

      }
      else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
  };
  const elementRef = useRef(null);
  const triggerRef = useRef(null);

  const openDrawer = () => {
    // if (!isDrawerOpen) {
    //   elementRef.current.style.width = "19rem";
    //   triggerRef.current.style.transform = "translate(-19rem)";
    //   triggerRef.current.style.transition = "0.5s";
    //   setIsDrawerOpen((prev) => !prev);
    // } else {
    //   elementRef.current.style.width = "0";
    //   triggerRef.current.style.transform = "translate(0)";
    //   setIsDrawerOpen((prev) => !prev);
    // }
  };

  const handleReview = (data) => {
    setDetails(data);
    return setShowFormModal(true);
  };

  return (
    
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Appraisal</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">
        Plan your activities for the year <span >{headerDetails?.period}</span> 
      </p>

      
      {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      /> */}
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Departmental Commitment"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Approvers Setup"
        setApproverId={setApproverId}
        approverId={approverId}
        setApprovers={setApprovers}
        employeeName={employeeName}
        setemployeeName={setemployeeName}
        recipientMda={recipientMda}
        setrecipientMda={setrecipientMda}
      />
      {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Targets/Goals/Commitment"
      /> */}

      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>My Personal Targets & Performance (Quantitative)</h4>
            <p className="sub-header">
              The Head of the Department in consultation with my
              Division/Section/Unit set out to achieve the following
            </p>
            <div className="table-view m-t-10 score-table">
              <div className="table-header">
                <div className="table-row score-table-header-row ">
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "20%" }}
                  >
                    <p>Commitments</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "20%" }}
                  >
                    <p>KPIs</p>
                  </div>
                  <div
                    className="th san-sherif score-table-th"
                    style={{ width: "40%" }}
                  >
                    <p>Employee's Comments</p>
                    {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                  </div>
                  {goalsAndCommitments[0]?.reviewers?.length > 0 && (
                    <div className="th san-sherif score-table-th">
                      <p>Reviewers</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="table-body score-table-body"
                // style={{ overflow: "scroll" }}
              >
                {goalsAndCommitments.map((item, index) => (
                  <div
                    className={`table-row score-table-row m-t-10 ${
                      item.status === 1 && "bg-light-green"
                    }`}
                    key={index}
                  >
                    <div className="td td-cell score-table-td">
                      <h3 className="bold-text">{item?.objective}</h3>
                    </div>
                    <div className="td score-table-td h-100">
                      {/* {
                        <textarea
                          rows={item?.kpis?.length + 2}
                          className=" h-100"
                          onChange={(e) => setScore(e.target.value, item)}
                          value={(item?.score === 0 || item?.score === null || !item.score || item.status === 1) ? undefined : item.score}
                          disabled={item.status !== 1 || (item.score && item?.score > 0 && item.status !== 1)}

                        />
                      } */}
                      {Array.isArray(item.kpis) && item?.kpis?.map((kpi, idx) => (
                      <div>&nbsp;&nbsp;&nbsp; {`${idx + 1}. ${kpi.performanceIndicator}`}</div>
                    ))}
                    </div>
                    <div className="td score-table-td h-100">
                      {
                        <textarea
                          rows={item?.kpis?.length + 2}
                          className="w-100 h-100"
                          onChange={(e) => setComment(e.target.value, item)}
                          value={
                            item?.statusName === "Unasigned" || item.status === 1
                              ? undefined
                              : item.comment
                          }
                          disabled={
                            item.status !== 1
                          }
                        />
                      }
                    </div>
                    {item?.reviewers?.length > 0 && <div className="td score-table-td">
                      {item?.reviewers?.map((item, index) => (
                        <Reviewer
                          key={item.id}
                          handleReview={handleReview}
                          isReviewed={item.goalComment !== ''}
                          score={item?.score}
                          comment={item?.goalComment}
                          office={`${item?.role}, ${item?.approverMDA}`}
                          name={item?.approver}
                          date={item?.date}
                          details={item}
                          showButtons={false}
                          viewDetails={showDetails}
                        />
                      ))}
                    </div>}
                  </div>
                ))}
              </div>
              {/* <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th">
                    <p>100%</p>
                  </div>

                  <div className="th san-sherif score-table-th"></div>
                </div>
              </div> */}
              {/* <div className="mdy-drawer" ref={elementRef}>
                <div className="header san-sherif th score-table-th">
                  Reviewers
                </div>

                <div className="reviewer-container">
                  {sampleReviewerList.map((item, index) => (
                    <Reviewer
                      key={index}
                      handleReview={handleReview}
                      isReviewed={item?.isReviewed}
                      score={item?.score}
                      office={item?.office}
                      name={item?.name}
                    />
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      

      <Declaration />

      <div className="submit-goal-container">
        {(session === "review" || (!pending && !approved)) && <button onClick={submitMidYear} type="button" className="btn btn-large">
          Submit Review
        </button>}
      </div>

      {isShowFormModal && (
        <ReviewForm
          details={details}
          formDetails={formDetails}
          refreshList={() => fetchMidYearData()}
          closeModal={() => setShowFormModal(false)}
        />
      )}
    
    </div>
  );
};

export default QuarterlyReview;
