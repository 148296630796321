import React, { useEffect, useRef, useState } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineFileDone } from "react-icons/ai";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import Declaration from "../../components/common/Declaration/declaration";
import Reviewer from "../../components/common/Reviewer/Reviewer";
import ReviewForm from "../../components/Forms/ReviewForm/ReviewForm";
import "./midYearReview.scss";
import { useSelector } from "react-redux";
import { getMidYearData, getPerformanceGoalsData, postMidYearMultiple, getApproverMidYearQueue, getPerformanceHeader } from "../../network/services";
import { feedback } from "../../utils/feedback";
import Select from "react-select";
import Cookies from "js-cookie";

const MidYearApprover = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isShowFormModal, setShowFormModal] = useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [details, setDetails] = useState([]);
  const [formDetails, setFormDetails] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState({label: "All Initiators", value:0});
  const [appraisees, setAppraisees] = useState([]);
  const [tableData, setTableData] = useState([])
  const [assignee, setAssignee] = useState("")
  const [headerDetails, setHeaderDetails] = useState(null);



  let midYearData = [];

  const sortTable = (text) => {
    if(text === "All Initiators"){
      setTableData(goalsAndCommitments)
      return;
    }
    
    setTableData(goalsAndCommitments.filter((item) => {
      return item.reviewers[0].appraisee.includes(text)
    }))
}


// useEffect(()=>{
//   sortTable(assignee)
// }, [tableData])

const dashboardDetails = useSelector(
  (state) => state?.dashboard?.dashboardDetails
);

const showDetails = (comment, score) => {
  setFormDetails({ comment, score })
  setShowFormModal(true);
}


useEffect(() => {
  const key = 'label';
  Array.isArray(goalsAndCommitments) && setAppraisees(
    [...new Map(
      goalsAndCommitments.map((item, index) => (
        {
          label: item.reviewers[0].appraisee,
          value: index + 1
        }
      )).map(item =>
        [item[key], item])).values()]
  );
  sortTable(assignee);
}, [goalsAndCommitments])

console.log(appraisees)

const fetchMidYearData = async () => {
  setIsFetchingGoals(true);
  try {
    const response = await getApproverMidYearQueue(dashboardDetails?.performanceId)
    console.log(response)
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      console.log('IN set everything array')
      setTableData(response?.data?.data)
      setGoalsAndCommitments(response?.data?.data);
    }else {
      console.log('Failed')
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  } catch (error) {
    setTableData([])
    setGoalsAndCommitments([]) 
   console.log(error)
  }
  setIsFetchingGoals(false);
}

const fetchPerformanceHeaderDetails = async () => {
  try {
    const response = await getPerformanceHeader(dashboardDetails?.periodId);
    if (response?.data != null) {
      if (response?.status) {
        let result = response?.data?.data;

        setHeaderDetails(result);

       
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    }
  } catch (error) {
    
  }
};

const setScore = (score, data) => {
  console.log(score, data)
  console.log(midYearData)
  let recordExist = Array.isArray(midYearData) && midYearData.find(record => record.goalId === data.id)
  console.log(recordExist);

  if (recordExist == false || recordExist === undefined) {
    midYearData = Array.isArray(midYearData) && [
      ...midYearData, {
        goalId: data.id,
        score: Number(score),
        comment: ""
      }]
    console.log(midYearData)
  }
  else {

    Array.isArray(midYearData) && midYearData.find(record => record.goalId === data.id && (record.score = Number(score), true));

    console.log(midYearData)
  }


}

const setComment = (comment, data) => {
  console.log(comment, data)
  let recordExist = Array.isArray(midYearData) && midYearData?.find(record => record?.goalId === data?.id)
  console.log(midYearData);
  console.log(recordExist);

  if (recordExist === false || recordExist === undefined) {
    midYearData = [...midYearData, {
      goalId: data.id,
      score: "",
      comment: comment
    }]

  }
  else {
    Array.isArray(midYearData) && midYearData.find(record => record.goalId === data.id && (record.comment = comment, true));
    console.log(midYearData)
  }
}

console.log(midYearData)

useEffect(() => {
  window.scrollTo(0, 0);
  document.title = "Appraisal MidYear Review";
  const yearElement = document.getElementById("currentYear");
  if (yearElement) {
    const currentYear = new Date().getFullYear();
    yearElement.textContent = currentYear;
  }
  // fetchGoalsAndCommitments(dashboardDetails?.performanceId);
  fetchMidYearData();
  fetchPerformanceHeaderDetails()
}, []);

const submitMidYear = async () => {
  const res = await postMidYearMultiple(midYearData);
  console.log(res)
}

const elementRef = useRef(null);
const triggerRef = useRef(null);

const openDrawer = () => {
  // if (!isDrawerOpen) {
  //   elementRef.current.style.width = "19rem";
  //   triggerRef.current.style.transform = "translate(-19rem)";
  //   triggerRef.current.style.transition = "0.5s";
  //   setIsDrawerOpen((prev) => !prev);
  // } else {
  //   elementRef.current.style.width = "0";
  //   triggerRef.current.style.transform = "translate(0)";
  //   setIsDrawerOpen((prev) => !prev);
  // }
};

const handleReview = (data) => {
  setFormDetails(null);
  setDetails(data);
  return setShowFormModal(true);
};

return (
  <div className="w-100">
    <div className="w-100 flex space-between flex-v-center m-b-20">
      <div>
        <h2>Appraisal</h2>
      </div>
      <LocationDetails />
    </div>

    <p className="goal-setting-header">
      Plan your activities for the year <span>{headerDetails?.period}</span>
    </p>

    {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      /> */}
    <Collapsible
      icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
      title="Departmental Commitment"
    />
    {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Targets/Goals/Commitment"
      /> */}

    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="flex space-between">
            <div>
              <h4>My Personal Targets & Performance (Quantitative)</h4>
              <p className="sub-header">
                The Head of the Department in consultation with my
                Division/Section/Unit set out to achieve the following
              </p>
            </div>

            <div className='m-b-10 flex_basis_30 form-group '>
              <label>Initiators</label>
              <Select
                label='Select Initiators'
                color='rgba(120,120,120,0.8)'
                className='m-b-10 w-w00'
                onChange={(value) => {
                  console.log(value);
                  setSearchValue(value);
                  setAssignee(value?.label);
                  sortTable(value?.label)
                }}
                value={searchValue}
                options={[
                  {label: "All Initiators", value:0},
                  ...appraisees
                ]}
              />
            </div>
          </div>



          <div className="table-view m-t-10 score-table ">
            <div className="table-header ">
              <div className="table-row score-table-header-row p-40">
                <div
                  className="th san-sherif score-table-th"
                  style={{ width: "20%" }}
                >
                  <p>Initiator</p>
                </div>
                <div
                  className="th san-sherif score-table-th"
                  style={{ width: "20%" }}
                >
                  <p>Goals/Commitments</p>
                </div>
                <div
                  className="th san-sherif score-table-th"
                  style={{ width: "20%" }}
                >
                  <p>KPIs</p>
                </div>
                <div
                  className="th san-sherif score-table-th"
                  style={{ width: "30%" }}
                >
                  <p>Employee's Comments</p>
                  {/* <div ref={triggerRef}>
                      <AiOutlineFileDone
                        size={32}
                        onClick={openDrawer}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}
                </div>
                <div className="th san-sherif score-table-th">
                  <p>
                    Reviewers
                  </p>
                </div>
              </div>
            </div>
            <div
              className="table-body score-table-body"
              style={{ overflow: "scroll" }}
            >
              {tableData.map((item, index) => (
                <div className={`table-row score-table-row m-t-10`} key={index}>
                  <div className="td td-cell score-table-td">
                    <h3 className="bold-text">{item?.reviewers[0]?.appraisee}</h3>
                  </div>
                  <div className="td td-cell score-table-td">
                    <h3 className="bold-text">{item?.objective}</h3>
                    
                  </div>
                  <div className={`td score-table-td h-100`}>
                  {Array.isArray(item.kpis) && item?.kpis?.map((kpi, idx) => (
                      <div>&nbsp;&nbsp;&nbsp; {`${idx + 1}. ${kpi.performanceIndicator}`}</div>
                    ))}
                  </div>
                  <div className="td score-table-td h-100">
                    {
                      <textarea
                        rows={item?.kpis?.length + 2}
                        className="w-100 h-100"
                        onChange={(e) => setComment(e.target.value, item)}
                        value={item?.comment === "" ? null : item.comment}
                        disabled={item?.score === 0 ? false : true}
                      />
                    }
                  </div>
                  <div className="td score-table-td">
                    {item?.reviewers?.map((reviewer, index) => (
                      <Reviewer
                        key={reviewer.id}
                        handleReview={handleReview}
                        // isReviewed={reviewer?.goalComment !== ""}
                        score={reviewer?.score}
                        comment={reviewer?.goalComment}
                        office={`${reviewer?.role}`}
                        mda={`${reviewer?.approverMDA}`}
                        name={reviewer?.approver}
                        date={reviewer?.date}
                        details={item}
                        showButtons={reviewer?.approver === Cookies.get("fullName")}
                        viewDetails={showDetails}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th">
                    <p>100%</p>
                  </div>

                  <div className="th san-sherif score-table-th"></div>
                </div>
              </div> */}
            {/* <div className="mdy-drawer" ref={elementRef}>
                <div className="header san-sherif th score-table-th">
                  Reviewers
                </div>

                <div className="reviewer-container">
                  {sampleReviewerList.map((item, index) => (
                    <Reviewer
                      key={index}
                      handleReview={handleReview}
                      isReviewed={item?.isReviewed}
                      score={item?.score}
                      office={item?.office}
                      name={item?.name}
                    />
                  ))}
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </div>

    <Declaration />

    {/* <div className="submit-goal-container">
        <button onClick={submitMidYear} type="button" className="btn btn-large">
          Submit Review
        </button>
      </div> */}

    {isShowFormModal && (
      <ReviewForm details={details} formDetails={formDetails} refreshList={fetchMidYearData} closeModal={() => setShowFormModal(false)} />
    )}
  </div>
);
};

export default MidYearApprover;
