import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import Spinner from "../../common/spinner/spinner";
import TextareaInput from "../../inputs/TextareaInput";
import {
  createDevelopmentActionPlan,
  updateDevelopmentActionPlan,
} from "../../../network/services";
import TextInput from "../../inputs/TextInput";
import { feedback } from "../../../utils/feedback";
import SelectInput from "../../inputs/SelectInput";

const DevelopmentActionPlanForm = ({
  closeModal,
  handleSave,
  loading,
  formDetails,
  setLoading,
  initiator,
  details,
  setDetails,
  disable,
  developmentGap,
  shortTerm,
  fetchDevelopmentActionPlan
}) => {
  const [inputData, setInputData] = useState();

  console.log(details);
  console.log(developmentGap);
  console.log(inputData);

  const optionValues = developmentGap.map((d) => ({
    name: d.title,
    value: d.id,
  }));

  const userId = localStorage.getItem('userId')

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  const editPlan = async () => {
    const payload = {
      developmentGapId: inputData?.developmentGapId || details?.devGapId,
      developmentGoals: inputData?.developmentGoals || details?.devGoals,
      employeeAchievements: inputData?.employeeAchievements || details?.achievements,
      managersReview: inputData?.managersReview || details?.managersReviews,
      id: details?.id
    }
    const response = await updateDevelopmentActionPlan(payload);
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentActionPlan()
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  };

  const addPlan = async () => {
    const response = await createDevelopmentActionPlan({
      ...inputData,
      developmentGapId: parseInt(inputData.developmentGapId)
      
    });
    if (response?.data?.code === 1 || response?.data?.code === "1") {
      fetchDevelopmentActionPlan()
      feedback({
        title: "Success",
        text: response?.data?.message,
        iconType: "success",
      });
      closeModal();
    } else {
      feedback({
        title: "Failed",
        text: response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    if (details.id) {
      editPlan();
    } else {
      addPlan();
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer"onClick={()=>{clearForm(); closeModal(); setDetails([]); }} />
      <div className="modal-box max-w-500">
        <form
          id="modal-form"
          onSubmit={(e) => {
            // handleSave(e, inputData);
            handlSubmit(e);
            clearForm();
            setDetails([])
          }}
        >
          <div className="content">
            <SelectInput
              className="w-100 m-b-10"
              name="developmentGapId"
              label="Development Gap"
              options={optionValues}
              value={inputData?.developmentGapId || (formDetails && formDetails.devGapId) || ""}
              onChange={handleChange}
            />

            <TextareaInput
              className="w-100 m-b-10"
              name="developmentGoals"
              label="Development Goals"
              type="text"
              rows="5"
              onChange={handleChange}
              value={inputData?.developmentGoals || (formDetails && formDetails.devGoals) || ""}
            />

            <TextareaInput
              className="w-100 m-b-10"
              name="employeeAchievements"
              label="Employee Achievements"
              type="text"
              rows="5"
              onChange={handleChange}
              value={inputData?.employeeAchievements || (formDetails && formDetails.achievements) || ""}
            />

            <TextareaInput
              className="w-100 m-b-10"
              name="managersReview"
              label="Managers Review"
              type="text"
              rows="5"
              onChange={handleChange}
              value={inputData?.managersReview || (formDetails && formDetails.managersReviews) || ""}
            />

            {disable && (
              <div className="flex justify-content-end w-100">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-large flex_basis_48 w-100"
                >
                  {loading && <Spinner />} Save
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DevelopmentActionPlanForm;
