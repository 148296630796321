import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import { RiAddLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import Cookies from "js-cookie";
import { RiDeleteBin6Line } from "react-icons/ri";

import LocationDetails from "../../components/layouts/LocationDetails";
import {
  postPerformanceGoal,
  getPerformanceHeader,
  getPerformanceGoalsData,
  postInitiatePerformance,
  putPerformanceGoal,
  deletePerformanceGoalsData,
  postInitiatePerformanceApproval,
  putSubmitUpdate,
  putGoal,
  putUpdateGoal,
  putSubmitMyGoal,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import Collapsible from "../../components/common/collapsible/collapsible";
import Declaration from "../../components/common/Declaration/declaration";
import GoalForm from "../../components/Forms/goalForm";
import IsLoading from "../../components/common/isLoading";

import Spinner from "../../components/common/spinner/spinner";
import { fetchDashboardDetails } from "../../redux/actions/actions";
import { formatWeight } from "./helper";
import EditGoalForm from "../../components/Forms/editGoalForm";

import "./goalSetting.scss";
import ViewApproversComment from "../../components/layouts/ViewApproversComments";
import { NIL } from "uuid";

const GoalSetting = () => {
  const [isShowGoalFormModal, setGoalFormModal] = useState(false);
  const [isShowEditGoalFormModal, setEditGoalFormModal] = useState(false);
  const [isSettingGoal, setIsSettingGoal] = useState(false);
  const [isInitiatingPerformance, setIsInitiatePerformance] = useState(false);
  const [isFetchingPerformanceHeader, setIsFetchingPerformanceHeader] = useState(false);
  const [headerDetails, setHeaderDetails] = useState(null);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [singleGoal, setSingleGoal] = useState(null);
  const [isDeletingGoal, setIsDeletingGoal] = useState(false);
  const [approverId, setApproverId] = React.useState(0);
  const [approvers, setApprovers] = React.useState([]);
  const [showApproversComment, setShowApproversComment] = useState(false);
  const [approversComments, setApproversComments] = useState([]);
  const [employeeName, setemployeeName] = React.useState([]);
  const [recipientMda, setrecipientMda] = React.useState([]);


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Employee Appraisal Dashboard";
    const yearElement = document.getElementById("currentYear");
    if (yearElement) {
      const currentYear = new Date().getFullYear();
      yearElement.textContent = currentYear;
    }
  }, []);

  
  const dispatch = useDispatch();
  const userId = Cookies.get('userId');
  console.log(userId)

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const handleSave = async (e, inputData) => {
    e.preventDefault();

    const { kpiList, inputData: input } = inputData;

    if (kpiList?.length < 1) {
      feedback({
        title: "Validation Error",
        text: "Please enter at least one KPI",
        iconType: "error",
      });
      return;
    }

    const performanceIndicator =
      kpiList?.length > 0
        ? kpiList?.map((item) => ({
          performanceIndicator: item?.performanceIndicator,
        }))
        : [];
    const payload = {
      performanceID: dashboardDetails?.performanceId,
      periodID: dashboardDetails?.periodId,
      performanceIndicator: performanceIndicator,
      objective: input?.goal,
      weight: Number(input?.weight),
      priority: Number(input?.priority),
      reviewSchedule: input?.schedule,
      origin: userId,
    };

    setIsSettingGoal(true);
    try {
      const response = await postPerformanceGoal(payload);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchGoalsAndCommitments(dashboardDetails?.performanceId);
        setGoalFormModal(false);
      } else if (response.status === 400) {
        feedback({
          title: "Failed",
          text: 'response?.errors?.Origin0',
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: 'Failed to save goal',
        iconType: "error",
      });
    }
    setIsSettingGoal(false);
  };
  console.log(goalsAndCommitments);
  const handleComment = (comment) => {
    setApproversComments(comment?.performanceGoalApprovers);
    setShowApproversComment(true);
  }

  const clearApprovers = () => {
    setemployeeName([]);
    setrecipientMda([])
  }

  const handleUpdate = async (e, inputData) => {
    e.preventDefault();

    const { kpiList, inputData: input } = inputData;
    console.log(kpiList)

    const performanceIndicator =
      kpiList?.length > 0
        ? kpiList?.map((item) => ({

          performanceIndicator: item?.performanceIndicator,
        }))
        : [];
    const payload = {
      id: input?.id,
      staffID: Number(Cookies.get("userId")),
      performanceID: dashboardDetails?.performanceId,
      periodID: dashboardDetails?.periodId,
      performanceIndicator: performanceIndicator,
      objective: input?.objective,
      priority: Number(input?.priority),
      origin: Cookies.get("userId"),
    };

    setIsSettingGoal(true);
    try {
      const response = await putGoal(payload);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchGoalsAndCommitments(dashboardDetails?.performanceId);
        fetchDashboardDetails();
        fetchPerformanceHeaderDetails(dashboardDetails?.periodId);
        setGoalFormModal(false);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsSettingGoal(false);
  };


  React.useEffect(() => {
    console.log(approvers);
    setApproverId(
      approvers?.map((item, idx) => ({
        approverId: item.value,
        sequence: idx + 1,
      }))
    );
  }, [approvers]);


  const fetchGoalsAndCommitments = async (id) => {
    setIsFetchingGoals(true);
    try {
      const response = await getPerformanceGoalsData(id);
      if (response?.data != null) {
        if (response?.data?.code === 1 || response?.data?.code === "1") {
          setGoalsAndCommitments(response?.data?.data);
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } catch (error) {

    }
    setIsFetchingGoals(false);
  };

  const handleDeleteGoal = async (item) => {
    setIsDeletingGoal(true);
    try {
      const response = await deletePerformanceGoalsData(item?.id);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchGoalsAndCommitments(dashboardDetails?.performanceId);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsDeletingGoal(false);
  };

  console.log(approverId)

  const fetchPerformanceHeaderDetails = async (id) => {
    setIsFetchingPerformanceHeader(true);
    try {
      const response = await getPerformanceHeader(id);
      if (response?.data != null) {
        if (response?.status) {
          let result = response?.data?.data;

          setHeaderDetails(result);

          if (result) {
            fetchGoalsAndCommitments(result.id);
          }
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } catch (error) {

    }
    setIsFetchingPerformanceHeader(false);
  };

  const handleInitiatePerformance = async () => {
    setIsInitiatePerformance(true);
    try {
      const response = await postInitiatePerformance(
        dashboardDetails?.periodId
      );
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchPerformanceHeaderDetails(dashboardDetails?.periodId);
        dispatch(fetchDashboardDetails());
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsInitiatePerformance(false);
  };

  console.log(headerDetails)

  const handleInitiatePerformanceApproval = async () => {

    const updatedGoals = goalsAndCommitments?.map(item => ({
      ...item, 
      performanceGoalApprovers: approverId, 
      performancePlanApprovers: approverId
    }));
    
    console.log("goals object", updatedGoals)

    if (goalsAndCommitments?.length < 1) {
      feedback({
        title: "Validation Warning",
        text: "Please add at least one performance goal",
        iconType: "warning",
      });
      return;
    }

    try {
      const response = await putSubmitMyGoal({
        periodID: Number(dashboardDetails?.periodId),
        staffID: Number(Cookies.get("userId")),
        performanceID: Number(dashboardDetails?.performanceId),
        goals: updatedGoals
      })
      if (response?.data?.code === "1" || response?.data?.code === 1) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        setApprovers([]);
        clearApprovers();
        fetchGoalsAndCommitments(dashboardDetails?.performanceId)
        fetchPerformanceHeaderDetails(dashboardDetails?.periodId)
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }

    }
    catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }

  }

  const getTotalWeight = (commitments) => {
    if (commitments?.length > 0) {
      const totalWeight = commitments.reduce(
        (previous, current) => previous + current?.weight,
        0
      );

      return totalWeight;
    }

    return 0;
  };

  const handleLoader = () => {
    if (isSettingGoal) {
      return <IsLoading message="Is Setting Goals.." />;
    } else if (isFetchingPerformanceHeader) {
      return <IsLoading message="Is Fetching Performance Details.." />;
    } else if (isFetchingGoals) {
      return <IsLoading message="Is Fetching Goals.." />;
    } else if (isDeletingGoal) {
      return <IsLoading message="Is Deleting Goal.." />;
    }
  };

  const handleClickEditGoal = (e, goal) => {
    e.preventDefault();
    e.stopPropagation();
    setEditGoalFormModal(true);
    setSingleGoal(goal);
  };

  useEffect(() => {
    dashboardDetails && fetchPerformanceHeaderDetails(dashboardDetails?.periodId);
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Goal Setting</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">
        Plan your activities for the year <span >{headerDetails?.period}</span>
      </p>

      {/* <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Departmental Commitment"
      /> */}
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Approvers Setup"
        setApproverId={setApproverId}
        approverId={approverId}
        setApprovers={setApprovers}
        employeeName={employeeName}
        setemployeeName={setemployeeName}
        recipientMda={recipientMda}
        setrecipientMda={setrecipientMda}
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Mandate"
      />

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p className="san-sherif">Period</p>
            </div>
            <div className="th">
              <p className="san-sherif">Planning Start Date</p>
            </div>
            <div className="th">
              <p className="san-sherif">Planning End Date</p>
            </div>
            <div className="th">
              <p className="san-sherif">Status</p>
            </div>
            <div className="th">
              {/* {headerDetails === null || headerDetails === undefined && ( */}
              <button
                className="add-button pointer"
                type="button"
                onClick={handleInitiatePerformance}
              >
                <AiOutlineEdit className="m-r-10" />
                {isInitiatingPerformance ? (
                  <Spinner />
                ) : (
                  "Initiate Performance"
                )}
              </button>
              {/* )} */}
              {/* {dashboardDetails !== null && (
                <button
                  className="add-button pointer"
                  type="button"
                  onClick={() => handleApprovalProgress()}
                >
                  <AiOutlineEdit />
                  View Approval Progress
                </button>
              )} */}
            </div>
          </div>
        </div>
        <div className="table-body">
          {headerDetails && (
            <div className="table-row">
              <div className="td">{headerDetails?.period}</div>
              <div className="td">{headerDetails?.stageStartDate}</div>
              <div className="td">{headerDetails?.stageEndDate}</div>
              <div className="td">{headerDetails?.approvalStatus}</div>
              <div className="td"></div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th san-sherif no-inherit" style={{ width: "30%" }}>
                  <p>Target/Goals/Commitment</p>
                </div>

                <div className="th san-sherif no-inherit" style={{ width: "30%" }}>
                  <p>KPIs</p>
                </div>


                {/* <div className="th san-sherif">
                  <p>Review Schedule</p>
                </div> */}

                <div className="th san-sherif" style={{ width: "5%" }}>
                  <p>Priority</p>
                </div>

                {/* <div className="th san-sherif" style={{ width: "5%" }}>
                  <p>Weight</p>
                </div> */}

                <div className="th san-sherif">
                  <p>Last Update</p>
                </div>
                <div className="th">
                  {/* {(headerDetails?.status === 3 || headerDetails?.approvalStatus === "Staff") && ( */}
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => setGoalFormModal(true)}
                  >
                    <RiAddLine />
                    Add Goals
                  </button>
                  {/* )} */}
                </div>
              </div>
            </div>
            <div className="table-body" style={{ overflow: "scroll" }}>
              {goalsAndCommitments?.map((item, index) => (
                <div onClick={() => handleComment(item)} className="table-row pointer hover-green" key={index}>
                  <div className="td td-cell">{item?.objective}</div>
                  <div className="no-inherit">
                    <div>
                      {item?.kpis?.length &&
                        item?.kpis?.map((kpiObject, index) => (
                          <p key={index}>
                            <span>-</span>
                            <span style={{ marginLeft: "0.2rem" }}>
                              {kpiObject?.performanceIndicator}
                            </span>
                          </p>
                        ))}
                    </div>
                  </div>

                  {/* <div className="td">{item?.reviewSchedule}</div> */}
                  <div className="td">{item?.priority}</div>
                  {/* <div className="td">{formatWeight(item?.weight)}</div> */}
                  <div className="td">{item?.date}</div>
                  <div className="td">
                    {(headerDetails?.status === 0 || headerDetails.status === 3) && <div className="actions">
                      <button
                        type="button"
                        className="btn activate"
                        onClick={(e) => handleClickEditGoal(e, item)}
                      >
                        <GrEdit />
                      </button>
                      <button
                        type="button"
                        className="btn deactivate"
                        onClick={(e) => {
                          e.stopPropagation();
                          feedback(
                            {
                              title: "Delete Goal?",
                              text: "Click on DELETE to continue",
                              isDialog: true,
                              showCancelButton: true,
                              confirmButtonText: "Delete",
                              iconType: "warning",
                            },
                            () => handleDeleteGoal(item)
                          )
                        }


                        }
                      >
                        <RiDeleteBin6Line />
                      </button>
                    </div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Declaration />

      {handleLoader()}

      {/* <div className="submit-goal-container">
        {goalsAndCommitments?.length > 0 &&
        goalsAndCommitments[0]?.approvalStatus !== "Staff" ? null : (
          <button type="button" className="btn btn-large">
            Submit Goals/Target for the Year
          </button>
        )}
      </div> */}

      {/* {(headerDetails?.status === 0 || headerDetails?.status === 3) && */}
      <div className="submit-goal-container">
        <button
          type="button"
          className="btn btn-large"
          onClick={handleInitiatePerformanceApproval}
        >
          Submit Goals/Target for the Year
        </button>
      </div>
      {/* } */}

      {isShowGoalFormModal && (
        <GoalForm
          closeModal={() => setGoalFormModal(false)}
          handleSave={handleSave}
          isLoading={isSettingGoal}
        />
      )}

      {
        showApproversComment && (
          <ViewApproversComment
            approversComments={approversComments}
            closeModal={() => setShowApproversComment(false)}
          />
        )
      }

      {isShowEditGoalFormModal && (
        <EditGoalForm
          closeModal={() => setEditGoalFormModal(false)}
          handleUpdate={handleUpdate}
          data={singleGoal}
        />
      )}
    </div>
  );
};

export default GoalSetting;
