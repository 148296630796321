import React, { useEffect } from "react";

import { HiOutlineUserCircle } from "react-icons/hi";
import LocationDetails from "../../components/layouts/LocationDetails";
import Collapsible from "../../components/common/collapsible/collapsible";
import { quarterlyAppraisalData } from "./quarterlyAppraisalData";
import Declaration from "../../components/common/Declaration/declaration";

import "./quarterlyAppraisal.scss";

const QuarterlyAppraisal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "";
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center m-b-20">
        <div>
          <h2>Appraisal</h2>
        </div>
        <LocationDetails />
      </div>

      <p className="goal-setting-header">
        Plan your activities for the year 2021
      </p>

      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Employee Record"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Departmental Commitment"
      />
      <Collapsible
        icon={<HiOutlineUserCircle size={28} className="collapsible-icon" />}
        title="Targets/Goals/Commitment"
      />

      <div className="row">
        <div className="col-12">
          <div className="card">
            <h4>My Personal Targets & Performance (Quantitative)</h4>
            <p className="sub-header">
              The Head of the Department in consultation with my
              Division/Section/Unit set out to achieve the following
            </p>
            <div className="table-view m-t-10 score-table">
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th">
                    <p>Commitments</p>
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Assigned Weight</p>
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Your Score (.../100)</p>
                  </div>
                  <div className="th san-sherif score-table-th">
                    <p>Employee's Comments</p>
                  </div>
                </div>
              </div>
              <div
                className="table-body score-table-body"
                style={{ overflow: "scroll" }}
              >
                {quarterlyAppraisalData.map((item, index) => (
                  <div className="table-row score-table-row m-t-10" key={index}>
                    <div className="td td-cell score-table-td">
                      {item?.commitment}
                    </div>
                    <div className="td score-table-td">
                      {item?.assignedWeight}
                    </div>
                    <div className="td score-table-td">{item?.review}</div>
                    <div className="td score-table-td">{item?.priority}</div>
                  </div>
                ))}
              </div>
              <div className="table-header">
                <div className="table-row score-table-header-row">
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th">
                    <p>100%</p>
                  </div>
                  <div className="th san-sherif score-table-th"></div>
                  <div className="th san-sherif score-table-th"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Declaration />

      <div className="submit-goal-container">
        <button type="button" className="btn btn-large">
          Submit Review
        </button>
      </div>
    </div>
  );
};

export default QuarterlyAppraisal;
